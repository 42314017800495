
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  Link,
  Text,
  TextField,
  TextAreaField,
  Flex
  } from '@aws-amplify/ui-react';

  import  { getSBSMNews}  from '../../graphql/queries'
  import  { deleteSBSMNews, updateSBSMNews, createSBSMNews}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  
  
  import styles from './TwitterPostTextRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
  import {RhyteItLogging} from '../../BusinessLogic/UserManagement';

const TwitterPostTextRow = (props) => {       

  TwitterPostTextRow.propTypes = {
      SBSMArticle: PropTypes.object
      };
  
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true
    let vHoursAgo = 0    
    const [vSBSMNewsArticle, setvSBSMNewsArticle] = useState(props.SBSMArticle)  
    const [vExpandRow, setvExpandRow] = useState(false) 
    const [vTextString, setvTextString] = useState()  
    const [vExpandRowIcon, setvExpandRowIcon] = useState("+")  
    const [vHeadRowKey, setvHeadRowKey] = useState()  
    const [vTextRowKey, setvTextRowKey] = useState()  



    useEffect(() => {


      },[])  

    useEffect(() => {

          //console.log("TwitterPostTextRow.useEffect[vSBSMNewsArticle].vSBSMNewsArticle:  ",vSBSMNewsArticle) 
          setErrorMessage("")

          if (vSBSMNewsArticle !== undefined) {
            setvHeadRowKey(Date.now())
            setvTextString(vSBSMNewsArticle.TextString)
          }          

      },[vSBSMNewsArticle])     
      
      

      useEffect(() => {

        setErrorMessage("")

        if (vTextString !== undefined) {
        
          //console.log("TwitterPostTextRow.useEffect[vTextString].vTextString:  ",vTextString) 

          setvTextRowKey(Date.now())
        }          

    },[vTextString])     
    
    
    function TwitterPostTextRow_Delete_Row(event,data) {
      try {

           // //console.log("TwitterPostTextRow.TwitterPostTextRow_Delete_Row.Users[0].UserId    :  ",Users[0].UserId    ) 

            if (Users === "edwardhawke@rhyteit.com") {
          
                var today = new Date();

                if (vSBSMNewsArticle.SBSMStatus !== "New") {

                  const DeleteSBSMNewsArticle = {
                    id: vSBSMNewsArticle.id,
                    UserId: vSBSMNewsArticle.UserId,
                    _version: vSBSMNewsArticle._version
                  }
                  


                  const ReturnMessage = client.graphql({
                    query: deleteSBSMNews,
                    variables: {
                      input: DeleteSBSMNewsArticle
                    }
                  });  

                }
              
                setvSBSMNewsArticle()
                ErrorMessageStr = "Item deleted at: " + today.toLocaleString("en-US")
                setErrorMessage(ErrorMessageStr)       
                  
          } else {

            ErrorMessageStr = "You do not have permission to delete this item"
            setErrorMessage(ErrorMessageStr)  

          }
      
          } catch (err) { 

          ErrorMessageStr = "TwitterPostTextRow.TwitterPostTextRow_Delete_Row.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }   


   async function SaveTwitterPostTextRow(pEvent, pStatus) {
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()


          vSBSMNewsArticle.UpdateDateTime = vUpdateDateTime       
          vSBSMNewsArticle.UpdateName = Users   
          vSBSMNewsArticle.SBSMStatus = pStatus

          let ArticleItem = {
            "id": vSBSMNewsArticle.id,
            "UserId": vSBSMNewsArticle.UserId,
            "QueryTerm": vSBSMNewsArticle.QueryTerm,
            "InfoType": vSBSMNewsArticle.InfoType,
            "Title": vSBSMNewsArticle.Title,
            "ImageURL": vSBSMNewsArticle.ImageURL,
            "ActualURL": vSBSMNewsArticle.ActualURL,
            "URL": vSBSMNewsArticle.URL,
            "Source": vSBSMNewsArticle.Source,
            "PubDate": vSBSMNewsArticle.PubDate,
            "SBSMStatus": vSBSMNewsArticle.SBSMStatus,
            "Penalty": vSBSMNewsArticle.Penalty,
            "UpVotes": vSBSMNewsArticle.UpVotes,
            "DownVotes": vSBSMNewsArticle.DownVotes,
            "AgeInHours": vSBSMNewsArticle.AgeInHours,
            "Score": vSBSMNewsArticle.Score,
            "IncludeInNewsLetter": vSBSMNewsArticle.IncludeInNewsLetter,
            "ArticleTextCollected": vSBSMNewsArticle.ArticleTextCollected,
            "ArticleAnalyzed": vSBSMNewsArticle.ArticleAnalyzed,
            "ArticleTrendAnalyzed": vSBSMNewsArticle.ArticleTrendAnalyzed,
            "TwitterPostCreated": vSBSMNewsArticle.TwitterPostCreated,
            "ArticlePublished": vSBSMNewsArticle.ArticlePublished,
            "Category": vSBSMNewsArticle.Category,
            "PeopleMentionedInTheArticle": vSBSMNewsArticle.PeopleMentionedInTheArticle,
            "ArticleSummary": vSBSMNewsArticle.ArticleSummary,
            "TextString": vSBSMNewsArticle.TextString,
            "ArticleURL": vSBSMNewsArticle.ArticleURL,
            "ArticleHtml": vSBSMNewsArticle.ArticleHtml,
            "ArticleS3Key": vSBSMNewsArticle.ArticleS3Key,
            "TwitterPost": vSBSMNewsArticle.TwitterPost,
            "TrendImpact": vSBSMNewsArticle.TrendImpact,
            "TrendImpactExplanation": vSBSMNewsArticle.TrendImpactExplanation,
            "TrendDemographics": vSBSMNewsArticle.TrendDemographics,
            "TrendDuration": vSBSMNewsArticle.TrendDuration,
            "TrendDurationExplanation": vSBSMNewsArticle.TrendDurationExplanation,
            "TrendSentiment": vSBSMNewsArticle.TrendSentiment,
            "TrendSentimentExplanation": vSBSMNewsArticle.TrendSentimentExplanation,
            "TrendDrivers": vSBSMNewsArticle.TrendDrivers,
            "TrendRisk": vSBSMNewsArticle.TrendRisk,
            "TrendRiskExplanation": vSBSMNewsArticle.TrendRiskExplanation,
            "Blocked": vSBSMNewsArticle.Blocked,
            "CreationName": vSBSMNewsArticle.CreationName,
            "UpdateName":vSBSMNewsArticle.UpdateName,
            "CreationDateTime": vSBSMNewsArticle.CreationDateTime,
            "UpdateDateTime": vSBSMNewsArticle.UpdateDateTime
          }

            //console.log("TwitterPostTextRow.SaveTwitterPostTextRow.ArticleItem:  ",ArticleItem) 
            
            const ReturnMessage = await client.graphql({
              query: updateSBSMNews,
              variables: {
                input: ArticleItem
              }              
            });  

            //console.log("TwitterPostTextRow.SaveTwitterPostTextRow.ReturnMessage.data.updateSBSMNews:  ",ReturnMessage.data.updateSBSMNews) 

            ErrorMessageStr = "Article saved at: " + today.toLocaleString("en-US")


            setvSBSMNewsArticle(ReturnMessage.data.updateSBSMNews) 
            setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          ErrorMessageStr = ("TwitterPostTextRow.SaveTwitterPostTextRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }    

    async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("TwitterPostTextRow.ExpandRow.pExpandRowTmp: " ,pExpandRowTmp);
        setvExpandRow(!pExpandRowTmp)

        setvExpandRowIcon(!pExpandRowTmp === true ? "-" : "+")


          } catch (err) { 

          ErrorMessageStr = "TwitterPostTextRow.ExpandRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }   

      function Field_handleChange(event, data, pButtonTitle) {
        try {
  
          //console.log("TwitterPostTextRow.Field_handleChange.pButtonTitle: " ,pButtonTitle);
  
          let vTmp = event.target.value
        
  
          if (pButtonTitle === "vTextString") {
            setvTextString(vTmp)
          } 
          
  
        } catch (err) {
          RhyteItLogging(
            Users,
            "TwitterPostTextRow",
            "Field_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      async function GetSBSMNewsArticle() {
        try {
          setErrorMessage("");
          //console.log("BlogCategoryPostHeader.getSBSMNews.vBlogPost: ", vBlogPost);
          let variables = {
            id: vSBSMNewsArticle["id"],
            UserId: vSBSMNewsArticle["UserId"],
            };
    
          const getSBSMNewsData = await client.graphql({
            query: getSBSMNews,
            variables: variables,
          });

          if (getSBSMNewsData.data.getSBSMNews !== undefined) {
    
            //console.log("TwitterPostTextRow.getSBSMNews.getSBSMNews.data.getSBSMNews.items: ", getSBSMNewsData.data.getSBSMNews);
            const TwitterPostTextRow1 =
            getSBSMNewsData.data.getSBSMNews;
            setvSBSMNewsArticle(TwitterPostTextRow1)
          } 
    
    
    
        } catch (err) {
  
          console.error("TwitterPostTextRow.getSBSMNews.Error.message:  ", err); 
          console.error("TwitterPostTextRow.getSBSMNews.Error.message:  ", err["errors"][0]["message"].toString()); 
          
          RhyteItLogging(
            "NA",
            "TwitterPostTextRow",
            "getSBSMNews",
            err["errors"][0]["message"].toString(),
            '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
            0
          );  
        }
      }         
            

  return (
              <TableRow  className={styles.TableRowBackgroundTitle}>                
                  <TableCell colspan="5" className={styles.TableCellDetailValue}>
                            <Text className={styles.RowDetail}>
                            {vTextString}
                            </Text>             
                  </TableCell>                                         
            </TableRow>  
  )
}

export default TwitterPostTextRow;