
import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead
  } from '@aws-amplify/ui-react'; 
import TwitterPostRow from './TwitterPostRow';  
import styles from './TwitterPostTable.module.css';
import { setUpTOTP } from 'aws-amplify/auth';


const TwitterPostTable = (props) => {       

  TwitterPostTable.propTypes = {
      SBSMNewsArticles: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true


    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [SBSMNewsArticles, setSBSMNewsArticles] = useState()
    const [ShowTable, setShowTable] = useState(false);

    useEffect(() => {

        //console.log("TwitterPostTable.useEffect[].props.SBSMArticles:  ",props.SBSMNewsArticles) 
        //console.log("TwitterPostTable.useEffect[].Object.keys(SBSMNewsArticles).length:  ",Object.keys(SBSMNewsArticles).length)
       // //console.log("TwitterPostTable.useEffect[].Object.Array.isArray(SBSMNewsArticles):  ",Array.isArray(SBSMNewsArticles))
         

        if (Object.entries(props.SBSMNewsArticles).length > 0) {

          let vArticlesTmp = SortArticlesByDate(Object.entries(props.SBSMNewsArticles))
          setSBSMNewsArticles(vArticlesTmp)

        }

      },[])  

      useEffect(() => {

        if (SBSMNewsArticles !== undefined) {
         
          setShowTable(true)
        }

        //console.log("TwitterPostTable.useEffect[].SBSMNewsArticles:  ",SBSMNewsArticles) 
  
      },[SBSMNewsArticles])  
      
      function SortArticlesByDate(pArticles) {
        pArticles.sort(GFG_sortFunction);
        return pArticles
        //    //console.log(JSON.stringify(pEmails));
      }

      function GFG_sortFunction(a, b) {
          let dateA = new Date(a.TwitterLastPostDate === null ? "1999-01-01T01:00:00.000Z" : a.TwitterLastPostDate).getTime();
          let dateB = new Date(b.TwitterLastPostDate === null ? "1999-01-01T01:00:00.000Z" : b.TwitterLastPostDate).getTime();
          return dateA < dateB ? 1 : -1;
      };    
    

  return ( 
    <div>
      <Table className={styles.RhyteItTable}   highlightOnHover={true}> 
        <TableHead>          
            <TableRow className={styles.TableHeaderRow}>
              <TableCell className={styles.TableHeaderRowCells}>Id</TableCell>   
              <TableCell className={styles.Title} >Title</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Twitter Post</TableCell>
              <TableCell className={styles.Count} >Post Date</TableCell> 
              <TableCell className={styles.Count} >Post Count</TableCell> 
              <TableCell className={styles.TableHeaderRowCells} >Actions</TableCell> 
            </TableRow>                                       
          </TableHead>   
              {
                ShowTable === true && Array.isArray(SBSMNewsArticles)
                ? SBSMNewsArticles.map((SBSMArticle) => (         
                <TwitterPostRow  key={SBSMArticle[1].id} SBSMArticle={SBSMArticle[1]}/>))  : null
                 
              }                    
        </Table>
      </div> 
    
  )
}

export default TwitterPostTable;


