
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Flex,
  Autocomplete, Loader , Text
  } from '@aws-amplify/ui-react'; 
import BlogEntry from './BlogEntry';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './BlogEntries.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listBlogPosts,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createBlogPosts,
  deleteBlogPosts
} from "../../../graphql/mutations";
import HeadlineArticle from './HeadlineArticle'



const BlogEntries = (props) => {       

  BlogEntries.propTypes = {
      Blog: PropTypes.object,
      SearchTerm: PropTypes.string,
      BlogPostFilter: PropTypes.string,
      BlogPosts: PropTypes.array,
      CellPhone:  PropTypes.bool
      };

    let debugConsoleOutPut = true
  

    const [vBlog, setvBlog] = useState(props.Blog)   
    const [ShowTable, setShowTable] = useState(false); 
    const [vSearchTerm, setvSearchTerm] = useState(props.SearchTerm); 
    const [vCellPhone, setvCellPhone] = useState(props.CellPhone); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vBlogPosts, setvBlogPosts] = useState(props.BlogPosts)   
    const [vBlogPostFilter, setvBlogPostFilter] = useState(props.BlogPostFilter);  

    const [nextToken, setnextToken] = useState() 
    const [IsLoading, setIsLoading] = useState(false)
    
    const [vBlogPostsReturned, setvBlogPostsReturned] = useState()     
    
    useEffect(() => {
      //console.log("BlogEntries.useEffect[].props.BlogPosts: " ,props.BlogPosts);
      //console.log("BlogEntries.useEffect[].props.SearchTerm: " ,props.SearchTerm);
 

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);

    useEffect(() => {
     
    }, [vBlog]);

    useEffect(() => {
      
      //console.log("BlogEntries.useEffect[vSearchTerm].vSearchTerm: " ,vSearchTerm);

      if (vBlogPosts !== undefined) {

        setvBlogPostsReturned(vBlogPosts === undefined ? 0 : vBlogPosts.length)
       
      }

    }, [vBlogPosts]);  

  return (   
    <section ref={ref} className = {styles.Section}>
      {vCellPhone === false && (  
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>               
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              className={styles.BlogPostRow}
            >         
            {
              vBlogPostsReturned !== undefined &&  (   

                vBlogPostsReturned > 0 && vBlogPosts.slice(0,1).map((vBlogPost) => (                      
                    (<HeadlineArticle   key={vBlogPost.id}  BlogPost={vBlogPost}/>)        
                    ))
                  )
                }
            {
              vBlogPostsReturned !== undefined &&  (   

                vBlogPostsReturned > 0 && vBlogPosts.slice(1,3).map((vBlogPost) => (                      
                    (<BlogEntry   key={vBlogPost.id}  BlogPost={vBlogPost}/>)           
                  ))
                )
              }    
                  
            </Flex>  
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="wrap"
              gap="1rem"
              className={styles.BlogPostSubRow}
            >         
            {
              vBlogPostsReturned !== undefined &&  (   

                vBlogPostsReturned > 0 && vBlogPosts.slice(3,vBlogPosts.length-1).map((vBlogPost) => (                      
                    (
                      <BlogEntry   key={vBlogPost.id}  BlogPost={vBlogPost}/>)        
                    ))

              )
            }                
            </Flex>                      
      {IsLoading === true && (
        <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
          > 
          <Text
            className={styles.Text}>Loading...</Text>
            <Loader />    
        </Flex>
        )  
      }            
      
        </Flex>  
      )}    

      {vCellPhone === true && (  
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.CellPhoneFlexColumn}>               
            {
              vBlogPostsReturned !== undefined &&  (   
                vBlogPostsReturned > 0 && vBlogPosts.map((vBlogPost) => (                      
                    (<BlogEntry   key={vBlogPost.id}  BlogPost={vBlogPost}/>)           
                  ))
                )
              } 
        </Flex>     
        )}    
      {IsLoading === true && (
        <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
          > 
          <Text
            className={styles.Text}>Loading...</Text>
            <Loader />    
        </Flex>
        )  
      }            
      
          
  </section>
  )
}

export default BlogEntries;


