import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types';
import styles from './MainMenuV2.module.css';
import {useNavigate, useLocation } from 'react-router-dom';
import MenuBackground from './MenuBackground';
import Header from "../RhyteItComponents/Header/Header"
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {
    Table,
    Button,
    TableBody,
    TableRow,
    TableCell    ,
    Flex,
    Link,
    Authenticator,
    Text,
    useAuthenticator,
    Fieldset
    } from '@aws-amplify/ui-react'; 
import { signOut } from 'aws-amplify/auth';



const MainMenuV2  = (props) => {
 
    MainMenuV2.propTypes = {
    UserId: PropTypes.string,
    UserName: PropTypes.string
  };

        const { authStatus } = useAuthenticator(context => [context.authStatus]);

        let navigate = useNavigate(); 
        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);   

        const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:true}]

        var ErrorMessageStr = ""

        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr) 

    useEffect(() => {
        

    },[])  
  
    useEffect(() => {   

        if ( authStatus !== "authenticated" ) {

                navigate('/AuthenticatorRhyteIt', 
                {
                }
        );  

        } else {

                if (CognitoGroup !== undefined ) {

                        const vCognitoGroup = Object.values(CognitoGroup).filter(cg => {
                                return cg ===  "rhyteitAdmins";
                        }).length;
                        
                        if (vCognitoGroup === 0) { 
                                navigate('/LandingPageV4', 
                                {
                                        state: {
                                        }
                                })

                        }  
                
                }
        }

       },[CognitoGroup])  

       

   

    async function handleSignOut() {
        try {
          await signOut();
        } catch (error) {
          console.log('error signing out: ', error);
        }
      }



  return (   
  <Authenticator>
    {({ signOut, user }) => (
        <div>                        
            <MenuBackground className={styles.MenuBackground} GradientStopColor1="#0000" GradientStopColor2="#000"  GradientStopColor3="#777"   GradientStopColor4="#8880"/> 
            <Flex direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                    alignContent="flex-start"
                    wrap="wrap"
                    gap="1rem"
                    className={styles.GridContainer}>    
                    <Header Title="Main Menu" SubTitle="Where you can find anything" BreadCrumbItems ={vBreadCrumbItems} pageSource="MainMenu"/>                             
                    <Text  className={styles.Text}>{Users} is {authStatus} to rhyteitAdmins</Text>
                    <Text  className={styles.ErrorMessage}  ErrorMessage={ErrorMessage}></Text>    
                    
                    <Text  className={styles.Text}>SMMAD</Text>  
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}>                           

                        <Link className={styles.Link} href="/SMMADPlatforms" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Platforms</Button>
                        </Link>     
                        <Link className={styles.Link} href="/SMMADCampaign" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Campaigns</Button>
                        </Link>                 
                        <Link className={styles.Link} href="/TurboFanBoostUser" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Manage accounts</Button>
                        </Link>     
                        <Link className={styles.Link} href="/SMMADBatchJobs" isExternal={false}>
                        <Button className={styles.metallicbutton}    
                                variation="primary">Batch Jobs</Button>        
                            
                        </Link>                 
                        <Link className={styles.Link} href="/SMMADOrganize" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Organize Campaigns</Button>
                        </Link>        
                        <Link className={styles.Link} href="/SMMADContentMetrics" isExternal={false}>
                        <Button className={styles.metallicbutton}    
                                variation="primary">Statistics</Button>        
                            
                        </Link>                                                                  
                    </Fieldset>
                    
                    <Text  className={styles.Text}>Blog Manager</Text> 
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}>                           

                        <Link className={styles.Link} href="/BlogManager" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Blog Manager</Button>
                        </Link>     
                        <Link className={styles.Link} href="/Blog" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Blog</Button>
                        </Link>                                                                                            
                    </Fieldset>                     
                    <Text  className={styles.Text}>Newsletter</Text>  
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}>                           

                        <Link className={styles.Link} href="/NewsletterManager" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Newsletter</Button>
                        </Link>                                                                                        
                    </Fieldset>
                    <Text  className={styles.Text}>Marketing</Text>
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}>  
                            <Link className={styles.Link} href="/Dashboard" isExternal={false}>  
                            
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Dashboard</Button>
                            </Link>   
                            <Link className={styles.Link} href="/RhyteItAccounts" isExternal={false}>
                            <Button className={styles.metallicbutton}    
                                    variation="primary">RhyteIt Accounts</Button>                                    
                            </Link>      
                            <Link className={styles.Link} href="/TwitterStats" isExternal={false}>
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Twitter Management</Button>        
                                
                            </Link>    
                            <Link className={styles.Link} href="/ApplicantManagement" isExternal={false}>
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Applicant Management</Button>        
                                
                            </Link>                                                                                            
                    </Fieldset>     
                    <Text  className={styles.Text}>Product Management</Text>       
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}>   
                            <Link className={styles.Link} href="/ProductManagement" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Product Management</Button>
                                
                            </Link>  
                            <Link className={styles.Link} href="/DigitalMarketPlace" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Digital Market</Button>
                                
                            </Link>      
                            <Link className={styles.Link} href="/CustomerPurchases" isExternal={false}>  
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Your purchases</Button>
                                
                            </Link>                                                                                                      
                    </Fieldset>  
                    <Text  className={styles.Text}>Email</Text>          
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}> 
                            <Link className={styles.Link} href="/EmailManagement" isExternal={false}>
                            <Button className={styles.metallicbutton}    
                                    variation="primary">RhyteIt Emails</Button>
                                
                            </Link>                                                                                                      
                    </Fieldset>      
                    <Text  className={styles.Text}>News Management</Text>  
                    <Fieldset variation="outlined"
                            direction="row"
                            gap="1rem"
                            className={styles.Fieldset}> 
                            <Link className={styles.Link} href="/SBSMQueries" isExternal={false}>
                            <Button className={styles.metallicbutton}    
                                    variation="primary">Google News Query Terms</Button>
                                
                            </Link>       
                                <Link className={styles.Link} href="/SBSMNewsAdmin" isExternal={false}>
                                <Button className={styles.metallicbutton}    
                                        variation="primary">SBSM News Admin</Button>
                                </Link>       
                                <Link className={styles.Link} href="/SBSMRSS" isExternal={false}>
                                    <Button className={styles.metallicbutton}    
                                        variation="primary">SBSM RSS Feeds</Button>
                                </Link>                                                                                                      
                    </Fieldset>      
                    <Text  className={styles.Text}>Support</Text>     
                    <Fieldset variation="outlined"
                        direction="row"
                        gap="1rem"
                        className={styles.Fieldset}> 
                        <Link className={styles.Link} href="https://discord.gg/QymmajFQ" isExternal={true}>
                        Discord
                        </Link>   
                        <Link className={styles.Link} href="https://twitter.com/RhyteIt" isExternal={true}>
                        Twitter
                        </Link>    
                        <Link className={styles.Link} href="https://medium.com/@support_13571" isExternal={true}>
                        Medium
                        </Link>        
                        <Link className={styles.Link} href="https://www.instagram.com/rhyteit/" isExternal={true}>
                        Instagram
                        </Link>     
                        <Button className={styles.Link}
                                variation="primary"                             
                                onClick={ (e) => handleSignOut()}>Sign Out
                        </Button>                                                                                                                               
                    </Fieldset>  
            </Flex>   
        </div>       )}
    </Authenticator>          
   )
                                    }

export default MainMenuV2