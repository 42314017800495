
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import {
    createBlogPosts,
    updateBlogPosts,
    deleteBlogPosts
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './HeadlineArticle.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function HeadlineArticle(
  props
) {       

  HeadlineArticle.propTypes = {
    BlogPost: PropTypes.object,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlogPost, setvBlogPost] = useState(props.BlogPost)  
    const [vPrompt, setvPrompt] = useState() 
    const [vPromptName, setvPromptName] = useState() 

    const [vSubRedditListString, setvSubRedditListString] = useState() 

    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vBlogPostActive, setvBlogPostActive] = useState(false)   
    const [vBlogPostHTMLString, setvBlogPostHTMLString] = useState()    
    
    
      useEffect(() => {

        console.log("HeadlineArticle.useEffect[].props.BlogPost:  ",props.BlogPost) 

      },[])  

      useEffect(() => {
      //console.log("HeadlineArticle.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {


      }, [vBlogPost]);   

      const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };

      return (
        <div className={styles.Background}
        onClick={(e) => openInNewTab(vBlogPost.PostURL)} >
        <Flex
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexLeftColumn}>   
        {
              vBlogPost.PictureURL !== null &&  (                        
          <Image
              src={vBlogPost.PictureURL}
              objectFit="initial"
              objectPosition="50% 50%"
              backgroundColor="initial"
              className={styles.Image}   
            />  
              )}  
              {
                    vBlogPost.PictureURL === null &&  (                        
                <Image
                    alt="Amplify logo"
                    src="https://news.rhyteit.com/RhyteItLogo.png"
                    objectFit="initial"
                    objectPosition="50% 50%"
                    backgroundColor="initial"
                    width="10vw"
                    height="15vh"
                    opacity="100%"
                    onClick={() => alert(vBlogPost.id)}
                  />  
                    )}                
         
             <Text className={styles.TextTitle} >
              {vBlogPost.Title}
             </Text>              
             <Text className={styles.Date} >
              {vBlogPost.Intro}
             </Text>
          <div
          className={styles.DateLine} >
            <Text
          className={styles.Date} 
          onClick={() => alert(vBlogPost.id)}>
             {new Date(vBlogPost.CreationDateTime).toLocaleDateString('en-US',{year: 'numeric',month: 'long',day: 'numeric'})}
             </Text>
          </div>
          </Flex>         
      </div>
      
    )
  }
export default HeadlineArticle;

