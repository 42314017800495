
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Flex,
  Autocomplete, Loader 
  } from '@aws-amplify/ui-react'; 
import BlogMetaDataTable from './BlogMetaDataTable';
import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './BlogMetaData.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  getBlog,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createBlogPrompts,
  deleteBlogPrompts
} from "../../../graphql/mutations";


const BlogMetaData = (props) => {       

  BlogMetaData.propTypes = {
      Blog: PropTypes.object,
      Users: PropTypes.string,
      };

    let debugConsoleOutPut = true
  

    const [vBlog, setvBlog] = useState(props.Blog)   
    const [Users, setUsers] = useState(props.Users)   
    const [ShowTable, setShowTable] = useState(false); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vBlogPrompts, setvBlogPrompts] = useState()   

    

    useEffect(() => {
      //console.log("BlogMetaData.useEffect[isInView].props.Blog: " ,props.Blog);
 

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);



    useEffect(() => {

    }, [vBlog]);

    async function GetBlog() {
      try {
        setErrorMessage("");
        //console.log("BlogCategoryPostHeader.getBlog.vBlogPost: ", vBlogPost);
        let variables = {
          id: vBlog["id"]
          };
  
        const getBlogData = await client.graphql({
          query: getBlog,
          variables: variables,
        });

        if (getBlogData.data.getBlog !== undefined) {
  
          //console.log("BlogMetaData.getBlog.getBlog.data.getBlog.items: ", getBlogData.data.getBlog);
          const vBlogTmp =
          getBlogData.data.getBlog;
          setvBlog(vBlogTmp)
        } 
  
  
  
      } catch (err) {

        console.error("BlogMetaData.getBlog.Error.message:  ", err); 
        console.error("BlogMetaData.getBlog.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          "NA",
          "BlogMetaData",
          "getBlog",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );  
      }
    }         
          

  return (   
    <section ref={ref} className = {styles.Section}>
      {
        vBlog !== undefined  && (                      
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>               
        <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="nowrap"
               gap="1rem"
               className={styles.FlexRow}>              
              <Button
                className={styles.Button}
                variation="primary"
                onClick={(e) => GetBlog(e)}
              >
                Refresh
            </Button>   
        </Flex>                
        <Table className={styles.Table}   highlightOnHover={true}>           
              {
               vBlog !== undefined && (                      
                  (<BlogMetaDataTable   key={vBlog.UpdateDateTime}  Users={Users} Blog={vBlog}/>)        
                  )
              }                    
        </Table>   
        </Flex>                 
      )}      
    </section>
  )
}

export default BlogMetaData;


