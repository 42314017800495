import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useRef  } from 'react';
import {ErrorBoundary} from 'react-error-boundary'
import Header from "../RhyteItComponents/Header/Header";


import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { fetchAuthSession } from 'aws-amplify/auth';
import  { listSBSMNews, listSBSMManagers}  from '../../graphql/queries'
import  { deleteSBSMNews, updateSBSMNews}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import SBSMNewsAdminTable from "./SBSMNewsAdminTable";
import SBSMNewsManager from "./SBSMNewsManager";
import TwitterPostTable from "./TwitterPostTable";
import {get_uuidv4} from '../../BusinessLogic/uuidv4';

import {
  Button ,
  Authenticator,
  Autocomplete,
  Flex,
  Loader,
  Text,
  SelectField ,
  SearchField
} from '@aws-amplify/ui-react';
import styles from './SBSMNewsAdmin.module.css';

function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}


const SBSMNewsAdmin = (props) => {       
  
  SBSMNewsAdmin.propTypes = {
          RedditUser: PropTypes.object
        };

        const [SBSMNewsArticles, setSBSMNewsArticles] = useState()


              const vBreadCrumbItems = [{Path: "/", Title:"Home", isCurrent:false},{Path: "/MainMenuV2", Title:"Menu", isCurrent:false},{Path: "/SBSMNewsAdmin", Title:"SBSM News Admin", isCurrent:true}]

        const client = generateClient();
        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [updatedKey, setupdatedKey] = useState(Date.now())   
        let debugConsoleOutPut = true

        const {Users, setUsers} = useContext(UserContext);    
        const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);       
        const [Article, setArticle] = useState([]);
        const [SearchTerm1, setSearchTerm1] = useState('');
        const [ShowTable, setShowTable] = useState(false); 
        const [nextToken, setnextToken] = useState(undefined) 
        const [IsLoading, setIsLoading] = useState(false)
        const [vArticlesReturned, setvArticlesReturned] = useState()
        const [vViewArticles, setvViewArticles] = useState(false)
        const [vViewParameters, setvViewParameters] = useState(false)     
        const [SBSMManagerParameters, setSBSMManagerParameters] = useState();        
        const [vSBSMNewsFilter, setvSBSMNewsFilter] = useState();     
        const [vFilter, setvFilter] = useState();
        
        const [vSearchTerm, setvSearchTerm] = useState();
        const inputRef = React.useRef(null);
        const searchButtonRef = React.useRef(null);
        
        
    useEffect(() => {

      window.scrollTo(0, 0);

    },[])

    useEffect(() => {
      
    (async function() {
      try {  

              if (CognitoGroup !== undefined) {

                let   GetGroupRoleAccessResponse = await GetGroupRoleAccess(CognitoGroup, "rhyteitAdmins")

              } else {

                const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

                if (idToken !== undefined) {
                  setUsers(idToken.payload.email)
                  setCognitoGroup(accessToken["payload"]["cognito:groups"])
                } else {                          
                  ReturnUserToLandingPage(Users, CognitoGroup)
                }

              }
            } catch (err) {                  
              RhyteItLogging(Users, "SBSMNewsAdmin", "useEffect[CognitoGroup]",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
          }
        }
      )();   

    },[CognitoGroup]) 

    useEffect(() => {
      window.scrollTo(0, 0);
      setvViewParameters(false)
      setvViewArticles(true)
    }, [updatedKey]); 

    useEffect(() => {

      if (vFilter !== undefined && SBSMNewsArticles !== undefined && IsLoading === false && nextToken === undefined) {

        let vArticlesSorted = SortArticlesByDate(SBSMNewsArticles)
        setSBSMNewsArticles(vArticlesSorted)
        setvViewArticles(true)

      }

      
      if (vFilter !== undefined && IsLoading === false && nextToken === undefined) {
        setvArticlesReturned(SBSMNewsArticles === undefined ? 0 : SBSMNewsArticles.length)
      }

    }, [IsLoading]);  

    useEffect(() => {

      //console.log("SBSMNewsAdmin.useEffect[vArticlesReturned].IsLoading: ",IsLoading);
      //console.log("SBSMNewsAdmin.useEffect[vArticlesReturned].SBSMNewsArticles: ",SBSMNewsArticles);
      //console.log("SBSMNewsAdmin.useEffect[vArticlesReturned].nextToken: ",nextToken);
      //console.log("SBSMNewsAdmin.useEffect[vArticlesReturned].vViewParameters: ",vViewParameters);
      //console.log("SBSMNewsAdmin.useEffect[vArticlesReturned].vViewArticles: ",vViewArticles);      
      //console.log("SBSMNewsAdmin.useEffect[vArticlesReturned].vArticlesReturned: ",vArticlesReturned);
      //console.log("-----------------------------------------------------------------");      

        if (vSBSMNewsFilter !== undefined && vArticlesReturned !== undefined && IsLoading === false && nextToken === undefined) {
          
          setErrorMessage("SBSM items retrieved at: " + new Date().toLocaleString() + " - # of articles: " + vArticlesReturned)
          //console.log("SBSMNewsAdmin.useEffect[vArticlesReturned].vArticlesReturned: ",vArticlesReturned);
          setupdatedKey(Date.now())

      }

    }, [vArticlesReturned]);    
    
    useEffect(() => {


      console.log("SBSMNewsAdmin.useEffect[vSBSMNewsFilter].vSBSMNewsFilter: ",vSBSMNewsFilter);

      //if (vSBSMNewsFilter !== undefined && ShowTable === false) {
      if (vSBSMNewsFilter !== undefined) {
        setSBSMNewsArticles()
        GetArticles()
      }


    }, [vSBSMNewsFilter]);   
    
    useEffect(() => {

      const fetch1 = async () => {
        

        try {

              let SBSMNewsArticlesData = null

              if(nextToken !== null && nextToken !== undefined) {

                
                setIsLoading(true)

                  let variables = {
                    nextToken,
                    filter: vSBSMNewsFilter
                  };
                  
      
                  const SBSMNewsArticlesData  = await client.graphql({
                    query: listSBSMNews,
                    variables: variables
                  });     

                
                  //console.log("SBSMNewsAdmin.fetch1.SBSMNewsArticlesData.RETURN:  ",SBSMNewsArticlesData)


                if (SBSMNewsArticlesData.data.listSBSMNews.items.length > 0) {

                    let vSBSMNewsArticlesDataTmp = SBSMNewsArticles   

                    if (vSBSMNewsArticlesDataTmp !== undefined) {
          
                        SBSMNewsArticlesData.data.listSBSMNews.items.forEach(item => 
                          {   
                        
                            if ([...vSBSMNewsArticlesDataTmp].filter(Content => item.id === Content.id)["length"] === 0) {  
                              vSBSMNewsArticlesDataTmp.push(item) 
                            }
      
                          })

                        setSBSMNewsArticles(vSBSMNewsArticlesDataTmp)  

                    } else {

                      setSBSMNewsArticles(SBSMNewsArticlesData.data.listSBSMNews.items)  

                    }

                    if (SBSMNewsArticlesData.data.listSBSMNews.nextToken !== null ) {
                      //console.log("SBSMNewsAdmin.fetch1.SBSMNewsArticlesData.data.listSBSMNews.nextToken.valid1:  ",SBSMNewsArticlesData.data.listSBSMNews.nextToken.substring(0, 4))
                      
                      if (SBSMNewsArticlesData.data.listSBSMNews.nextToken !== nextToken) {
                        setnextToken(SBSMNewsArticlesData.data.listSBSMNews.nextToken)    
                      }

                    } else if (SBSMNewsArticlesData.data.listSBSMNews.nextToken === null){          
                
                      //console.log("SBSMNewsAdmin.fetch1.vToken.null:  ",SBSMNewsArticlesData.data.listSBSMNews.nextToken)
                      setnextToken()
                      setIsLoading(false)  
                    }  
                      
                } else if (SBSMNewsArticlesData.data.listSBSMNews.items.length === 0) {
                
                  if (SBSMNewsArticlesData.data.listSBSMNews.nextToken !== null){                  
                    //console.log("SBSMNewsAdmin.fetch1.SBSMNewsArticlesData.data.listSBSMNews.nextToken.valid2:  ",SBSMNewsArticlesData.data.listSBSMNews.nextToken.substring(0, 4))
                    
                    if (SBSMNewsArticlesData.data.listSBSMNews.nextToken !== nextToken) {
                      setnextToken(SBSMNewsArticlesData.data.listSBSMNews.nextToken)    
                    }
                        
    
                  } else if (SBSMNewsArticlesData.data.listSBSMNews.nextToken === null){                  
                    setnextToken()
                    setIsLoading(false)         
    
                  }
              }
              
              } else {setIsLoading(false) }
  
        } catch (err) {
          
            console.error("SBSMNewsAdmin.useEffect.[nextToken].fetch1.Error: ", err, err.stack)

        } 
      }
    
      fetch1()

    }, [nextToken]) 
    
    useEffect(() => {
  
      if (SBSMManagerParameters !== undefined) {


        setvViewArticles(false)
        setvViewParameters(true)
        
    }

    }, [SBSMManagerParameters]);     

    useEffect(() => {
  
      console.log("SBSMNewsAdmin.useEffect[vFilter].vFilter: ",vFilter);

      if (vFilter !== undefined) {

        GetArticlesByFilter(vFilter)
          
      }

    }, [vFilter]);    
    
    const onClick = React.useCallback(() => {
      inputRef.current.focus();
      //console.log("Blog.onClick.inputRef.current.value: ", inputRef.current.value );
      setvSearchTerm(inputRef.current.value);
      setupdatedKey(Date.now());
    }, []);

    useEffect(() => {

      const searchButtonRefCurrent = searchButtonRef.current;
      if (searchButtonRef && searchButtonRefCurrent) {
        // Note: this example is contrived to demonstrate using refs.
        // Use the `onSubmit` prop on `SearchField` instead which
        // responds to input field `Enter` keypresses and Submit button clicks.
        searchButtonRefCurrent.addEventListener('click', onClick, false);
        return () => {
          searchButtonRefCurrent.removeEventListener('click', onClick, false);
        };
      }

    }, [onClick]);

    function SortArticlesByDate(pArticles) {
          pArticles.sort(GFG_sortFunction);
          return pArticles
      //    //console.log(JSON.stringify(pEmails));
    }

    function GFG_sortFunction(a, b) {
        let dateA = new Date(a.PubDate).getTime();
        let dateB = new Date(b.PubDate).getTime();
        return dateA < dateB ? 1 : -1;
    };    

    async function GetArticles() {
      try {
        //console.log("SBSMNewsAdmin.GetArticles.Start");

                  
                setErrorMessage("")

                  let variables = {
                    nextToken,
                    filter: vSBSMNewsFilter
                  };
                  
      
                  const SBSMNewsArticlesData  = await client.graphql({
                    query: listSBSMNews,
                    variables: variables
                  });    


                //console.log("SBSMNewsAdmin.GetArticles.SBSMNewsArticlesData.data.listSBSMNews: ",SBSMNewsArticlesData.data.listSBSMNews);
                
                if (SBSMNewsArticlesData.data.listSBSMNews.items.length > 0) {
                    //console.log("SBSMNewsArticlesData.data.listSBSMNews.items.length: ",SBSMNewsArticlesData.data.listSBSMNews.items.length);
                     
                    setSBSMNewsArticles(SBSMNewsArticlesData.data.listSBSMNews.items)    
                   
                    if (SBSMNewsArticlesData.data.listSBSMNews.nextToken !== null ) {
                      //console.log("SBSMNewsAdmin.GetArticles.SBSMNewsArticlesData.data.listSBSMNews.nextToken.valid:  ",SBSMNewsArticlesData.data.listSBSMNews.nextToken.substring(0, 4))
                      setnextToken(SBSMNewsArticlesData.data.listSBSMNews.nextToken)  

                    } 
                  
                  }  
                  
                  if (SBSMNewsArticlesData.data.listSBSMNews.nextToken !== null){                  
                      //console.log("SBSMNewsAdmin.GetArticles.SBSMNewsArticlesData.data.listSBSMNews.nextToken.valid:  ",SBSMNewsArticlesData.data.listSBSMNews.nextToken.substring(0, 4))
                      setnextToken(SBSMNewsArticlesData.data.listSBSMNews.nextToken)        
      
                    } 

                   else if (SBSMNewsArticlesData.data.listSBSMNews.nextToken === null){          
                
                      //console.log("SBSMNewsAdmin.GetArticles.vToken.null:  ",SBSMNewsArticlesData.data.listSBSMNews.nextToken)
                      setIsLoading(false)  
                    }                    

       
            

      } catch (err) { 
        ErrorMessageStr = ("SBSMNewsAdmin.GetArticles.Error: ", err.stack)
        console.error(ErrorMessageStr)
        ErrorMessageStr = ("SBSMNewsAdmin.GetArticles.Error: ", err)
        console.error(ErrorMessageStr)
        RhyteItLogging(Users, "EmailManagement", "GetArticles",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
    }     

    async function GetSBSMParameters() {
      try {
                setErrorMessage("")
                setShowTable(false)
                setvViewArticles(false)
                setvViewParameters(false)
                var date = new Date();
                date.setDate(date.getDate() - 1);
                var vUpdateDateTime = date.toISOString() 
                  
                  let variables = {
                  };
                  
      
                  const vSBSMParametersData  = await client.graphql({
                    query: listSBSMManagers,
                    variables: variables
                  });    


                //console.log("SBSMNewsAdmin.GetSBSMParameters.vSBSMParametersData.data.listSBSMManagers: ",vSBSMParametersData.data.listSBSMManagers);
                
                if (vSBSMParametersData.data.listSBSMManagers.items.length > 0) {
                    //console.log("SBSMNewsArticlesData.data.listSBSMNews.items.length: ",SBSMNewsArticlesData.data.listSBSMNews.items.length);
                     
                    setSBSMManagerParameters(vSBSMParametersData.data.listSBSMManagers.items)    
                  
                  
                  }  
       
            

      } catch (err) { 
        ErrorMessageStr = ("SBSMNewsAdmin.GetArticles.Error: ", err.stack)
        console.error(ErrorMessageStr)
        ErrorMessageStr = ("SBSMNewsAdmin.GetArticles.Error: ", err)
        console.error(ErrorMessageStr)
        RhyteItLogging(Users, "EmailManagement", "GetArticles",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
    }    

    async function RefreshRecords(event) {
      try {
                setSBSMNewsArticles()
                setnextToken()
                setIsLoading(false)
                setvArticlesReturned()
                setErrorMessage("")
                setShowTable(false)
                setvViewArticles(false)
                setvViewParameters(false)
                GetArticles()  
            

      } catch (err) { 
        ErrorMessageStr = ("SBSMNewsAdmin.RefreshRecords.Error: ", err.stack)
        console.error(ErrorMessageStr)
        ErrorMessageStr = ("SBSMNewsAdmin.RefreshRecords.Error: ", err)
        console.error(ErrorMessageStr)
        RhyteItLogging(Users, "SBSMNewsAdmin", "RefreshRecords",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
    }    

    async function GetArticlesByFilter(pFilter) {
      try {

                setvArticlesReturned()
                setShowTable(false)
                setvViewArticles(false)
                setvViewParameters(false)
                      

                var date = new Date();
                date.setDate(date.getDate() - 1);
                var Yesterday = date.toISOString() 
                
                date.setDate(date.getDate() - 7);
                var LastWeek = date.toISOString() 

                //console.log("SBSMNewsAdmin.GetArticlesByFilter.LastWeek:  ",LastWeek)

                let vFilter = ""

                if (pFilter === "TODAYARTICLES") {
                  vFilter = {
                        ArticleTextCollected:{eq: false},
                        Blocked: {eq: false}, 
                        CreationDateTime: {
                          gt: Yesterday
                        }
                      }
                } else if (pFilter === "PUBLISHEDTODAY") {

                  vFilter = {
                    ArticlePublished:{eq: true}, 
                    UpdateDateTime: {
                      gt: Yesterday
                    }
                  }              

                } else if (pFilter === "READY2ANALYZE") {

                    vFilter = {
                      Blocked: {eq: false}, 
                      ArticleTextCollected:{eq: true},
                      ArticleAnalyzed:{eq: false}, 
                      ArticleTrendAnalyzed:{eq: false}, 
                      TwitterPostCreated:{eq: false}, 
                      ArticlePublished:{eq: false}
                    }              

                } else if (pFilter === "READY2PUBLISH") {

                  vFilter = {
                    Blocked: {eq: false}, 
                    ArticleTextCollected:{eq: true}, 
                    ArticleAnalyzed:{eq: true}, 
                    ArticleTrendAnalyzed:{eq: true}, 
                    TwitterPostCreated:{eq: true}, 
                    ArticlePublished:{eq: false}, 
                    CreationDateTime: {gt: LastWeek}
                  }  
                  
                } else if (pFilter === "PUBLISHED") {

                  vFilter = {
                    Blocked: {eq: false}, 
                    ArticleTextCollected:{eq: true}, 
                    ArticleAnalyzed:{eq: true}, 
                    ArticleTrendAnalyzed:{eq: true}, 
                    TwitterPostCreated:{eq: true}, 
                    ArticlePublished:{eq: true}, 
                    CreationDateTime: {
                      gt: Yesterday
                    }
                  }  
                  
                } else if (pFilter === "TWITTERPOSTS") {

                  vFilter = {
                    TwitterPostCreated:{eq: true}, 
                    CreationDateTime: {gt: LastWeek}
                  }  
                  
                }  else if (pFilter === "ID") {
                  vFilter = {
                        id:{eq: vSearchTerm}
                      }
                } else if (pFilter === "TITLE") {
        
                    vFilter = {
                      ArticleTitle: {contains: vSearchTerm}
                    }          
        
                } else if (pFilter === "TEXT") {
        
                  vFilter = {
                    TextString: {contains: vSearchTerm}
                  }  
                  
                } 
       
              setvSBSMNewsFilter(vFilter)
            

      } catch (err) { 
        ErrorMessageStr = ("SBSMNewsAdmin.setArticleFilter.Error: ", err.stack)
        console.error(ErrorMessageStr)
        ErrorMessageStr = ("SBSMNewsAdmin.setArticleFilter.Error: ", err)
        console.error(ErrorMessageStr)
        RhyteItLogging(Users, "SBSMNewsAdmin", "setArticleFilter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                  }
    }        

    async function SaveSBSMNewsArticle(pArticle, pStatus) {
      try {           
          var today = new Date();
          
          var vUpdateDateTime = new Date().toISOString()


            pArticle.UpdateDateTime = vUpdateDateTime       
            pArticle.UpdateName = Users   
            pArticle.SBSMStatus = pStatus

            //console.log("SBSMNewsAdminRow.SaveSBSMNewsArticle.pArticle:  ",pArticle) 

            let ArticleItem = {
              "id": pArticle.id,
              "UserId": pArticle.UserId,
              "QueryTerm": pArticle.QueryTerm,
              "InfoType": pArticle.InfoType,
              "Title": pArticle.Title,
              "ImageURL": pArticle.ImageURL,
              "ActualURL": pArticle.ActualURL,
              "URL": pArticle.URL,
              "Source": pArticle.Source,
              "PubDate": pArticle.PubDate,
              "SBSMStatus": pArticle.SBSMStatus,
              "Penalty": pArticle.Penalty,
              "UpVotes": pArticle.UpVotes,
              "DownVotes": pArticle.DownVotes,
              "AgeInHours": pArticle.AgeInHours,
              "Score": pArticle.Score,
              "IncludeInNewsLetter": pArticle.IncludeInNewsLetter,
              "ArticleTextCollected": pArticle.ArticleTextCollected,
              "ArticleAnalyzed": pArticle.ArticleAnalyzed,
              "ArticleTrendAnalyzed": pArticle.ArticleTrendAnalyzed,
              "TwitterPostCreated": pArticle.TwitterPostCreated,
              "ArticlePublished": pArticle.ArticlePublished,
              "Category": pArticle.Category,
              "PeopleMentionedInTheArticle": pArticle.PeopleMentionedInTheArticle,
              "ArticleSummary": pArticle.ArticleSummary,
              "TextString": pArticle.TextString,
              "ArticleURL": pArticle.ArticleURL,
              "ArticleHtml": pArticle.ArticleHtml,
              "ArticleS3Key": pArticle.ArticleS3Key,
              "TwitterPost": pArticle.TwitterPost,
              "TrendImpact": pArticle.TrendImpact,
              "TrendImpactExplanation": pArticle.TrendImpactExplanation,
              "TrendDemographics": pArticle.TrendDemographics,
              "TrendDuration": pArticle.TrendDuration,
              "TrendDurationExplanation": pArticle.TrendDurationExplanation,
              "TrendSentiment": pArticle.TrendSentiment,
              "TrendSentimentExplanation": pArticle.TrendSentimentExplanation,
              "TrendDrivers": pArticle.TrendDrivers,
              "TrendRisk": pArticle.TrendRisk,
              "TrendRiskExplanation": pArticle.TrendRiskExplanation,
              "Blocked": pArticle.Blocked,
              "CreationName": pArticle.CreationName,
              "UpdateName":pArticle.UpdateName,
              "CreationDateTime": pArticle.CreationDateTime,
              "UpdateDateTime": pArticle.UpdateDateTime
            }
  
            
            const ReturnMessage = await client.graphql({
              query: updateSBSMNews,
              variables: {
                input: ArticleItem
              }              
            });  

   
            //console.log("SBSMNewsAdmin.SaveSBSMNewsArticle.ReturnMessage.data.updateSBSMNews:  ",ReturnMessage.data.updateSBSMNews) 

            ErrorMessageStr = "Analysis Process kicked off: " + today.toLocaleString("en-US")

            setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          ErrorMessageStr = ("SBSMNewsAdmin.SaveSBSMNewsArticle.Error: ", err)
          ErrorMessageStr = ("SBSMNewsAdmin.SaveSBSMNewsArticle.ErrorStack: ", err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }  

    async function BatchUpdateOfStatus(event, pStatus) {
      try {           


        setErrorMessage("....Analyzing")
        
        for (const article of SBSMNewsArticles) {
          let vResponse = await SaveSBSMNewsArticle(article, pStatus);
        }  

        let vRefresh = RefreshRecords(event)

        } catch (err) { 
          ErrorMessageStr = ("SBSMNewsAdmin.SaveSBSMNewsArticle.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }  

    async function vClearSearchBar(event) {
      try {
    
        setvSearchTerm()
      
    } catch (err) {
        RhyteItLogging(
          "NA",
          "SBSMNewsAdmin",
          "vClearSearchBar",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }    

    async function vSearchTermChanged(event, data) {
      try {
  

        console.log("SBSMNewsAdmin.vSearchTermChanged.event.target.value:  ",event.target.value)

        let vTmp = event.target.value
  
          setvSearchTerm(vTmp);
      
    } catch (err) {
      RhyteItLogging(
        "NA",
        "SBSMNewsAdmin",
        "vSearchTermChanged",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
    }

  
    async function GetSearch(event, pAttribute) {
      try {

        console.log("SBSMNewsAdmin.GetSearch.pAttribute: ", pAttribute );

        setvArticlesReturned()
        setShowTable(false)
        setvViewArticles(false)
        setvViewParameters(false)
              

        var date = new Date();
        date.setDate(date.getDate() - 1);
        var Yesterday = date.toISOString() 
        
        date.setDate(date.getDate() - 7);
        var LastWeek = date.toISOString() 

        console.log("SBSMNewsAdmin.GetSearch.vSearchTerm:  ",vSearchTerm)

        let vFilter = ""

        if (pAttribute === "ID") {
          vFilter = {
                id:{eq: vSearchTerm}
              }
        } else if (pAttribute === "TITLE") {

            vFilter = {
              ArticleTitle: {contains: vSearchTerm}
            }          

        } else if (pAttribute === "TEXT") {

          vFilter = {
            TextString: {contains: vSearchTerm}
          }  
          
        } 



      console.log("SBSMNewsAdmin.GetSearch.vFilter:  ",vFilter)

      setvSBSMNewsFilter(vFilter)        
      
    } catch (err) {
      RhyteItLogging(
        "NA",
        "SBSMNewsAdmin",
        "GetSearch",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
    }



    return ( 
      <Authenticator  className={styles.AuthenticatorLogin}  loginMechanisms={['email']} socialProviders={[]} hideSignUp={true}>
        {({ signOut, user }) => (   
          <div className={styles.Background}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                <Flex
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  alignContent="flex-start"
                  wrap="wrap"
                  gap="3rem"
                  className={styles.GridContainer}
                >        
                      <Header Title="SBSM News Administration" SubTitle="Manage the SBSM News" BreadCrumbItems ={vBreadCrumbItems} pageSource="SBSMNewsAdmin"/>                            
                      <Flex
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          alignContent="center"
                          wrap="nowrap"
                          gap="1rem"
                          className={styles.FlexRow}>  
                            <SelectField
                                label="Fruit"
                                labelHidden
                                className={styles.SelectField}
                                placeholder="Select Query"
                                value={vFilter}
                                onChange={(e) => setvFilter(e.target.value)}
                              >
                                <option className={styles.SelectFieldOption} value="TODAYARTICLES">Last 24 hours</option>
                                <option className={styles.SelectFieldOption} value="READY2ANALYZE">Ready to analyze</option>
                                <option className={styles.SelectFieldOption} value="READY2PUBLISH">Ready to publish</option>
                                <option className={styles.SelectFieldOption} value="PUBLISHED">New articles published today</option>
                                <option className={styles.SelectFieldOption} value="PUBLISHEDTODAY">All articles published today</option>
                                
                                <option className={styles.SelectFieldOption} value="TWITTERPOSTS">Twitter posts</option>
                            </SelectField>                                        
                            <Button
                              loadingText=""
                              ariaLabel=""
                              className={styles.Button}
                              onClick={ (e) => GetSBSMParameters(e)}
                            >
                            Parameters
                            </Button>                                                                                                             
                      </Flex>     
                      <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.FlexRow}>  
                          
                      {vViewArticles === true && vViewParameters === false && SBSMNewsArticles !== undefined && (                          
                          <Button
                            loadingText=""
                            ariaLabel=""
                            className={styles.Button}
                            onClick={ (e) => RefreshRecords(e)}
                          >
                          Refresh
                          </Button>
                            )  
                          }     
                          {vFilter === "READY2ANALYZE" && vArticlesReturned > 0 && (      
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.Button}
                                onClick={ (e) => BatchUpdateOfStatus(e, "ANALYZE")}
                              >
                              Analyze All
                              </Button>  
                            )  
                          }     
                          {(vFilter === "READY2PUBLISH" || vFilter === "PUBLISHED") && vArticlesReturned > 0  && (      
                              <Button
                                loadingText=""
                                ariaLabel=""
                                className={styles.Button}
                                onClick={ (e) => BatchUpdateOfStatus(e, "PUBLISH")}
                              >
                              Publish All
                              </Button>  
                            )  
                          }                                                                                          
                      </Flex>
                      <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.SubscribeRow}
                    >                  
                            <SearchField
                              placeholder="Search here..."
                              hasSearchIcon={true}
                              labelHidden={true}
                              hasSearchButton={false}
                              onClear={(e) => vClearSearchBar(e)}
                              onChange={(e) => vSearchTermChanged(e, vSearchTerm)}
                              value={vSearchTerm}
                              className={styles.SearchField}
                              ref={inputRef}
                              searchButtonRef={searchButtonRef}
                            />
                            <Button
                                      className={styles.Button}
                                      onClick={(e) => setvFilter("ID")}
                                      
                                    >Search</Button> 

                      </Flex>                         
                      {vArticlesReturned !== undefined && vViewParameters === false && IsLoading === false && ( 
                      <Flex
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      alignContent="center"
                      wrap="nowrap"
                      gap="1rem"
                      className={styles.FlexRow}>  
                        <div  className={styles.ErrorMessage} ErrorMessage={ErrorMessage}>{ErrorMessage}</div>                                                                                                          
                      </Flex>  
                        )  
                      }  
                      {vArticlesReturned === undefined && IsLoading === true && vViewParameters === false && (
                        <Flex
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                            gap="1rem"
                          > 
                          <Text
                            className={styles.TextLoading}>Loading...</Text>
                            <Loader />    
                        </Flex>
                        )  
                      }  
                      {vFilter !== "TWITTERPOSTS" && vViewArticles === true && vViewParameters === false && SBSMNewsArticles !== undefined && (
                          <SBSMNewsAdminTable  key={updatedKey} SBSMNewsArticles={SBSMNewsArticles}/>
                        )  
                      }
                      {vFilter !== "TWITTERPOSTS" && vViewArticles === false && vViewParameters === true && (
                          <SBSMNewsManager  key={updatedKey} SBSMManagerParameters={SBSMManagerParameters}/>
                        )  
                      }
                      {vFilter === "TWITTERPOSTS" && vViewArticles === true && SBSMNewsArticles !== undefined && vViewParameters === false && (
                          <TwitterPostTable  key={updatedKey} SBSMNewsArticles={SBSMNewsArticles}/>
                        )  
                      }


                </Flex>     
            </ErrorBoundary>
          </div>    
        )}         
      </Authenticator>                     

    )

  }
 

export default SBSMNewsAdmin;