/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccountGroups = /* GraphQL */ `
  query GetAccountGroups($id: ID!) {
    getAccountGroups(id: $id) {
      id
      AccountGroup
      AccountType
      ParentAccount
      Modules
      AccountGroupStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listAccountGroups = /* GraphQL */ `
  query ListAccountGroups(
    $filter: ModelAccountGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AccountGroup
        AccountType
        ParentAccount
        Modules
        AccountGroupStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAccountGroupAccounts = /* GraphQL */ `
  query GetAccountGroupAccounts($id: ID!) {
    getAccountGroupAccounts(id: $id) {
      id
      AccountGroup
      Account
      AccountGroupAccountStatus
      Modules
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listAccountGroupAccounts = /* GraphQL */ `
  query ListAccountGroupAccounts(
    $filter: ModelAccountGroupAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccountGroupAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AccountGroup
        Account
        AccountGroupAccountStatus
        Modules
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSecrets = /* GraphQL */ `
  query GetSecrets($id: ID!) {
    getSecrets(id: $id) {
      id
      Name
      Type
      Value
      SecretValue
      Status
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSecrets = /* GraphQL */ `
  query ListSecrets(
    $filter: ModelSecretsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecrets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Type
        Value
        SecretValue
        Status
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getApplicants = /* GraphQL */ `
  query GetApplicants($id: ID!, $UserId: String!) {
    getApplicants(id: $id, UserId: $UserId) {
      id
      UserId
      UserEmail
      Type
      UserLastName
      UserFirstName
      UserDialCode
      UserPhone
      SocialMediaProfile
      ipAddress
      PrivacyAcknowledgement
      Application
      EmailTemplate
      Role
      Status
      Active
      NewsLetterStatus
      NewsLetterConsent
      InOnlyFansProspectingProfile
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listApplicants = /* GraphQL */ `
  query ListApplicants(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelApplicantsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplicants(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        UserEmail
        Type
        UserLastName
        UserFirstName
        UserDialCode
        UserPhone
        SocialMediaProfile
        ipAddress
        PrivacyAcknowledgement
        Application
        EmailTemplate
        Role
        Status
        Active
        NewsLetterStatus
        NewsLetterConsent
        InOnlyFansProspectingProfile
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMeetings = /* GraphQL */ `
  query GetMeetings($id: ID!, $UserEmail: AWSEmail!) {
    getMeetings(id: $id, UserEmail: $UserEmail) {
      id
      UserEmail
      UserType
      MeetingType
      MeetingStatus
      MeetingContext
      ipAddress
      MeetingDateTime
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listMeetings = /* GraphQL */ `
  query ListMeetings(
    $id: ID
    $UserEmail: ModelStringKeyConditionInput
    $filter: ModelMeetingsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMeetings(
      id: $id
      UserEmail: $UserEmail
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserEmail
        UserType
        MeetingType
        MeetingStatus
        MeetingContext
        ipAddress
        MeetingDateTime
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      Description
      URL
      Type
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $id: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Description
        URL
        Type
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($id: ID!, $UserId: String!) {
    getUsers(id: $id, UserId: $UserId) {
      id
      UserId
      UserLastName
      UserFirstName
      UserEmail
      UserDialCode
      UserPhone
      PrivacyAcknowledgement
      Application
      SocialMediaProfile
      RedditUserID
      RedditPassword
      CreationName
      UpdateName
      Active
      StripeCustomerId
      StripeSubscriptionId
      StripeInvoiceURL
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        UserLastName
        UserFirstName
        UserEmail
        UserDialCode
        UserPhone
        PrivacyAcknowledgement
        Application
        SocialMediaProfile
        RedditUserID
        RedditPassword
        CreationName
        UpdateName
        Active
        StripeCustomerId
        StripeSubscriptionId
        StripeInvoiceURL
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComments = /* GraphQL */ `
  query GetComments($id: ID!, $UserId: String!) {
    getComments(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      Comment
      CommentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelCommentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listComments(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        SubReddit
        SubRedditId
        Comment
        CommentStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailAccounts = /* GraphQL */ `
  query GetEmailAccounts($id: ID!, $UserId: String!) {
    getEmailAccounts(id: $id, UserId: $UserId) {
      id
      UserId
      Password
      FirstName
      LastName
      Name
      NameNonSpaced
      BirthMonth
      BirthDay
      BirthYear
      StartDate
      EndDate
      EmailAccountProvider
      EmailAccount
      Location
      SBSMNewsBio
      ChromiumProfile
      GmailAccount
      GmailPassword
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterBio
      TwitterAppName
      TwitterAppId
      TwitterStatus
      RedditAccount
      RedditPassword
      OnlyFansAccount
      MediumAccount
      InstagramAccount
      InstagramPassword
      VerificationProvider
      VerificationCode
      OpenAIOrgKey
      OpenAIapiKey
      AccountStatus
      Prompt
      NegativePrompt
      Model
      ProfileImageURL
      MageSeed
      Industry
      ProcessGroup
      RedoImage
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listEmailAccounts = /* GraphQL */ `
  query ListEmailAccounts(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelEmailAccountsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmailAccounts(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        Password
        FirstName
        LastName
        Name
        NameNonSpaced
        BirthMonth
        BirthDay
        BirthYear
        StartDate
        EndDate
        EmailAccountProvider
        EmailAccount
        Location
        SBSMNewsBio
        ChromiumProfile
        GmailAccount
        GmailPassword
        TwitterAccount
        TwitterPassword
        TwitterConsumerKey
        TwitterConsumerSecret
        TwitterBearerToken
        TwitterAccessToken
        TwitterAccessTokenSecret
        TwitterClientId
        TwitterClientSecret
        TwitterBio
        TwitterAppName
        TwitterAppId
        TwitterStatus
        RedditAccount
        RedditPassword
        OnlyFansAccount
        MediumAccount
        InstagramAccount
        InstagramPassword
        VerificationProvider
        VerificationCode
        OpenAIOrgKey
        OpenAIapiKey
        AccountStatus
        Prompt
        NegativePrompt
        Model
        ProfileImageURL
        MageSeed
        Industry
        ProcessGroup
        RedoImage
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmailAccountStats = /* GraphQL */ `
  query GetEmailAccountStats($id: ID!, $StatDate: AWSDateTime!) {
    getEmailAccountStats(id: $id, StatDate: $StatDate) {
      id
      AccountId
      StatDate
      UserId
      TwitterFollowing
      TwitterFollowingScheduled
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowingIncrScheduled
      TwitterFollowers
      TwitterFollowersScheduled
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterFollowersIncrScheduled
      TwitterTweets
      TwitterTweetsScheduled
      TwitterTweetsIncr
      TwitterTweetsPer
      TwitterTweetsIncrScheduled
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listEmailAccountStats = /* GraphQL */ `
  query ListEmailAccountStats(
    $id: ID
    $StatDate: ModelStringKeyConditionInput
    $filter: ModelEmailAccountStatsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmailAccountStats(
      id: $id
      StatDate: $StatDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        AccountId
        StatDate
        UserId
        TwitterFollowing
        TwitterFollowingScheduled
        TwitterFollowingIncr
        TwitterFollowingPer
        TwitterFollowingIncrScheduled
        TwitterFollowers
        TwitterFollowersScheduled
        TwitterFollowersIncr
        TwitterFollowersPer
        TwitterFollowersIncrScheduled
        TwitterTweets
        TwitterTweetsScheduled
        TwitterTweetsIncr
        TwitterTweetsPer
        TwitterTweetsIncrScheduled
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRhyteItEmails = /* GraphQL */ `
  query GetRhyteItEmails($id: ID!, $EmailAccount: String!) {
    getRhyteItEmails(id: $id, EmailAccount: $EmailAccount) {
      id
      EmailAccount
      From
      To
      Subject
      S3Bucket
      S3Key
      SESMessageId
      EmailType
      EmailCampaign
      EmailCampaignSendDateTime
      EmailCampaignStatus
      EmailText
      ReplyText
      EmailHtml
      EmailRawText
      EmailStatus
      EmailOpened
      EmailOpenedDateTime
      DeliveryStatus
      BounceReason
      ContentType
      Size
      SizeUnits
      ContentTransferEncoding
      InReplyTo
      MessageID
      CharSet
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listRhyteItEmails = /* GraphQL */ `
  query ListRhyteItEmails(
    $id: ID
    $EmailAccount: ModelStringKeyConditionInput
    $filter: ModelRhyteItEmailsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRhyteItEmails(
      id: $id
      EmailAccount: $EmailAccount
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        EmailAccount
        From
        To
        Subject
        S3Bucket
        S3Key
        SESMessageId
        EmailType
        EmailCampaign
        EmailCampaignSendDateTime
        EmailCampaignStatus
        EmailText
        ReplyText
        EmailHtml
        EmailRawText
        EmailStatus
        EmailOpened
        EmailOpenedDateTime
        DeliveryStatus
        BounceReason
        ContentType
        Size
        SizeUnits
        ContentTransferEncoding
        InReplyTo
        MessageID
        CharSet
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRhyteItEmailAttachments = /* GraphQL */ `
  query GetRhyteItEmailAttachments($id: ID!, $EmailId: String!) {
    getRhyteItEmailAttachments(id: $id, EmailId: $EmailId) {
      id
      EmailId
      ContentType
      ContentDisposition
      ContentTransferEncoding
      ContentID
      XAttachmentId
      Size
      SizeUnits
      S3Bucket
      S3Key
      Text
      EmailAttachmentStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listRhyteItEmailAttachments = /* GraphQL */ `
  query ListRhyteItEmailAttachments(
    $id: ID
    $EmailId: ModelStringKeyConditionInput
    $filter: ModelRhyteItEmailAttachmentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRhyteItEmailAttachments(
      id: $id
      EmailId: $EmailId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        EmailId
        ContentType
        ContentDisposition
        ContentTransferEncoding
        ContentID
        XAttachmentId
        Size
        SizeUnits
        S3Bucket
        S3Key
        Text
        EmailAttachmentStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTwitterFollowerCollectionStats = /* GraphQL */ `
  query GetTwitterFollowerCollectionStats(
    $id: ID!
    $RunDateTime: AWSDateTime!
  ) {
    getTwitterFollowerCollectionStats(id: $id, RunDateTime: $RunDateTime) {
      id
      RunDateTime
      RhyteItAccount
      Twitter
      FollowersCollected
      Duration
      IpAddress
      Hostname
      CreationName
      CreationDateTime
      updatedAt
      __typename
    }
  }
`;
export const listTwitterFollowerCollectionStats = /* GraphQL */ `
  query ListTwitterFollowerCollectionStats(
    $id: ID
    $RunDateTime: ModelStringKeyConditionInput
    $filter: ModelTwitterFollowerCollectionStatsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTwitterFollowerCollectionStats(
      id: $id
      RunDateTime: $RunDateTime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        RunDateTime
        RhyteItAccount
        Twitter
        FollowersCollected
        Duration
        IpAddress
        Hostname
        CreationName
        CreationDateTime
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSocialMediaProcesses = /* GraphQL */ `
  query GetSocialMediaProcesses($id: ID!, $ProcessGroup: String!) {
    getSocialMediaProcesses(id: $id, ProcessGroup: $ProcessGroup) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSocialMediaProcesses = /* GraphQL */ `
  query ListSocialMediaProcesses(
    $id: ID
    $ProcessGroup: ModelStringKeyConditionInput
    $filter: ModelSocialMediaProcessesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSocialMediaProcesses(
      id: $id
      ProcessGroup: $ProcessGroup
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Industry
        ProcessGroup
        Process
        Task
        Step
        Example
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRhyteItTweets = /* GraphQL */ `
  query GetRhyteItTweets($id: ID!, $ProcessGroup: String!) {
    getRhyteItTweets(id: $id, ProcessGroup: $ProcessGroup) {
      id
      Industry
      ProcessGroup
      Process
      Task
      Step
      Example
      Tweet
      RhyteItAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listRhyteItTweets = /* GraphQL */ `
  query ListRhyteItTweets(
    $id: ID
    $ProcessGroup: ModelStringKeyConditionInput
    $filter: ModelRhyteItTweetsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRhyteItTweets(
      id: $id
      ProcessGroup: $ProcessGroup
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        Industry
        ProcessGroup
        Process
        Task
        Step
        Example
        Tweet
        RhyteItAccount
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCommentPosts = /* GraphQL */ `
  query GetCommentPosts($id: ID!, $UserId: String!) {
    getCommentPosts(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      PostTitle
      PostAuthor
      PostURL
      PostDateTime
      CommentPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listCommentPosts = /* GraphQL */ `
  query ListCommentPosts(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelCommentPostsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCommentPosts(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        SubReddit
        SubRedditId
        PostTitle
        PostAuthor
        PostURL
        PostDateTime
        CommentPostStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQueryTerms = /* GraphQL */ `
  query GetQueryTerms($id: ID!, $UserId: String!) {
    getQueryTerms(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listQueryTerms = /* GraphQL */ `
  query ListQueryTerms(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelQueryTermsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQueryTerms(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        QueryTermStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubRedditPosts = /* GraphQL */ `
  query GetSubRedditPosts($id: ID!, $UserId: String!) {
    getSubRedditPosts(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      SubReddit
      PostId
      PostTitle
      PostURL
      NewsURL
      NewsSource
      NewsPubDate
      SubRedditPostStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSubRedditPosts = /* GraphQL */ `
  query ListSubRedditPosts(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelSubRedditPostsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubRedditPosts(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        SubReddit
        PostId
        PostTitle
        PostURL
        NewsURL
        NewsSource
        NewsPubDate
        SubRedditPostStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSBSMNewsQueryTerms = /* GraphQL */ `
  query GetSBSMNewsQueryTerms($id: ID!, $UserId: String!) {
    getSBSMNewsQueryTerms(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      QueryTermStatus
      LogImageFileLocation
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSBSMNewsQueryTerms = /* GraphQL */ `
  query ListSBSMNewsQueryTerms(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelSBSMNewsQueryTermsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSBSMNewsQueryTerms(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        QueryTermStatus
        LogImageFileLocation
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSBSMManager = /* GraphQL */ `
  query GetSBSMManager($id: ID!) {
    getSBSMManager(id: $id) {
      id
      AccountGroup
      Account
      SBSMTitle
      Description
      OpenAIPrompt
      OpenAITrendAnalysisPrompt
      OpenAITwitterPrompt
      OpenAIHtmlPrompt
      OpenAIImagePrompt
      OpenAIapiKey
      OpenAIOrganization
      OpenAIProject
      OpenAIModel
      AWSBedrockModelIdImage
      ArticleHtmlHeadSection
      ArticleHtmlStyleSection
      ArticleHtmlHeaderSection
      ArticleHtmlAuthorInfoSection
      ArticleHtmlFooterSection
      ArticleHtmlGooglePublishingSection
      S3Bucket
      S3Prefix
      Author
      LogoUrl
      IconURL
      Url
      Organization
      Categories
      NewsSourceCount
      ArticleCount
      TopicCount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSBSMManagers = /* GraphQL */ `
  query ListSBSMManagers(
    $filter: ModelSBSMManagerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSBSMManagers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AccountGroup
        Account
        SBSMTitle
        Description
        OpenAIPrompt
        OpenAITrendAnalysisPrompt
        OpenAITwitterPrompt
        OpenAIHtmlPrompt
        OpenAIImagePrompt
        OpenAIapiKey
        OpenAIOrganization
        OpenAIProject
        OpenAIModel
        AWSBedrockModelIdImage
        ArticleHtmlHeadSection
        ArticleHtmlStyleSection
        ArticleHtmlHeaderSection
        ArticleHtmlAuthorInfoSection
        ArticleHtmlFooterSection
        ArticleHtmlGooglePublishingSection
        S3Bucket
        S3Prefix
        Author
        LogoUrl
        IconURL
        Url
        Organization
        Categories
        NewsSourceCount
        ArticleCount
        TopicCount
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSBSMNews = /* GraphQL */ `
  query GetSBSMNews($id: ID!, $UserId: String!) {
    getSBSMNews(id: $id, UserId: $UserId) {
      id
      UserId
      SBSMManagerId
      QueryTerm
      InfoType
      Title
      ImageURL
      ActualURL
      URL
      Source
      PubDate
      SBSMStatus
      Penalty
      UpVotes
      DownVotes
      AgeInHours
      Score
      ArticleTextCollected
      ArticleAnalyzed
      ArticleTrendAnalyzed
      TwitterPostCreated
      ArticlePublished
      IncludeInNewsLetter
      Category
      SubCategory
      PeopleMentionedInTheArticle
      TrendPrimary
      TrendImpact
      TrendImpactExplanation
      TrendDemographics
      TrendDuration
      TrendDurationExplanation
      TrendSentiment
      TrendSentimentExplanation
      TrendDrivers
      TrendRisk
      TrendRiskExplanation
      TextString
      Blocked
      ArticleTitle
      ArticleIntro
      ArticleKeywords
      ArticleHtmlBody
      ArticleURL
      ArticleS3Key
      ArticleImageS3Key
      TwitterPost
      TwitterLastPostDate
      TwitterPostCount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSBSMNews = /* GraphQL */ `
  query ListSBSMNews(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelSBSMNewsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSBSMNews(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        SBSMManagerId
        QueryTerm
        InfoType
        Title
        ImageURL
        ActualURL
        URL
        Source
        PubDate
        SBSMStatus
        Penalty
        UpVotes
        DownVotes
        AgeInHours
        Score
        ArticleTextCollected
        ArticleAnalyzed
        ArticleTrendAnalyzed
        TwitterPostCreated
        ArticlePublished
        IncludeInNewsLetter
        Category
        SubCategory
        PeopleMentionedInTheArticle
        TrendPrimary
        TrendImpact
        TrendImpactExplanation
        TrendDemographics
        TrendDuration
        TrendDurationExplanation
        TrendSentiment
        TrendSentimentExplanation
        TrendDrivers
        TrendRisk
        TrendRiskExplanation
        TextString
        Blocked
        ArticleTitle
        ArticleIntro
        ArticleKeywords
        ArticleHtmlBody
        ArticleURL
        ArticleS3Key
        ArticleImageS3Key
        TwitterPost
        TwitterLastPostDate
        TwitterPostCount
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSBSMNewsRSS = /* GraphQL */ `
  query GetSBSMNewsRSS($id: ID!, $UserId: String!) {
    getSBSMNewsRSS(id: $id, UserId: $UserId) {
      id
      UserId
      RSSLink
      InfoType
      URL
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSBSMNewsRSSES = /* GraphQL */ `
  query ListSBSMNewsRSSES(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelSBSMNewsRSSFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSBSMNewsRSSES(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        RSSLink
        InfoType
        URL
        Status
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubReddits = /* GraphQL */ `
  query GetSubReddits($id: ID!, $UserId: String!) {
    getSubReddits(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      SubReddit
      SubRedditId
      FlareRequired
      SubRedditStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSubReddits = /* GraphQL */ `
  query ListSubReddits(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelSubRedditsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSubReddits(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        SubReddit
        SubRedditId
        FlareRequired
        SubRedditStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAudienceGeoInfo = /* GraphQL */ `
  query GetAudienceGeoInfo($id: ID!, $IPv4: String!) {
    getAudienceGeoInfo(id: $id, IPv4: $IPv4) {
      id
      UserId
      Page
      IPv4
      city
      country_code
      country_name
      latitude
      longitude
      postal
      state
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listAudienceGeoInfos = /* GraphQL */ `
  query ListAudienceGeoInfos(
    $id: ID
    $IPv4: ModelStringKeyConditionInput
    $filter: ModelAudienceGeoInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAudienceGeoInfos(
      id: $id
      IPv4: $IPv4
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        Page
        IPv4
        city
        country_code
        country_name
        latitude
        longitude
        postal
        state
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostAlerts = /* GraphQL */ `
  query GetPostAlerts($id: ID!, $UserId: String!) {
    getPostAlerts(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      PostAlertStatus
      AutoRespond
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPostAlerts = /* GraphQL */ `
  query ListPostAlerts(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelPostAlertsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPostAlerts(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        PostAlertStatus
        AutoRespond
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostAlertsNotifications = /* GraphQL */ `
  query GetPostAlertsNotifications($id: ID!, $UserId: String!) {
    getPostAlertsNotifications(id: $id, UserId: $UserId) {
      id
      UserId
      QueryTerm
      SubReddit
      PostTitle
      PostURL
      PostId
      PostAlertId
      Responded
      PostAlertNotificationStatus
      AutoResponse
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPostAlertsNotifications = /* GraphQL */ `
  query ListPostAlertsNotifications(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelPostAlertsNotificationsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPostAlertsNotifications(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        QueryTerm
        SubReddit
        PostTitle
        PostURL
        PostId
        PostAlertId
        Responded
        PostAlertNotificationStatus
        AutoResponse
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPostSchedule = /* GraphQL */ `
  query GetPostSchedule($id: ID!, $UserId: String!) {
    getPostSchedule(id: $id, UserId: $UserId) {
      id
      UserId
      SubReddit
      PostTitle
      PostComment
      PostPhotoURL
      PostURL
      PostId
      PostDateTime
      PostScheduleStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPostSchedules = /* GraphQL */ `
  query ListPostSchedules(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelPostScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPostSchedules(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        SubReddit
        PostTitle
        PostComment
        PostPhotoURL
        PostURL
        PostId
        PostDateTime
        PostScheduleStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPhotoGeneratorModels = /* GraphQL */ `
  query GetPhotoGeneratorModels($id: ID!, $UserId: String!) {
    getPhotoGeneratorModels(id: $id, UserId: $UserId) {
      id
      UserId
      ModelName
      PhotoGeneratorModelStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPhotoGeneratorModels = /* GraphQL */ `
  query ListPhotoGeneratorModels(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelPhotoGeneratorModelsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPhotoGeneratorModels(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        ModelName
        PhotoGeneratorModelStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getModelTrainingPhotos = /* GraphQL */ `
  query GetModelTrainingPhotos($id: ID!, $UserId: String!) {
    getModelTrainingPhotos(id: $id, UserId: $UserId) {
      id
      UserId
      ModelName
      TrainingPhotoURL
      ModelTrainingPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listModelTrainingPhotos = /* GraphQL */ `
  query ListModelTrainingPhotos(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelModelTrainingPhotosFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listModelTrainingPhotos(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        ModelName
        TrainingPhotoURL
        ModelTrainingPhotoStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOutPutPhotos = /* GraphQL */ `
  query GetOutPutPhotos($id: ID!, $UserId: String!) {
    getOutPutPhotos(id: $id, UserId: $UserId) {
      id
      UserId
      ModelName
      PhotoURL
      OutPutPhotoStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listOutPutPhotos = /* GraphQL */ `
  query ListOutPutPhotos(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelOutPutPhotosFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOutPutPhotos(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        ModelName
        PhotoURL
        OutPutPhotoStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOnlyFansProspectingProfiles = /* GraphQL */ `
  query GetOnlyFansProspectingProfiles($id: ID!, $UserId: String!) {
    getOnlyFansProspectingProfiles(id: $id, UserId: $UserId) {
      id
      UserId
      OnlyFansProfileURL
      OnlyFansProfilePictureURL
      OnlyFansPictureStatus
      OnlyFansChatURL
      OnlyFansId
      OnlyFansName
      OFSP_Photos
      OFSP_Videos
      OFSP_Likes
      OFSP_Streams
      OFSP_Fans
      PaidFree
      Description
      Location
      Subscribed
      AdSent
      Responded
      RespondedDate
      Demo
      DemoDate
      CollectionAccount
      RhyteItCustomer
      RhyteItCustomerDate
      RhyteItDAOMember
      RhyteItDAOMemberDate
      RhyteItUserId
      LinkTree
      Twitter
      Telegram
      Fansly
      Instagram
      Reddit
      Email
      Beacons
      TikTok
      AllMyLinks
      UpdateCounter
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listOnlyFansProspectingProfiles = /* GraphQL */ `
  query ListOnlyFansProspectingProfiles(
    $id: ID
    $UserId: ModelStringKeyConditionInput
    $filter: ModelOnlyFansProspectingProfilesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOnlyFansProspectingProfiles(
      id: $id
      UserId: $UserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        OnlyFansProfileURL
        OnlyFansProfilePictureURL
        OnlyFansPictureStatus
        OnlyFansChatURL
        OnlyFansId
        OnlyFansName
        OFSP_Photos
        OFSP_Videos
        OFSP_Likes
        OFSP_Streams
        OFSP_Fans
        PaidFree
        Description
        Location
        Subscribed
        AdSent
        Responded
        RespondedDate
        Demo
        DemoDate
        CollectionAccount
        RhyteItCustomer
        RhyteItCustomerDate
        RhyteItDAOMember
        RhyteItDAOMemberDate
        RhyteItUserId
        LinkTree
        Twitter
        Telegram
        Fansly
        Instagram
        Reddit
        Email
        Beacons
        TikTok
        AllMyLinks
        UpdateCounter
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSocialMediaAds = /* GraphQL */ `
  query GetSocialMediaAds($id: ID!) {
    getSocialMediaAds(id: $id) {
      id
      SMAdType
      SMPlatform
      SMId
      SBSMPlatform
      SBSMId
      AdFile
      SMPostURL
      RIAccount
      RISMAccount
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSocialMediaAds = /* GraphQL */ `
  query ListSocialMediaAds(
    $filter: ModelSocialMediaAdsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialMediaAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        SMAdType
        SMPlatform
        SMId
        SBSMPlatform
        SBSMId
        AdFile
        SMPostURL
        RIAccount
        RISMAccount
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesPipelineMetrics = /* GraphQL */ `
  query GetSalesPipelineMetrics($id: ID!, $ReportDate: AWSDate!) {
    getSalesPipelineMetrics(id: $id, ReportDate: $ReportDate) {
      id
      UserId
      ReportDate
      SubscribedCount
      AdvertsSent
      RespondedCount
      DemoCount
      CustomerCount
      DAOMemberCount
      RhyteItAccounts
      RhyteItChromeProfiles
      RhyteItEmailAccounts
      RhyteItTwitterAccounts
      RhyteItInstagramAccounts
      RhyteItOnlyFansAccounts
      RhyteItMediumAccounts
      RhyteItRedditAccounts
      OFAccounts
      OFLinkTreeAccounts
      OFEmailAccounts
      OFTwitterAccounts
      OFTelegramAccounts
      OFInstagramAccounts
      OFFanslyAccounts
      OFRedditAccounts
      RhyteItPageSiteVisits
      ReportData
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSalesPipelineMetrics = /* GraphQL */ `
  query ListSalesPipelineMetrics(
    $id: ID
    $ReportDate: ModelStringKeyConditionInput
    $filter: ModelSalesPipelineMetricsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSalesPipelineMetrics(
      id: $id
      ReportDate: $ReportDate
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        ReportDate
        SubscribedCount
        AdvertsSent
        RespondedCount
        DemoCount
        CustomerCount
        DAOMemberCount
        RhyteItAccounts
        RhyteItChromeProfiles
        RhyteItEmailAccounts
        RhyteItTwitterAccounts
        RhyteItInstagramAccounts
        RhyteItOnlyFansAccounts
        RhyteItMediumAccounts
        RhyteItRedditAccounts
        OFAccounts
        OFLinkTreeAccounts
        OFEmailAccounts
        OFTwitterAccounts
        OFTelegramAccounts
        OFInstagramAccounts
        OFFanslyAccounts
        OFRedditAccounts
        RhyteItPageSiteVisits
        ReportData
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSBSMRedditors = /* GraphQL */ `
  query GetSBSMRedditors($id: ID!) {
    getSBSMRedditors(id: $id) {
      id
      UserId
      redditor
      OnlyFansName
      FanslyName
      SheerName
      comment_karma
      link_karma
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSBSMRedditors = /* GraphQL */ `
  query ListSBSMRedditors(
    $filter: ModelSBSMRedditorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSBSMRedditors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserId
        redditor
        OnlyFansName
        FanslyName
        SheerName
        comment_karma
        link_karma
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSBSMSubReddits = /* GraphQL */ `
  query GetSBSMSubReddits($id: ID!) {
    getSBSMSubReddits(id: $id) {
      id
      UserId
      subreddit
      over18
      Description
      subscribers
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSBSMSubReddits = /* GraphQL */ `
  query ListSBSMSubReddits(
    $filter: ModelSBSMSubRedditsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSBSMSubReddits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserId
        subreddit
        over18
        Description
        subscribers
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSBSMRedditorSubreddits = /* GraphQL */ `
  query GetSBSMRedditorSubreddits(
    $id: ID!
    $redditor: String!
    $subreddit: String!
  ) {
    getSBSMRedditorSubreddits(
      id: $id
      redditor: $redditor
      subreddit: $subreddit
    ) {
      id
      UserId
      redditor
      subreddit
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSBSMRedditorSubreddits = /* GraphQL */ `
  query ListSBSMRedditorSubreddits(
    $id: ID
    $redditorSubreddit: ModelSBSMRedditorSubredditsPrimaryCompositeKeyConditionInput
    $filter: ModelSBSMRedditorSubredditsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSBSMRedditorSubreddits(
      id: $id
      redditorSubreddit: $redditorSubreddit
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        redditor
        subreddit
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProducts = /* GraphQL */ `
  query GetProducts($id: ID!) {
    getProducts(id: $id) {
      id
      Product
      Description
      PaymentMode
      ProductURLParam
      PriceId
      Price
      SuccessURL
      CancelURL
      ProductImage
      Environment
      ProductType
      DeliveryMethod
      FileURL
      SocialMedia
      Likes
      Reposts
      Follow
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Product
        Description
        PaymentMode
        ProductURLParam
        PriceId
        Price
        SuccessURL
        CancelURL
        ProductImage
        Environment
        ProductType
        DeliveryMethod
        FileURL
        SocialMedia
        Likes
        Reposts
        Follow
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrivateKeys = /* GraphQL */ `
  query GetPrivateKeys($id: ID!, $Component: String!, $Key: String!) {
    getPrivateKeys(id: $id, Component: $Component, Key: $Key) {
      id
      UserId
      Component
      Key
      Value
      URL
      Environment
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPrivateKeys = /* GraphQL */ `
  query ListPrivateKeys(
    $id: ID
    $componentKey: ModelPrivateKeysPrimaryCompositeKeyConditionInput
    $filter: ModelPrivateKeysFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPrivateKeys(
      id: $id
      componentKey: $componentKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        UserId
        Component
        Key
        Value
        URL
        Environment
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPurchase = /* GraphQL */ `
  query GetPurchase($id: ID!) {
    getPurchase(id: $id) {
      id
      StripePaymentIntent
      StripePaymentMethod
      StripeCustomerId
      StripeCustomerEmail
      StripeCustomerName
      StripeCheckOutSessionId
      StripeInvoiceId
      StripeSubscriptionId
      StripeSubscriptionItemId
      StripeProductId
      StripePriceId
      StripeStatus
      RequestId
      idempotency_key
      ReceiptURL
      Last4
      PostalCode
      Product
      Environment
      EmailVerified
      PurchaseStatus
      FileURL
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPurchases = /* GraphQL */ `
  query ListPurchases(
    $filter: ModelPurchaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPurchases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        StripePaymentIntent
        StripePaymentMethod
        StripeCustomerId
        StripeCustomerEmail
        StripeCustomerName
        StripeCheckOutSessionId
        StripeInvoiceId
        StripeSubscriptionId
        StripeSubscriptionItemId
        StripeProductId
        StripePriceId
        StripeStatus
        RequestId
        idempotency_key
        ReceiptURL
        Last4
        PostalCode
        Product
        Environment
        EmailVerified
        PurchaseStatus
        FileURL
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSocialMediaEngagementPurchases = /* GraphQL */ `
  query GetSocialMediaEngagementPurchases($id: ID!) {
    getSocialMediaEngagementPurchases(id: $id) {
      id
      Email
      StripePaymentIntent
      SocialMedia
      SocialMediaAccount
      LikesPurchased
      LikesAchieved
      Random
      PostURL
      Environment
      PurchaseStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSocialMediaEngagementPurchases = /* GraphQL */ `
  query ListSocialMediaEngagementPurchases(
    $filter: ModelSocialMediaEngagementPurchasesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocialMediaEngagementPurchases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Email
        StripePaymentIntent
        SocialMedia
        SocialMediaAccount
        LikesPurchased
        LikesAchieved
        Random
        PostURL
        Environment
        PurchaseStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCustomerComments = /* GraphQL */ `
  query GetCustomerComments($id: ID!) {
    getCustomerComments(id: $id) {
      id
      UserEmail
      IpAddress
      Rating
      CommentType
      Comment
      EmailTemplate
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listCustomerComments = /* GraphQL */ `
  query ListCustomerComments(
    $filter: ModelCustomerCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        UserEmail
        IpAddress
        Rating
        CommentType
        Comment
        EmailTemplate
        Status
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductReviews = /* GraphQL */ `
  query GetProductReviews($id: ID!) {
    getProductReviews(id: $id) {
      id
      Product
      Review
      Rating
      Status
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listProductReviews = /* GraphQL */ `
  query ListProductReviews(
    $filter: ModelProductReviewsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Product
        Review
        Rating
        Status
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADAccountGroups = /* GraphQL */ `
  query GetSMMADAccountGroups($id: ID!) {
    getSMMADAccountGroups(id: $id) {
      id
      AccountGroup
      AccountType
      SyncAccounts
      ParentAccount
      TwitterPostedCount
      TwitterWeight
      TwitterZScore
      TwitterLastPostDate
      SMMADAccountGroupStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADAccountGroups = /* GraphQL */ `
  query ListSMMADAccountGroups(
    $filter: ModelSMMADAccountGroupsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADAccountGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AccountGroup
        AccountType
        SyncAccounts
        ParentAccount
        TwitterPostedCount
        TwitterWeight
        TwitterZScore
        TwitterLastPostDate
        SMMADAccountGroupStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADContent = /* GraphQL */ `
  query GetSMMADContent($id: ID!) {
    getSMMADContent(id: $id) {
      id
      Account
      AccountType
      MasterAccount
      AccountGroup
      Campaign
      Category
      ContentType
      Text
      URL
      S3Bucket
      S3Key
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADContentStatus
      ExpirationDate
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADContents = /* GraphQL */ `
  query ListSMMADContents(
    $filter: ModelSMMADContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Account
        AccountType
        MasterAccount
        AccountGroup
        Campaign
        Category
        ContentType
        Text
        URL
        S3Bucket
        S3Key
        PostedCount
        Weight
        ZScore
        LastPostDate
        SMMADContentStatus
        ExpirationDate
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADText = /* GraphQL */ `
  query GetSMMADText($id: ID!) {
    getSMMADText(id: $id) {
      id
      Account
      AccountType
      MasterAccount
      AccountGroup
      ContentId
      Campaign
      Category
      Text
      PostedCount
      Weight
      ZScore
      LastPostDate
      SMMADTextStatus
      ExpirationDate
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADTexts = /* GraphQL */ `
  query ListSMMADTexts(
    $filter: ModelSMMADTextFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADTexts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Account
        AccountType
        MasterAccount
        AccountGroup
        ContentId
        Campaign
        Category
        Text
        PostedCount
        Weight
        ZScore
        LastPostDate
        SMMADTextStatus
        ExpirationDate
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADContentMetrics = /* GraphQL */ `
  query GetSMMADContentMetrics($id: ID!) {
    getSMMADContentMetrics(id: $id) {
      id
      Account
      AccountType
      MasterAccount
      AccountGroup
      Campaign
      Category
      SMMADTextId
      SMMADContentId
      PostDateTime
      PostDay
      PostHour24
      Platform
      SMMADContentMetricsStatus
      SubscribersGrowth
      SubscribersTotal
      PostURL
      PostLikes
      PostComments
      PostViews
      Reposts
      Weight
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADContentMetrics = /* GraphQL */ `
  query ListSMMADContentMetrics(
    $filter: ModelSMMADContentMetricsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADContentMetrics(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Account
        AccountType
        MasterAccount
        AccountGroup
        Campaign
        Category
        SMMADTextId
        SMMADContentId
        PostDateTime
        PostDay
        PostHour24
        Platform
        SMMADContentMetricsStatus
        SubscribersGrowth
        SubscribersTotal
        PostURL
        PostLikes
        PostComments
        PostViews
        Reposts
        Weight
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADAccounts = /* GraphQL */ `
  query GetSMMADAccounts($id: ID!) {
    getSMMADAccounts(id: $id) {
      id
      Account
      AccountType
      MasterAccount
      AccountGroup
      SMMADAccountStatus
      CampaignsActive
      PostedCount
      TwitterLastPostDate
      TwitterPostingFrequencyHours
      Weight
      ZScore
      PurchaseId
      ProductId
      TwitterAccount
      TwitterPassword
      TwitterConsumerKey
      TwitterConsumerSecret
      TwitterBearerToken
      TwitterAccessToken
      TwitterAccessTokenSecret
      TwitterClientId
      TwitterClientSecret
      TwitterAppName
      TwitterAppId
      TwitterStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADAccounts = /* GraphQL */ `
  query ListSMMADAccounts(
    $filter: ModelSMMADAccountsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Account
        AccountType
        MasterAccount
        AccountGroup
        SMMADAccountStatus
        CampaignsActive
        PostedCount
        TwitterLastPostDate
        TwitterPostingFrequencyHours
        Weight
        ZScore
        PurchaseId
        ProductId
        TwitterAccount
        TwitterPassword
        TwitterConsumerKey
        TwitterConsumerSecret
        TwitterBearerToken
        TwitterAccessToken
        TwitterAccessTokenSecret
        TwitterClientId
        TwitterClientSecret
        TwitterAppName
        TwitterAppId
        TwitterStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADAccountStats = /* GraphQL */ `
  query GetSMMADAccountStats($id: ID!) {
    getSMMADAccountStats(id: $id) {
      id
      Account
      AccountType
      MasterAccount
      AccountGroup
      StatDate
      TwitterFollowing
      TwitterFollowingIncr
      TwitterFollowingPer
      TwitterFollowers
      TwitterFollowersIncr
      TwitterFollowersPer
      TwitterTweets
      TwitterTweetsIncr
      TwitterTweetsPer
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADAccountStats = /* GraphQL */ `
  query ListSMMADAccountStats(
    $filter: ModelSMMADAccountStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADAccountStats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Account
        AccountType
        MasterAccount
        AccountGroup
        StatDate
        TwitterFollowing
        TwitterFollowingIncr
        TwitterFollowingPer
        TwitterFollowers
        TwitterFollowersIncr
        TwitterFollowersPer
        TwitterTweets
        TwitterTweetsIncr
        TwitterTweetsPer
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADCampaigns = /* GraphQL */ `
  query GetSMMADCampaigns($id: ID!) {
    getSMMADCampaigns(id: $id) {
      id
      Account
      AccountType
      MasterAccount
      AccountGroup
      Campaign
      Description
      SMMADCampaignStatus
      TwitterPostingFrequencyHours
      ContentTextActive
      PostCountBeforeExpiry
      PostedCount
      Weight
      ZScore
      LastPostDate
      ExpirationDate
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADCampaigns = /* GraphQL */ `
  query ListSMMADCampaigns(
    $filter: ModelSMMADCampaignsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Account
        AccountType
        MasterAccount
        AccountGroup
        Campaign
        Description
        SMMADCampaignStatus
        TwitterPostingFrequencyHours
        ContentTextActive
        PostCountBeforeExpiry
        PostedCount
        Weight
        ZScore
        LastPostDate
        ExpirationDate
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADHashTags = /* GraphQL */ `
  query GetSMMADHashTags($id: ID!) {
    getSMMADHashTags(id: $id) {
      id
      Account
      AccountType
      MasterAccount
      AccountGroup
      Campaign
      HashTag
      PostedCount
      Weight
      ZScore
      SMMADHashTagStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADHashTags = /* GraphQL */ `
  query ListSMMADHashTags(
    $filter: ModelSMMADHashTagsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADHashTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Account
        AccountType
        MasterAccount
        AccountGroup
        Campaign
        HashTag
        PostedCount
        Weight
        ZScore
        SMMADHashTagStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSMMADPlatforms = /* GraphQL */ `
  query GetSMMADPlatforms($id: ID!) {
    getSMMADPlatforms(id: $id) {
      id
      Platform
      PlatformLogoURL
      SMMADPlatformsStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listSMMADPlatforms = /* GraphQL */ `
  query ListSMMADPlatforms(
    $filter: ModelSMMADPlatformsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSMMADPlatforms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Platform
        PlatformLogoURL
        SMMADPlatformsStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBatchJobs = /* GraphQL */ `
  query GetBatchJobs($id: ID!) {
    getBatchJobs(id: $id) {
      id
      BatchJobName
      Account
      Service
      JobJSON
      BatchJobStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listBatchJobs = /* GraphQL */ `
  query ListBatchJobs(
    $filter: ModelBatchJobsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBatchJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BatchJobName
        Account
        Service
        JobJSON
        BatchJobStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!) {
    getBlog(id: $id) {
      id
      AccountGroup
      Account
      Blog
      Description
      BlogEmail
      RedditClientId
      RedditClientSecret
      RedditUserName
      RedditPassword
      RedditUserAgent
      OpenAIapiKey
      OpenAIOrganization
      OpenAIProject
      OpenAIModel
      S3Bucket
      S3Key
      BlogURL
      ReadCount
      Categories
      PixelTrackerAPIURL
      Active
      BlogStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AccountGroup
        Account
        Blog
        Description
        BlogEmail
        RedditClientId
        RedditClientSecret
        RedditUserName
        RedditPassword
        RedditUserAgent
        OpenAIapiKey
        OpenAIOrganization
        OpenAIProject
        OpenAIModel
        S3Bucket
        S3Key
        BlogURL
        ReadCount
        Categories
        PixelTrackerAPIURL
        Active
        BlogStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlogPrompts = /* GraphQL */ `
  query GetBlogPrompts($id: ID!) {
    getBlogPrompts(id: $id) {
      id
      AccountGroup
      Account
      Blog
      PromptName
      SubRedditList
      Prompt
      PromptPicture
      PromptTrendAnalysis
      PromptTwitter
      AWSBedrockModelIdImage
      HtmlHeadSection
      HtmlStyleSection
      HtmlHeaderSection
      HtmlAuthorInfoSection
      HtmlFooterSection
      HtmlGooglePublishingSection
      LogoUrl
      IconURL
      PromptJSON
      BlogPromptStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listBlogPrompts = /* GraphQL */ `
  query ListBlogPrompts(
    $filter: ModelBlogPromptsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPrompts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AccountGroup
        Account
        Blog
        PromptName
        SubRedditList
        Prompt
        PromptPicture
        PromptTrendAnalysis
        PromptTwitter
        AWSBedrockModelIdImage
        HtmlHeadSection
        HtmlStyleSection
        HtmlHeaderSection
        HtmlAuthorInfoSection
        HtmlFooterSection
        HtmlGooglePublishingSection
        LogoUrl
        IconURL
        PromptJSON
        BlogPromptStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlogPosts = /* GraphQL */ `
  query GetBlogPosts($id: ID!) {
    getBlogPosts(id: $id) {
      id
      AccountGroup
      Account
      Blog
      BlogPromptId
      Title
      Intro
      PictureURL
      BlogPostHTML
      BlogPostString
      BlogHTMLBody
      S3Bucket
      S3Key
      ImageS3Key
      PicturePrompt
      PostURL
      TwitterPost
      TwitterLastPostDate
      TwitterPostCount
      TrendAnalyzed
      TwitterPostCreated
      PostPublished
      IncludeInNewsLetter
      PictureCreated
      Category
      SubCategory
      KeyWords
      TrendPrimary
      TrendImpact
      TrendImpactExplanation
      TrendDemographics
      TrendDuration
      TrendDurationExplanation
      TrendSentiment
      TrendSentimentExplanation
      TrendDrivers
      TrendRisk
      TrendRiskExplanation
      ReadCount
      Likes
      Unlikes
      PostCommentsJSON
      Reddit
      RedditSubmissionId
      Score
      Comments
      CompositeScore
      BlogPostStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listBlogPosts = /* GraphQL */ `
  query ListBlogPosts(
    $filter: ModelBlogPostsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AccountGroup
        Account
        Blog
        BlogPromptId
        Title
        Intro
        PictureURL
        BlogPostHTML
        BlogPostString
        BlogHTMLBody
        S3Bucket
        S3Key
        ImageS3Key
        PicturePrompt
        PostURL
        TwitterPost
        TwitterLastPostDate
        TwitterPostCount
        TrendAnalyzed
        TwitterPostCreated
        PostPublished
        IncludeInNewsLetter
        PictureCreated
        Category
        SubCategory
        KeyWords
        TrendPrimary
        TrendImpact
        TrendImpactExplanation
        TrendDemographics
        TrendDuration
        TrendDurationExplanation
        TrendSentiment
        TrendSentimentExplanation
        TrendDrivers
        TrendRisk
        TrendRiskExplanation
        ReadCount
        Likes
        Unlikes
        PostCommentsJSON
        Reddit
        RedditSubmissionId
        Score
        Comments
        CompositeScore
        BlogPostStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRhyteItLog = /* GraphQL */ `
  query GetRhyteItLog($id: ID!) {
    getRhyteItLog(id: $id) {
      id
      Environment
      ipaddress
      User
      Page
      Function
      ErrorMessage
      ErrorJSON
      ErrorLine
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listRhyteItLogs = /* GraphQL */ `
  query ListRhyteItLogs(
    $filter: ModelRhyteItLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRhyteItLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Environment
        ipaddress
        User
        Page
        Function
        ErrorMessage
        ErrorJSON
        ErrorLine
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPodcast = /* GraphQL */ `
  query GetPodcast($id: ID!) {
    getPodcast(id: $id) {
      id
      User
      AccountGroup
      Title
      Description
      Author
      Rating
      Frequency
      Location
      Category
      ImageURL
      PodcastURL
      RSSURL
      KeyWords
      Copyright
      Language
      Explicit
      PodCastRSS
      S3Bucket
      S3Key
      CloudFrontDistribution
      PodcastDistribution
      PodcastStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPodcasts = /* GraphQL */ `
  query ListPodcasts(
    $filter: ModelPodcastFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcasts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        User
        AccountGroup
        Title
        Description
        Author
        Rating
        Frequency
        Location
        Category
        ImageURL
        PodcastURL
        RSSURL
        KeyWords
        Copyright
        Language
        Explicit
        PodCastRSS
        S3Bucket
        S3Key
        CloudFrontDistribution
        PodcastDistribution
        PodcastStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPodcastEpisode = /* GraphQL */ `
  query GetPodcastEpisode($id: ID!) {
    getPodcastEpisode(id: $id) {
      id
      AccountGroup
      User
      Author
      Title
      Episode
      Description
      ImageURL
      Type
      Length
      Duration
      Guid
      Explicit
      S3Bucket
      S3Key
      ListenCount
      CloudFrontDistribution
      PodcastDistribution
      PodcastEpisodeStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listPodcastEpisodes = /* GraphQL */ `
  query ListPodcastEpisodes(
    $filter: ModelPodcastEpisodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPodcastEpisodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        AccountGroup
        User
        Author
        Title
        Episode
        Description
        ImageURL
        Type
        Length
        Duration
        Guid
        Explicit
        S3Bucket
        S3Key
        ListenCount
        CloudFrontDistribution
        PodcastDistribution
        PodcastEpisodeStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsletter = /* GraphQL */ `
  query GetNewsletter($id: ID!) {
    getNewsletter(id: $id) {
      id
      Newsletter
      Description
      AccountGroup
      User
      TemplateJSON
      TemplateHTML
      NewsletterDate
      Edition
      Content
      S3Bucket
      S3Key
      SendCount
      OpenCount
      ClickCount
      NewsletterStatus
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listNewsletters = /* GraphQL */ `
  query ListNewsletters(
    $filter: ModelNewsletterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Newsletter
        Description
        AccountGroup
        User
        TemplateJSON
        TemplateHTML
        NewsletterDate
        Edition
        Content
        S3Bucket
        S3Key
        SendCount
        OpenCount
        ClickCount
        NewsletterStatus
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsletterSubscribers = /* GraphQL */ `
  query GetNewsletterSubscribers($id: ID!) {
    getNewsletterSubscribers(id: $id) {
      id
      Newsletter
      AccountGroup
      SubscriberEmail
      SubscriptionStatus
      SubscribedDate
      SubscriberSegment
      LastEmailSentDate
      LastEmailOpenedDate
      SendCount
      OpenCount
      ClickCount
      SubscriptionSource
      UnsubscribeReason
      Geolocation
      SubscriptionPreferences
      BounceReason
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listNewsletterSubscribers = /* GraphQL */ `
  query ListNewsletterSubscribers(
    $filter: ModelNewsletterSubscribersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterSubscribers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Newsletter
        AccountGroup
        SubscriberEmail
        SubscriptionStatus
        SubscribedDate
        SubscriberSegment
        LastEmailSentDate
        LastEmailOpenedDate
        SendCount
        OpenCount
        ClickCount
        SubscriptionSource
        UnsubscribeReason
        Geolocation
        SubscriptionPreferences
        BounceReason
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsletterEdition = /* GraphQL */ `
  query GetNewsletterEdition($id: ID!) {
    getNewsletterEdition(id: $id) {
      id
      AccountGroup
      CampaignName
      Newsletter
      User
      NewsletterDate
      Edition
      ContentHTML
      S3HtmlLocation
      S3Bucket
      S3Key
      ContentJSON
      NewsletterEditionStatus
      SendCount
      OpenCount
      ClickCount
      Active
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listNewsletterEditions = /* GraphQL */ `
  query ListNewsletterEditions(
    $filter: ModelNewsletterEditionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterEditions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AccountGroup
        CampaignName
        Newsletter
        User
        NewsletterDate
        Edition
        ContentHTML
        S3HtmlLocation
        S3Bucket
        S3Key
        ContentJSON
        NewsletterEditionStatus
        SendCount
        OpenCount
        ClickCount
        Active
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNewsletterEditionTracking = /* GraphQL */ `
  query GetNewsletterEditionTracking($id: ID!) {
    getNewsletterEditionTracking(id: $id) {
      id
      AccountGroup
      CampaignName
      Newsletter
      Edition
      SESMessageId
      EmailHTML
      S3HtmlLocation
      S3Bucket
      S3Key
      RecipientEmail
      SendDateTime
      FirstOpenDateTime
      OpenDateTime
      LastClickDateTime
      UnsubscribeDateTime
      SendCount
      OpenCount
      ClickCount
      DeliveryStatus
      BounceReason
      UnsubscribeStatus
      AudienceSegment
      DeviceType
      UserAgent
      IPAddress
      City
      Country
      ContentVersion
      NewsletterTrackingStatus
      CreationName
      UpdateName
      CreationDateTime
      UpdateDateTime
      __typename
    }
  }
`;
export const listNewsletterEditionTrackings = /* GraphQL */ `
  query ListNewsletterEditionTrackings(
    $filter: ModelNewsletterEditionTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewsletterEditionTrackings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        AccountGroup
        CampaignName
        Newsletter
        Edition
        SESMessageId
        EmailHTML
        S3HtmlLocation
        S3Bucket
        S3Key
        RecipientEmail
        SendDateTime
        FirstOpenDateTime
        OpenDateTime
        LastClickDateTime
        UnsubscribeDateTime
        SendCount
        OpenCount
        ClickCount
        DeliveryStatus
        BounceReason
        UnsubscribeStatus
        AudienceSegment
        DeviceType
        UserAgent
        IPAddress
        City
        Country
        ContentVersion
        NewsletterTrackingStatus
        CreationName
        UpdateName
        CreationDateTime
        UpdateDateTime
        __typename
      }
      nextToken
      __typename
    }
  }
`;
