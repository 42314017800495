import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useRef } from "react";

import {
  listBlogs,
  listSMMADAccounts,
  listSMMADAccountGroups,
} from "../../../graphql/queries";
import {
  createBlog,updateBlog
} from "../../../graphql/mutations";
import { generateClient } from "aws-amplify/api";

import Header from "../../RhyteItComponents/Header/Header";
import {
  UserContext,
  CognitoGroupContext,
} from "../../../BusinessLogic/Hooks/UserContext";
import {
  GetGroupRoleAccess,
  RhyteItLogging,
  ReturnUserToLandingPage,
} from "../../../BusinessLogic/UserManagement";
import { fetchAuthSession } from "aws-amplify/auth";
import { motion as m } from "framer-motion";
import MenuBackground from "../../Menu/MenuBackground";
import { get_uuidv4 } from "../../../BusinessLogic/uuidv4";
import { useNavigate } from "react-router-dom";


import BlogAIPromptTable from '../BlogAIPrompt/BlogAIPromptTable';
import BlogMetaData from '../BlogMetaData/BlogMetaData';
import BlogPosts from '../BlogPostManager/BlogPosts';

import {
  Button,
  Fieldset,
  SelectField,
  Flex,
  Text,
  TextField,
  Card,
  Badge,
  Image,
  Heading,
  Link,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  TextAreaField
} from "@aws-amplify/ui-react";
import styles from "./BlogManager.module.css";

const BlogManager = (props) => {
  BlogManager.propTypes = {
    RedditUser: PropTypes.object,
  };

  const client = generateClient();
  const [vBlogManager, setvBlogManager] = useState([]);
  const navigate = useNavigate();
  

  const vBreadCrumbItems = [
    { Path: "/", Title: "Home", isCurrent: false },
    { Path: "/MainMenuV2", Title: "Menu", isCurrent: false },
    { Path: "/BlogManager", Title: "BlogManager", isCurrent: true },
  ];

  var ErrorMessageStr = "";
  const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr);

  const { Users, setUsers } = useContext(UserContext);
  const { CognitoGroup, setCognitoGroup } = useContext(CognitoGroupContext);

  const [updatedKey, setupdatedKey] = useState();
  const [vViewPostingConfig, setvViewPostingConfig] = useState(false);
  
  const [vAccountGroup, setvAccountGroup] = useState();
  
  const [vAccountGroupSelected, setvAccountGroupSelected] = useState({"AccountGroup":"None"});
  let [vAccountGroupList, setvAccountGroupList] = useState();

  const [vBlogSelected, setvBlogSelected] = useState({"Blog":"None"});
  let [vBlogList, setvBlogList] = useState();  
  const [vAddNewletterVisible, setvAddNewletterVisible] = useState(false);

  const [vBlogVisible, setvBlogVisible] = useState(false);
  
  let [vNewBlog, setvNewBlog] = useState();
  let [vNewBlogDescription, setvNewBlogDescription] = useState();

  let [vViewBlogMetaDataSection, setvViewBlogMetaDataSection] = useState(false);
  let [vBlogPromptsVisible, setvBlogPromptsVisible] = useState(false);
  let [vBlogPostsVisible, setvBlogPostsVisible] = useState(false);


  let [vBlogTitle, setvBlogTitle] = useState();
  let [vBlogDescription, setvBlogDescription] = useState();
  let [vBlogEmail, setvBlogEmail] = useState();
  let [vRedditClientId, setvRedditClientId] = useState();
  let [vRedditClientSecret, setvRedditClientSecret] = useState();
  let [vRedditUserName, setvRedditUserName] = useState();
  let [vRedditPassword, setvRedditPassword] = useState();
  let [vRedditUserAgent, setvRedditUserAgent] = useState();

  let [vOpenAIapiKey, setvOpenAIapiKey] = useState();
  let [vOpenAIOrganization, setvOpenAIOrganization] = useState();
  let [vOpenAIProject, setvOpenAIProject] = useState();
  let [vOpenAIModel, setvOpenAIModel] = useState();
  
  
  let [vS3Bucket, setvS3Bucket] = useState();
  let [vBlogURL, setvBlogURL] = useState();

  let [vPixelTrackerAPIURL, setvPixelTrackerAPIURL] = useState();

  let [vBlogCategories, setvBlogCategories] = useState();

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (CognitoGroup !== undefined) {
          let GetGroupRoleAccessResponse = await GetGroupRoleAccess(
            CognitoGroup,
            "RhyteItSMMADUserGroup"
          );

          if (GetGroupRoleAccessResponse) {
            GetSMMADAccountGroups();
           
          }
        } else {
          const { accessToken, idToken } =
            (await fetchAuthSession()).tokens ?? {};

          if (idToken !== undefined) {
            setUsers(idToken.payload.email);
            setCognitoGroup(accessToken["payload"]["cognito:groups"]);
          } else {
            ReturnUserToLandingPage(Users, CognitoGroup);
          }
        }
      } catch (err) {
        RhyteItLogging(
          Users,
          "BlogManager",
          "useEffect[CognitoGroup]",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    })();
  }, [CognitoGroup]);

  useEffect(() => {

    if (vAccountGroupSelected.AccountGroup !== "None") {
    //console.log("BlogManager.useEffect[vAccountGroupSelected].vAccountGroupSelected:  ",vAccountGroupSelected)
  
     GetBlogs(vAccountGroupSelected.AccountGroup);
    }
  }, [vAccountGroupSelected]);
  
  useEffect(() => {

   //console.log("BlogManager.useEffect[vBlogSelected].vBlogSelected:  ",vBlogSelected)

    if (vBlogSelected["Blog"] !== "None") {
      setvBlogTitle(vBlogSelected["Blog"])

      if ("Description" in vBlogSelected) {
        setvBlogDescription(vBlogSelected["Description"])
      }
      if ("BlogEmail" in vBlogSelected) {
        setvBlogEmail(vBlogSelected["BlogEmail"])
      }
      if ("RedditClientId" in vBlogSelected) {
        setvRedditClientId(vBlogSelected["RedditClientId"])
      }
      if ("RedditClientSecret" in vBlogSelected) {
        setvRedditClientSecret(vBlogSelected["RedditClientSecret"])
      }    
      if ("RedditUserName"  in vBlogSelected) {
        setvRedditUserName(vBlogSelected["RedditUserName"])
      }
      if ("RedditPassword"  in vBlogSelected) {
        setvRedditPassword(vBlogSelected["RedditPassword"])
      }
      if ("RedditUserAgent"  in vBlogSelected) {
        setvRedditUserAgent(vBlogSelected["RedditUserAgent"])
      }
      if ("OpenAIapiKey"  in vBlogSelected) {
        setvOpenAIapiKey(vBlogSelected["OpenAIapiKey"])
      }  
      if ("OpenAIOrganization"  in vBlogSelected) {
        setvOpenAIOrganization(vBlogSelected["OpenAIOrganization"])
      }  
      if ("OpenAIProject"  in vBlogSelected) {
        setvOpenAIProject(vBlogSelected["OpenAIProject"])
      }  
      if ("OpenAIModel"  in vBlogSelected) {
        setvOpenAIModel(vBlogSelected["OpenAIModel"])
      }  
      if ("S3Bucket"  in vBlogSelected) {
        setvS3Bucket(vBlogSelected["S3Bucket"])
      }  
      if ("BlogURL"  in vBlogSelected) {
        setvBlogURL(vBlogSelected["BlogURL"])
      }
      if ("PixelTrackerAPIURL" in vBlogSelected) {
        setvPixelTrackerAPIURL(vBlogSelected["PixelTrackerAPIURL"])
      }  
      
      if ("Categories" in vBlogSelected) {
        setvBlogCategories(vBlogSelected["Categories"])
      }        
       

  }

  }, [vBlogSelected]);

  async function GetBlogs(pAccountGroup) {
    try {
      setErrorMessage("");
      
      //console.log("BlogManager.GetBlogs.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            AccountGroup: {
              eq: pAccountGroup,
            },
          },
        };
      } else {
        variables = {};
      }

      const listBlogsData2 = await client.graphql({
        query: listBlogs,
        variables: variables,
      });

      //console.log("BlogManager.GetBlogs.listBlogs.data.listBlogs.items.length : ", listBlogsData2.data.listBlogs.items.length );

      if (listBlogsData2.data.listBlogs.items.length > 0) {
        const BlogManager1 =
          listBlogsData2.data.listBlogs.items;
          SortBlogByName(BlogManager1)
          setvBlogList(BlogManager1);
      } 



    } catch (err) {
      RhyteItLogging(
        Users,
        "BlogManager",
        "GetBlogs",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function GetSMMADAccountGroups() {
    try {
      setErrorMessage("");
      
      //console.log("BlogManager.GetBlogs.Users: ", Users);
      let variables;
      if (Users !== "edwardhawke@rhyteit.com") {
        variables = {
          filter: {
            ParentAccount: {
              eq: Users,
            },
          },
        };
      } else {
        variables = {};
      }

      const listSMMADAccountGroupsData2 = await client.graphql({
        query: listSMMADAccountGroups,
        variables: variables,
      });

      //console.log("BlogManager.GetSMMADAccountGroups.listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length : ", listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length );

      if (listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items.length > 0) {
        const BlogManager1 =
          listSMMADAccountGroupsData2.data.listSMMADAccountGroups.items;

          setvAccountGroupList(BlogManager1);
      } 



    } catch (err) {
      RhyteItLogging(
        Users,
        "BlogManager",
        "GetSMMADAccountGroups",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function SortBlogByName(pBlog) {
    pBlog.sort(GFG_sortFunction);
    return pBlog;
    //    //console.log(JSON.stringify(pEmails));
  }

  function GFG_sortFunction(a, b) {
    let BlogA = a.Blog;
    let BlogB = b.Blog;
    return BlogA > BlogB ? 1 : -1;
  }

  async function CreateBlog() {
    try {
      //console.log("BlogManagerTable.CreateBlog.vNewBlog:  ",vNewBlog);

      var vUpdateDateTimeNew = new Date().toISOString();

        const vSubRedditListJSON = { 
            "reddit1" : "onlyfansadvice",
            "reddit2" : "OnlyFansAdvice"
        };

        const vSubRedditList = JSON.stringify(vSubRedditListJSON);      

      let vBlogItem = {
        id: vNewBlog,
        AccountGroup: vAccountGroupSelected["AccountGroup"],
        Account: Users,
        Blog: vNewBlog,
        BlogEmail: "",
        RedditClientId: "",
        RedditClientSecret: "",
        RedditUserName: "",
        RedditPassword: "",
        RedditUserAgent: "",
        OpenAIapiKey: "",
        OpenAIOrganization: "",
        OpenAIProject: "",
        OpenAIModel: "",
        S3Bucket: "",
        S3Key: "",
        BlogURL: "",
        ReadCount: 0,
        PixelTrackerAPIURL:  "",
        BlogStatus: "new",
        Categories: "",
        Active: true,
        CreationName: Users,
        CreationDateTime: vUpdateDateTimeNew,
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTimeNew,
      };

      //console.log("BlogManagerTable.SaveRow.CampaignItem:  ",CampaignItem);
     


      const createBlogResponse = await client.graphql({
        query: createBlog,
        variables: {
          input: vBlogItem,
        },
      });
      setvAddNewletterVisible(false);
      setvNewBlog("");
      GetBlogs();

    } catch (err) {

      //console.log("BlogManagerTable.SaveRow.err:  ",err);

      RhyteItLogging(
        Users,
        "BlogManager",
        "CreateBlog",
        err["errors"][0]["message"],
        '{"ERROR": "' + err["errors"][0]["message"] + '"}',
        0
      );
    }
  }

  async function UpdateBlog() {
    try {
      
      //console.log("BlogManager.UpdateBlog.vBlogSelected:  ",vBlogSelected);
      //console.log("BlogManager.UpdateBlog.vBlogTitle:  ",vBlogTitle);

      var vUpdateDateTime = new Date().toISOString();

      vBlogSelected["Blog"] = vBlogTitle
      vBlogSelected["Description"] = vBlogDescription

      const vSubRedditListJSON = { 
          "reddit1" : "onlyfansadvice",
          "reddit2" : "OnlyFansAdvice"
      };

      const vSubRedditList = JSON.stringify(vSubRedditListJSON);      

      let vBlogItem = {
        id: vBlogSelected["id"],
        AccountGroup: vBlogSelected["AccountGroup"],
        Account: vBlogSelected["Account"],
        Blog: vBlogTitle,
        BlogEmail: vBlogEmail,
        Description: vBlogDescription,
        RedditClientId: vRedditClientId,
        RedditClientSecret: vRedditClientSecret,
        RedditUserName: vRedditUserName,
        RedditPassword: vRedditPassword,
        RedditUserAgent: vRedditUserAgent,
        OpenAIapiKey: vOpenAIapiKey,
        OpenAIOrganization: vOpenAIOrganization,
        OpenAIProject: vOpenAIProject,
        OpenAIModel: vOpenAIModel,
        Categories: vBlogCategories,
        S3Bucket: vS3Bucket,
        S3Key: vBlogSelected["S3Key"],
        BlogURL:  vBlogURL,
        ReadCount: vBlogSelected["ReadCount"],
        PixelTrackerAPIURL: vPixelTrackerAPIURL,
        BlogStatus: "updated",
        Active: vBlogSelected["Active"],
        CreationName: vBlogSelected["CreationName"],
        CreationDateTime: vBlogSelected["CreationDateTime"],
        UpdateName: Users,
        UpdateDateTime: vUpdateDateTime,
      };

      const updateBlogResponse = await client.graphql({
        query: updateBlog,
        variables: {
          input: vBlogItem,
        },
      });

      
      //console.log("BlogManager.UpdateBlog.updateBlogResponse:  ",updateBlogResponse);

      setvBlogSelected(updateBlogResponse.data.updateBlog)

    } catch (err) {
      RhyteItLogging(
        Users,
        "BlogManager",
        "UpdateBlog",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }


  async function BlogSelectedAction(pBlog) {
    try {
      //console.log("BlogManager.BlogSelectedAction.pBlog: ", pBlog);
      let vSelectedBlog = vBlogList.filter(
        (vNewletterItem) => vNewletterItem["Blog"] === pBlog
      );

      //console.log("BlogManager.BlogSelectedAction.vSelectedBlog: ",vSelectedBlog);
      //console.log("BlogManager.BlogSelectedAction.vSelectedBlog[0]: ",vSelectedBlog[0]);
      setvBlogVisible(true);
      //setvBlogSelected(pAccount);
      setvBlogSelected(vSelectedBlog[0]);
    } catch (err) {

      const errorMessage = err.message || 'Unknown error';
      const errorStack = err.stack || 'No stack trace available';


      RhyteItLogging(
        Users,
        "BlogManager",
        "BlogSelectedAction",
        errorMessage,
        '{"ERROR": "' + errorStack + '"}',
        0
      );
    }
  }

  async function AccountGroupSelectedAction(pAccountGroup) {
    try {
      //    //console.log("BlogManager.BlogSelectedAction.pAccount: ", pAccount);
      let vSelectedBlogGroup = vAccountGroupList.filter(
        (vAccountGroupItem) => vAccountGroupItem["AccountGroup"] === pAccountGroup
      );

      //console.log("BlogManager.AccountGroupSelectedAction.vSelectedBlogGroup: ",vSelectedBlogGroup);
      //console.log("BlogManager.BlogSelectedAction.vSelectedBlog[0]: ",vSelectedBlog[0]);
      setvBlogVisible(true);
      //setvBlogSelected(pAccount);
      setvAccountGroupSelected(vSelectedBlogGroup[0]);
    } catch (err) {
      RhyteItLogging(
        Users,
        "BlogManager",
        "AccountGroupSelectedAction",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  async function ViewConfig(event) {
    try {
      //console.log("BlogManager.ViewConfig.event: ", event);
      //console.log("BlogManager.ViewConfig.vViewPostingConfig: ", vViewPostingConfig);
      setvAddNewletterVisible(!vAddNewletterVisible)


    } catch (err) {
      RhyteItLogging(
        Users,
        "BlogManager",
        "ViewConfig",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }

  function ShowTable(event, pTable){

    if (pTable === "BlogAIPrompt") {

      setvViewBlogMetaDataSection(false)
      setvBlogPromptsVisible(true)
      setvBlogPostsVisible(false)

    } else if (pTable === "BlogManager") {
      setvViewBlogMetaDataSection(true)
      setvBlogPromptsVisible(false)
      setvBlogPostsVisible(false)
    }else if (pTable === "BlogPosts") {
      setvViewBlogMetaDataSection(false)
      setvBlogPromptsVisible(false)
      setvBlogPostsVisible(true)
    }

    

   };  
  
   function NewBlog_handleChange(event, data) {
    try {
      setvNewBlog(event.target.value);
    } catch (err) {
      RhyteItLogging(
        Users,
        "BlogManager",
        "NewBlog_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }


  function Field_handleChange(event, data, pButtonTitle) {
    try {

      //console.log("NewsletterEditionRow.IntroductionButtonTitle_handleChange.pButtonTitle: " ,pButtonTitle);

      let vTmp = event.target.value


      if (pButtonTitle === "vBlogTitle") {
        setvBlogTitle(vTmp)
      } else if (pButtonTitle === "vBlogDescription") {
        setvBlogDescription(vTmp)  
      } else if (pButtonTitle === "vBlogEmail") {
        setvBlogEmail(vTmp)  
      } else if (pButtonTitle === "vRedditClientId") {
        setvRedditClientId(vTmp)  
      } else if (pButtonTitle === "vRedditClientSecret") {
        setvRedditClientSecret(vTmp)  
      } else if (pButtonTitle === "vRedditUserName") {
        setvRedditUserName(vTmp)  
      } else if (pButtonTitle === "vRedditPassword") {
        setvRedditPassword(vTmp)  
      } else if (pButtonTitle === "vRedditUserAgent") {
        setvRedditUserAgent(vTmp)  
      } else if (pButtonTitle === "vOpenAIapiKey") {
        setvOpenAIapiKey(vTmp)  
      } else if (pButtonTitle === "vS3Bucket") {
        setvS3Bucket(vTmp)  
      } else if (pButtonTitle === "vBlogURL") {
        setvBlogURL(vTmp) 
      }  else if (pButtonTitle === "vPixelTrackerAPIURL") {
        setvPixelTrackerAPIURL(vTmp)
      } else if (pButtonTitle === "vOpenAIOrganization") {
        setvOpenAIOrganization(vTmp)
      } else if (pButtonTitle === "vOpenAIProject") {
        setvOpenAIProject(vTmp)
      } else if (pButtonTitle === "vOpenAIModel") {
        setvOpenAIModel(vTmp)
      } else if (pButtonTitle === "vBlogCategories") {
        setvBlogCategories(vTmp)
      } 

    } catch (err) {
      RhyteItLogging(
        Users,
        "NewsletterEditionRow",
        "Field_handleChange",
        err.toString(),
        '{"ERROR": "' + err.toString() + '"}',
        0
      );
    }
  }    

  return (
    <m.div
      key="Search"
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeOut" }}
      exit={{ opacity: 1 }}
    >
      <MenuBackground
        className={styles.MenuBackground}
        GradientStopColor1="#0000"
        GradientStopColor2="#000"
        GradientStopColor3="#00CCCC"
        GradientStopColor4="#8880"
      />

      <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexColumn}
      >
        <Header
          key={Date.now()}
          Title="Blog Manager"
          SubTitle="Manage your newsletter"
          BreadCrumbItems={vBreadCrumbItems}
          pageSource="BlogManager"
        />
      <Flex
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="1rem"
      >        
        {vAccountGroupList !== undefined &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Account Group</legend>
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
          <SelectField
            className={styles.SelectField}
            value={vAccountGroupSelected.AccountGroup}
            placeholder="Select account"
            onChange={(e) => AccountGroupSelectedAction(e.target.value)}
          >
            {vAccountGroupList !== undefined &&
              vAccountGroupList.map((AccountGroup) => (
                <option
                  key={AccountGroup.AccountGroup}
                  className={styles.SelectFieldOption}
                  value={AccountGroup.AccountGroup}
                  label={AccountGroup.AccountGroup}
                >
                  {AccountGroup.AccountGroup}
                </option>
              ))}
          </SelectField>  
          {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
            <Flex
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="nowrap"
              gap="0.25rem"
            >
              <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.AccountGroup}</Text>  
            </Flex> 
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="1rem"
              className={styles.FlexRow}
            >
              <Text  className={styles.AccountGroupLabel}>Parent Account:</Text> 
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.ParentAccount}</Text>
              <Text  className={styles.AccountGroupLabel}>Sync On:</Text> 
              <Text  className={styles.AccountGroup}>{vAccountGroupSelected.SyncAccounts.toString()}</Text>
            </Flex> 
            </Flex>              
          )}
          </Flex>         
        </Fieldset>        
        )}   
        {vAccountGroupSelected["AccountGroup"] !== "None" &&  (
        <Fieldset className={styles.Fieldset} size="small">
          <legend className={styles.legend}>Blogs</legend>
          <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
          >
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
              gap="3rem"
              className={styles.FlexRow}
            >
              <SelectField
                className={styles.SelectField}
                value={vBlogSelected.Blog}
                placeholder="Select Blog"
                onChange={(e) => BlogSelectedAction(e.target.value)}
              >
                {vBlogList !== undefined &&
                  vBlogList.map((Blog) => (
                    <option
                      key={Blog.Blog}
                      className={styles.SelectFieldOption}
                      value={Blog.Blog}
                      label={Blog.Blog}
                    >
                      {Blog.Blog}
                    </option>
                  ))}
              </SelectField>
              <Button
                  className={styles.Button}
                  variation="primary"
                  onClick={(e) => ViewConfig(e)}
                >
                  Add Blog
                </Button>            
            </Flex>
            {vAddNewletterVisible === true && (
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="3rem"
                className={styles.NewBlogRow}
              >
                <TextField
                  className={styles.CampaignField}
                  name="Account"
                  placeholder="Add new Blog"
                  defaultValue={vNewBlog}
                  onChange={(e) => NewBlog_handleChange(e, vNewBlog)}
                />
                <Button
                  className={styles.Button}
                  variation="primary"
                  onClick={(e) => CreateBlog(e)}
                >
                  Create Blog
                </Button>
              </Flex>
            )}
            </Flex>                  
        </Fieldset>             
        )}           
      </Flex>   
    
      {vBlogSelected["Blog"] !== "None" &&  (
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
                gap="3rem"
                className={styles.NewBlogRow}
              >
                <Button
                  className={styles.NavigationButton}
                  variation="primary"
                  onClick={(e) =>  ShowTable(e, "BlogManager")}
                >
                  Manage Template
                </Button>   
                <Button
                  className={styles.NavigationButton}
                  variation="primary"
                  onClick={(e) =>  ShowTable(e, "BlogAIPrompt")}
                >
                  AI Prompt
                </Button>         
                <Button
                  className={styles.NavigationButton}
                  variation="primary"
                  onClick={(e) =>  ShowTable(e, "BlogPosts")}
                >
                  Blog Posts
                </Button>  
              </Flex>
            )} 
      {vViewBlogMetaDataSection === true &&  (
        <BlogMetaData Blog={vBlogSelected} Users={Users}/>               
      )}   
      {vBlogPromptsVisible === true &&  (
        <BlogAIPromptTable Blog={vBlogSelected} Users={Users}/>               
      )}   
      {vBlogPostsVisible === true &&  (
        <BlogPosts Blog={vBlogSelected} Users={Users}/>               
      )}   
                 
      </Flex>                   
    </m.div>
  );
};

export default BlogManager;
