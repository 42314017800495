
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  Link,
  Text,
  TextField,
  TextAreaField,
  Flex
  } from '@aws-amplify/ui-react';

  import  { }  from '../../graphql/queries'
  import  { updateSBSMManager}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  
  
  import styles from './SBSMParameterRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
  import {RhyteItLogging} from '../../BusinessLogic/UserManagement';

const SBSMParameterRow = (props) => {       

  SBSMParameterRow.propTypes = {
      SBSMParameter: PropTypes.array,
      ExpandSection: PropTypes.string
      };
  
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    let ErrorMessageStr = ""
    const [vSBSMParameter, setvSBSMParameter] = useState(props.SBSMParameter)  
    const [vExpandSection, setvExpandSection] = useState()
    
    const [vExpandRow, setvExpandRow] = useState(false) 
    const [vTextString, setvTextString] = useState()  
    const [vExpandRowIcon, setvExpandRowIcon] = useState("+")  

    const [vOpenAIPrompt, setvOpenAIPrompt] = useState()  
    const [vOpenAITrendAnalysisPrompt, setvOpenAITrendAnalysisPrompt] = useState()  
    const [vOpenAITwitterPrompt, setvOpenAITwitterPrompt] = useState()  
    const [vOpenAIHtmlPrompt, setvOpenAIHtmlPrompt] = useState()
    const [vOpenAIImagePrompt, setvOpenAIImagePrompt] = useState()   

    const [vS3Bucket, setvS3Bucket] = useState() 
    const [vS3Prefix, setvS3Prefix] = useState() 
    const [vAuthor, setvAuthor] = useState() 
    const [vLogoUrl, setvLogoUrl] = useState() 
    const [vUrl, setvUrl] = useState()  
    const [vOrganization, setvOrganization] = useState()  
    const [vAWSBedrockModelIdImage, setvAWSBedrockModelIdImage] = useState()
    const [vCategories, setvCategories] = useState()
    
    const [vArticleHtmlHeadSection, setvArticleHtmlHeadSection] = useState() 
    const [vArticleHtmlStyleSection, setvArticleHtmlStyleSection] = useState() 
    const [vArticleHtmlHeaderSection, setvArticleHtmlHeaderSection] = useState() 
    const [vArticleHtmlAuthorInfoSection, setvArticleHtmlAuthorInfoSection] = useState() 
    const [vArticleHtmlFooterSection, setvArticleHtmlFooterSection] = useState() 
    const [vArticleHtmlGooglePublishingSection, setvArticleHtmlGooglePublishingSection] = useState()

    

    const [vKey, setvKey] = useState()
     

    useEffect(() => {

      console.log("SBSMParameterRow.useEffect[].props.SBSMParamete:  ",props.SBSMParameter) 

      },[])  

    useEffect(() => {

         // console.log("SBSMParameterRow.useEffect[].vSBSMParameter:  ",vSBSMParameter) 
          setErrorMessage("")

          if (vSBSMParameter !== undefined) {
          
            setvOpenAIPrompt(vSBSMParameter.OpenAIPrompt)
            setvOpenAITrendAnalysisPrompt(vSBSMParameter.OpenAITrendAnalysisPrompt)
            setvOpenAITwitterPrompt(vSBSMParameter.OpenAITwitterPrompt)
            setvOpenAIHtmlPrompt(vSBSMParameter.OpenAIHtmlPrompt)
            setvOpenAIImagePrompt(vSBSMParameter.OpenAIImagePrompt)

            
            setvS3Bucket(vSBSMParameter.S3Bucket)
            setvS3Prefix(vSBSMParameter.S3Prefix)
            setvAuthor(vSBSMParameter.Author)
            setvLogoUrl(vSBSMParameter.LogoUrl)
            setvUrl(vSBSMParameter.Url)
            setvOrganization(vSBSMParameter.Organization)
            setvAWSBedrockModelIdImage(vSBSMParameter.AWSBedrockModelIdImage)
            setvCategories(vSBSMParameter.Categories)
            
            
            setvArticleHtmlHeadSection(vSBSMParameter.ArticleHtmlHeadSection)
            setvArticleHtmlStyleSection(vSBSMParameter.ArticleHtmlStyleSection)
            setvArticleHtmlHeaderSection(vSBSMParameter.ArticleHtmlHeaderSection)
            setvArticleHtmlAuthorInfoSection(vSBSMParameter.ArticleHtmlAuthorInfoSection)
            setvArticleHtmlFooterSection(vSBSMParameter.ArticleHtmlFooterSection)
            setvArticleHtmlGooglePublishingSection(vSBSMParameter.ArticleHtmlGooglePublishingSection)
            setvKey(Date.now())
          }          

      },[vSBSMParameter])             
    



   async function SaveSBSMParameterRow(pEvent, pStatus) {
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()


          console.log("SBSMParameterRow.SaveSBSMParameterRow.vOpenAIPrompt:  ",vOpenAIPrompt) 

          vSBSMParameter.UpdateDateTime = vUpdateDateTime       
          vSBSMParameter.UpdateName = Users   
          vSBSMParameter.OpenAIPrompt = vOpenAIPrompt
          vSBSMParameter.OpenAITrendAnalysisPrompt = vOpenAITrendAnalysisPrompt
          vSBSMParameter.OpenAITwitterPrompt = vOpenAITwitterPrompt
          vSBSMParameter.OpenAIHtmlPrompt = vOpenAIHtmlPrompt
          vSBSMParameter.OpenAIImagePrompt = vOpenAIImagePrompt

          vSBSMParameter.S3Bucket = vS3Bucket
          vSBSMParameter.S3Prefix = vS3Prefix
          vSBSMParameter.Author = vAuthor
          vSBSMParameter.LogoUrl = vLogoUrl
          vSBSMParameter.Url = vUrl
          vSBSMParameter.Organization = vOrganization
          vSBSMParameter.AWSBedrockModelIdImage = vAWSBedrockModelIdImage
          vSBSMParameter.Categories = vCategories
          

          vSBSMParameter.ArticleHtmlHeadSection = vArticleHtmlHeadSection
          vSBSMParameter.ArticleHtmlStyleSection = vArticleHtmlStyleSection
          vSBSMParameter.ArticleHtmlHeaderSection = vArticleHtmlHeaderSection
          vSBSMParameter.ArticleHtmlAuthorInfoSection = vArticleHtmlAuthorInfoSection
          vSBSMParameter.ArticleHtmlFooterSection = vArticleHtmlFooterSection
          vSBSMParameter.ArticleHtmlGooglePublishingSection = vArticleHtmlGooglePublishingSection

          let ParameterItems = {
            "id": vSBSMParameter.id,
            "AccountGroup": vSBSMParameter.AccountGroup,
            "Account": vSBSMParameter.Account,
            "SBSMTitle": vSBSMParameter.SBSMTitle,
            "Description": vSBSMParameter.Description,
            "OpenAIPrompt": vSBSMParameter.OpenAIPrompt,
            "OpenAITrendAnalysisPrompt": vSBSMParameter.OpenAITrendAnalysisPrompt,
            "OpenAITwitterPrompt": vSBSMParameter.OpenAITwitterPrompt,
            "OpenAIHtmlPrompt": vSBSMParameter.OpenAIHtmlPrompt,
            "OpenAIImagePrompt": vSBSMParameter.OpenAIImagePrompt,
            "OpenAIapiKey": vSBSMParameter.OpenAIapiKey,
            "OpenAIOrganization": vSBSMParameter.OpenAIOrganization,
            "OpenAIProject": vSBSMParameter.OpenAIProject,
            "OpenAIModel": vSBSMParameter.OpenAIModel,
            "AWSBedrockModelIdImage": vSBSMParameter.AWSBedrockModelIdImage,
            "Categories": vSBSMParameter.Categories,
            "S3Bucket": vSBSMParameter.S3Bucket,
            "S3Prefix": vSBSMParameter.S3Prefix,
            "Author": vSBSMParameter.Author,
            "LogoUrl": vSBSMParameter.LogoUrl,
            "Url": vSBSMParameter.Url,
            "Organization": vSBSMParameter.Organization,  
            "ArticleHtmlHeadSection": vSBSMParameter.ArticleHtmlHeadSection, 
            "ArticleHtmlStyleSection": vSBSMParameter.ArticleHtmlStyleSection, 
            "ArticleHtmlHeaderSection": vSBSMParameter.ArticleHtmlHeaderSection, 
            "ArticleHtmlAuthorInfoSection": vSBSMParameter.ArticleHtmlAuthorInfoSection, 
            "ArticleHtmlFooterSection": vSBSMParameter.ArticleHtmlFooterSection,  
            "ArticleHtmlGooglePublishingSection": vSBSMParameter.ArticleHtmlGooglePublishingSection,  
            "NewsSourceCount": vSBSMParameter.NewsSourceCount,
            "ArticleCount": vSBSMParameter.ArticleCount,
            "TopicCount": vSBSMParameter.TopicCount,
            "CreationName": vSBSMParameter.CreationName,
            "UpdateName":vSBSMParameter.UpdateName,
            "CreationDateTime": vSBSMParameter.CreationDateTime,
            "UpdateDateTime": vSBSMParameter.UpdateDateTime,
          }

            console.log("SBSMParameterRow.SaveSBSMParameterRow.ParameterItems:  ",ParameterItems) 
            
            const ReturnMessage = await client.graphql({
              query: updateSBSMManager,
              variables: {
                input: ParameterItems
              }              
            });  

            console.log("SBSMParameterRow.SaveSBSMParameterRow.ReturnMessage:  ",ReturnMessage.data.updateSBSMManager) 

            ErrorMessageStr = "Parameters saved at: " + today.toLocaleString("en-US")

            setvSBSMParameter(ReturnMessage.data.updateSBSMManager) 
            setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          ErrorMessageStr = ("SBSMParameterRow.SaveSBSMParameterRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }    

    async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("SBSMParameterRow.ExpandRow.pExpandRowTmp: " ,pExpandRowTmp);
        setvExpandRow(!pExpandRowTmp)

        setvExpandRowIcon(!pExpandRowTmp === true ? "-" : "+")


          } catch (err) { 

          ErrorMessageStr = "SBSMParameterRow.ExpandRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }   

      async  function Field_handleChange(event, data, pButtonTitle) {
        try {
  
          console.log("SBSMParameterRow.Field_handleChange.pButtonTitle: " ,pButtonTitle);
  
          let vTmp = event.target.value
  
          if (pButtonTitle === "vOpenAIPrompt") {
            setvOpenAIPrompt(vTmp)
          } else if (pButtonTitle === "vOpenAITrendAnalysisPrompt") {
            setvOpenAITrendAnalysisPrompt(vTmp)
          } else if (pButtonTitle === "vOpenAITwitterPrompt") {
            setvOpenAITwitterPrompt(vTmp)
          }  else if (pButtonTitle === "vOpenAIHtmlPrompt") {
            setvOpenAIHtmlPrompt(vTmp)
          }   else if (pButtonTitle === "vS3Bucket") {
            setvS3Bucket(vTmp)
          }   else if (pButtonTitle === "vS3Prefix") {
            setvS3Prefix(vTmp)
          }   else if (pButtonTitle === "vAuthor") {
            setvAuthor(vTmp)
          }   else if (pButtonTitle === "vLogoUrl") {
            setvLogoUrl(vTmp)
          }   else if (pButtonTitle === "vUrl") {
            setvUrl(vTmp)
          }  else if (pButtonTitle === "vOrganization") {
            setvOrganization(vTmp)
          }  else if (pButtonTitle === "vArticleHtmlHeadSection") {
            setvArticleHtmlHeadSection(vTmp)
          }  else if (pButtonTitle === "vArticleHtmlStyleSection") {
            setvArticleHtmlStyleSection(vTmp)
          }  else if (pButtonTitle === "vArticleHtmlHeaderSection") {
            setvArticleHtmlHeaderSection(vTmp)
          }  else if (pButtonTitle === "vArticleHtmlAuthorInfoSection") {
            setvArticleHtmlAuthorInfoSection(vTmp)
          }  else if (pButtonTitle === "vArticleHtmlFooterSection") {
            setvArticleHtmlFooterSection(vTmp)
          }  else if (pButtonTitle === "vArticleHtmlGooglePublishingSection") {
            setvArticleHtmlGooglePublishingSection(vTmp)
          }  else if (pButtonTitle === "vOpenAIImagePrompt") {
            setvOpenAIImagePrompt(vTmp)
          }  else if (pButtonTitle === "vAWSBedrockModelIdImage") {
            setvAWSBedrockModelIdImage(vTmp)
          }   else if (pButtonTitle === "vCategories") {
            setvCategories(vTmp)
          } 
          
          
          
        } catch (err) {

          ErrorMessageStr = "SBSMParameterRow.Field_handleChange.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          

          RhyteItLogging(
            Users,
            "SBSMParameterRow",
            "Field_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      async function ParameterFilter(pFilter) {
        try {

               
          setvExpandSection(pFilter)
          setvKey(Date.now())
              
  
        } catch (err) { 
          ErrorMessageStr = ("SBSMNewsAdmin.setArticleFilter.Error: ", err.stack)
          console.error(ErrorMessageStr)
          ErrorMessageStr = ("SBSMNewsAdmin.setArticleFilter.Error: ", err)
          console.error(ErrorMessageStr)
          RhyteItLogging(Users, "SBSMNewsAdmin", "setArticleFilter",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
                    }
      }     

  return (
      <TableBody className={styles.Background} key={vKey}>  
        <TableRow  className={styles.ActionRow}  >         
        <TableCell className={styles.ButtonCell}> 
        <Button
                loadingText=""
                ariaLabel=""
                className={styles.Button}
                onClick={(e) => ParameterFilter("ALL")}
              >
              All
          </Button> 
          </TableCell>  
          <TableCell  className={styles.ButtonCell} >   
          <Button
                loadingText=""
                ariaLabel=""
                title="Save blog post"
                className={styles.Button}
                //onClick={(e) => UpdateRecord(e)}
                onClick={(e) => ParameterFilter("Prompts")}
                >
                Prompts
          </Button>    
          </TableCell>  
          <TableCell  className={styles.ButtonCell} > 
          <Button
                loadingText=""
                ariaLabel=""
                title="Analyze for trend information"
                className={styles.Button}
                //onClick={(e) => UpdateRecord(e)}
                onClick={(e) => ParameterFilter("HTML")}
                >
                HTML
          </Button> 
          </TableCell>  
          <TableCell  className={styles.ButtonCell} > 
          <Button
                loadingText=""
                ariaLabel=""
                title="Analyze for trend information"
                className={styles.Button}
                //onClick={(e) => UpdateRecord(e)}
                onClick={(e) => ParameterFilter("MetaData")}
                >
                MetaData
          </Button> 
          </TableCell>    
          <TableCell  className={styles.ButtonCell} > 
          <Button
                loadingText=""
                ariaLabel=""
                title="Analyze for trend information"
                className={styles.Button}
                //onClick={(e) => UpdateRecord(e)}
                onClick={(e) => SaveSBSMParameterRow(e, "UPDATED")}
                >
                Save
          </Button> 
          </TableCell>  
          <TableCell  className={styles.ButtonCell} > 
          <Button
                loadingText=""
                ariaLabel=""
                title="Refresh"
                className={styles.Button}
                //onClick={(e) => UpdateRecord(e)}
                onClick={(e) => ParameterFilter("REFRESH")}
                >
                Refresh
          </Button> 
          </TableCell>                                
        </TableRow>                              
        <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>                       
          <TableCell colSpan="3"> 
                  <Text className={styles.RowHeader}>
                  {vSBSMParameter.SBSMTitle}
                  </Text>         
          </TableCell>       
          <TableCell  className={styles.TableCellPubDate} colSpan="3">                  
              <Text className={styles.RowHeader}>
              {new Date(vSBSMParameter.UpdateDateTime).toLocaleString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", hourCycle: "h23"  }).replace(",", "")}
                  </Text>       
          </TableCell>                                 
        </TableRow>  
          {
            (vExpandSection ==="Prompts" || vExpandSection ==="ALL") && (           
              <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                <TableCell className={styles.TableCellParameter}>
                          <Text className={styles.RowHeader}>
                          OpenAI Prompt
                          </Text>                     
                          </TableCell>         
                <TableCell className={styles.TableCellValue} colspan="5">
                      <TextAreaField
                                className={styles.TextAreaField}
                                name="vOpenAIPrompt"
                                placeholder="Add OpenAI Prompt"
                                defaultValue={vOpenAIPrompt}
                                rows={5}
                                resize="vertical"
                                autoResize
                                onChange={(e) => Field_handleChange(e, vOpenAIPrompt, "vOpenAIPrompt")}
                          />                        
                </TableCell>                        
              </TableRow>
            )
          }
          {
            (vExpandSection ==="Prompts" || vExpandSection ==="ALL") && ( 
              <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                <TableCell className={styles.TableCellParameter}>
                          <Text className={styles.RowHeader}>
                          OpenAI Trend Analysis Prompt
                          </Text>                     
                          </TableCell>         
                <TableCell className={styles.TableCellValue} colspan="5">
                      <TextAreaField
                                className={styles.TextAreaField}
                                name="vOpenAITrendAnalysisPrompt"
                                placeholder="Add OpenAI Trend Analysis Prompt"
                                defaultValue={vOpenAITrendAnalysisPrompt}
                                rows={5}
                                resize="vertical"
                                autoResize
                                onChange={(e) => Field_handleChange(e, vOpenAITrendAnalysisPrompt, "vOpenAITrendAnalysisPrompt")}
                          />                        
                </TableCell>                        
              </TableRow>  
            )
          }  
          {
            (vExpandSection ==="Prompts" || vExpandSection ==="ALL") && ( 
              <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                <TableCell className={styles.TableCellParameter}>
                          <Text className={styles.RowHeader}>
                          OpenAI Twitter Prompt
                          </Text>                     
                          </TableCell>         
                <TableCell className={styles.TableCellValue} colspan="5">
                      <TextAreaField
                                className={styles.TextAreaField}
                                name="vOpenAITwitterPrompt"
                                placeholder="Add OpenAI Twitter Prompt"
                                defaultValue={vOpenAITwitterPrompt}
                                rows={5}
                                resize="vertical"
                                autoResize
                                onChange={(e) => Field_handleChange(e, vOpenAITwitterPrompt, "vOpenAITwitterPrompt")}
                          />                        
                </TableCell>                        
              </TableRow> 
            )
          }  
          {
            (vExpandSection ==="Prompts" || vExpandSection ==="ALL") && ( 
              <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                <TableCell className={styles.TableCellParameter}>
                          <Text className={styles.RowHeader}>
                          Image Prompt
                          </Text>                     
                          </TableCell>         
                <TableCell className={styles.TableCellValue} colspan="5">
                      <TextAreaField
                                className={styles.TextAreaField}
                                name="vOpenAIImagePrompt"
                                placeholder="Add Image Prompt"
                                defaultValue={vOpenAIImagePrompt}
                                rows={5}
                                resize="vertical"
                                autoResize
                                onChange={(e) => Field_handleChange(e, vOpenAIImagePrompt, "vOpenAIImagePrompt")}
                          />                        
                </TableCell>                        
              </TableRow> 
            )
          }   
          {
            (vExpandSection ==="Prompts" || vExpandSection ==="ALL") && ( 
              <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                <TableCell className={styles.TableCellParameter}>
                          <Text className={styles.RowHeader}>
                          OpenAI HTML Prompt
                          </Text>                     
                          </TableCell>         
                <TableCell className={styles.TableCellValue} colspan="5">
                      <TextAreaField
                                className={styles.TextAreaField}
                                name="vOpenAIHtmlPrompt"
                                placeholder="Add OpenAI HTML Prompt"
                                defaultValue={vOpenAIHtmlPrompt}
                                rows={5}
                                resize="vertical"
                                autoResize
                                onChange={(e) => Field_handleChange(e, vOpenAIHtmlPrompt, "vOpenAIHtmlPrompt")}
                          />                        
                </TableCell>                        
              </TableRow>  
            )
          }   
          {
            (vExpandSection ==="MetaData" || vExpandSection ==="ALL") && ( 
                <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                  <TableCell className={styles.TableCellParameter}>
                            <Text className={styles.RowHeader}>
                            S3 Bucket
                            </Text>                     
                            </TableCell>         
                  <TableCell className={styles.TableCellValue} colspan="5">
                        <TextField
                                  className={styles.TextField}
                                  name="vS3Bucket"
                                  placeholder="Add S3 Bucket"
                                  defaultValue={vS3Bucket}
                                  rows={10}
                                  resize="vertical"
                                  autoResize
                                  onChange={(e) => Field_handleChange(e, vS3Bucket, "vS3Bucket")}
                            />                        
                  </TableCell>                        
                </TableRow>      
              )
            }                             
            {
              (vExpandSection ==="MetaData" || vExpandSection ==="ALL") && ( 
                <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                  <TableCell className={styles.TableCellParameter}>
                            <Text className={styles.RowHeader}>
                            Logo Url
                            </Text>                     
                            </TableCell>         
                  <TableCell className={styles.TableCellValue} colspan="5">
                        <TextField
                                  className={styles.TextField}
                                  name="vAuthor"
                                  placeholder="Add Logo Url"
                                  defaultValue={vLogoUrl}
                                  rows={10}
                                  resize="vertical"
                                  autoResize
                                  onChange={(e) => Field_handleChange(e, vLogoUrl, "vLogoUrl")}
                            />                        
                  </TableCell>                        
                </TableRow>   
              )
            }        
            {
              (vExpandSection ==="MetaData" || vExpandSection ==="ALL") && ( 
                  <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                    <TableCell className={styles.TableCellParameter}>
                              <Text className={styles.RowHeader}>
                              Url
                              </Text>                     
                              </TableCell>         
                    <TableCell className={styles.TableCellValue} colspan="5">
                          <TextField
                                    className={styles.TextField}
                                    name="vUrl"
                                    placeholder="Add Url"
                                    defaultValue={vUrl}
                                    rows={10}
                                    resize="vertical"
                                    autoResize
                                    onChange={(e) => Field_handleChange(e, vUrl, "vUrl")}
                              />                        
                    </TableCell>                        
                  </TableRow>   
              )
            }         
            {
              (vExpandSection ==="MetaData" || vExpandSection ==="ALL") && ( 
                      <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                        <TableCell className={styles.TableCellParameter}>
                                  <Text className={styles.RowHeader}>
                                  Organization
                                  </Text>                     
                                  </TableCell>         
                        <TableCell className={styles.TableCellValue} colspan="5">
                              <TextField
                                        className={styles.TextField}
                                        name="vOrganization"
                                        placeholder="Add Organization"
                                        defaultValue={vOrganization}
                                        rows={10}
                                        resize="vertical"
                                        autoResize
                                        onChange={(e) => Field_handleChange(e, vOrganization, "vOrganization")}
                                  />                        
                        </TableCell>                        
                      </TableRow>            
          
              )
            }         
            {
              (vExpandSection ==="MetaData" || vExpandSection ==="ALL") && ( 
                      <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                        <TableCell className={styles.TableCellParameter}>
                                  <Text className={styles.RowHeader}>
                                  AWS Bedrock Model Id - Image
                                  </Text>                     
                                  </TableCell>         
                        <TableCell className={styles.TableCellValue} colspan="5">
                              <TextField
                                        className={styles.TextField}
                                        name="vAWSBedrockModelIdImage"
                                        placeholder="Add AWS Bedrock Model Id"
                                        defaultValue={vAWSBedrockModelIdImage}
                                        resize="vertical"
                                        autoResize
                                        onChange={(e) => Field_handleChange(e, vAWSBedrockModelIdImage, "vAWSBedrockModelIdImage")}
                                  />                        
                        </TableCell>                        
                      </TableRow>            
          
              )
            }    
          {
            (vExpandSection ==="MetaData" || vExpandSection ==="ALL") && (           
              <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                <TableCell className={styles.TableCellParameter}>
                          <Text className={styles.RowHeader}>
                          Categories
                          </Text>                     
                          </TableCell>         
                <TableCell className={styles.TableCellValue} colspan="5">
                      <TextAreaField
                                className={styles.TextAreaField}
                                name="vCategories"
                                placeholder="Add Categories"
                                defaultValue={vCategories}
                                rows={5}
                                resize="vertical"
                                autoResize
                                onChange={(e) => Field_handleChange(e, vCategories, "vCategories")}
                          />                        
                </TableCell>                        
              </TableRow>
            )
          }

            {
              (vExpandSection ==="HTML" || vExpandSection ==="ALL") && ( 
                  <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                    <TableCell className={styles.TableCellParameter}>
                              <Text className={styles.RowHeader}>
                              Article Html Head
                              </Text>                     
                              </TableCell>         
                    <TableCell className={styles.TableCellValue} colspan="5">
                          <TextAreaField
                                    className={styles.TextAreaField}
                                    name="vArticleHtmlHeadSection"
                                    placeholder="Add Article Html Head"
                                    defaultValue={vArticleHtmlHeadSection}
                                    rows={5}
                                    resize="vertical"
                                    autoResize
                                    onChange={(e) => Field_handleChange(e, vArticleHtmlHeadSection, "vArticleHtmlHeadSection")}
                              />                        
                    </TableCell>                        
                  </TableRow>  
              )
            }    
            {
              (vExpandSection ==="HTML" || vExpandSection ==="ALL") && ( 
                  <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                    <TableCell className={styles.TableCellParameter}>
                              <Text className={styles.RowHeader}>
                              Article Html Style
                              </Text>                     
                              </TableCell>         
                    <TableCell className={styles.TableCellValue} colspan="5">
                          <TextAreaField
                                    className={styles.TextAreaField}
                                    name="vArticleHtmlStyleSection"
                                    placeholder="Add Article Html Style"
                                    defaultValue={vArticleHtmlStyleSection}
                                    rows={5}
                                    resize="vertical"
                                    autoResize
                                    onChange={(e) => Field_handleChange(e, vArticleHtmlStyleSection, "vArticleHtmlStyleSection")}
                              />                        
                    </TableCell>                        
                  </TableRow> 
                )
            }            
            {
              (vExpandSection ==="HTML" || vExpandSection ==="ALL") && ( 
                  <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                    <TableCell className={styles.TableCellParameter}>
                              <Text className={styles.RowHeader}>
                              Article Html Header
                              </Text>                     
                              </TableCell>         
                    <TableCell className={styles.TableCellValue} colspan="5">
                          <TextAreaField
                                    className={styles.TextAreaField}
                                    name="vArticleHtmlHeaderSection"
                                    placeholder="Add Article Html Style"
                                    defaultValue={vArticleHtmlHeaderSection}
                                    rows={5}
                                    resize="vertical"
                                    autoResize
                                    onChange={(e) => Field_handleChange(e, vArticleHtmlHeaderSection, "vArticleHtmlHeaderSection")}
                              />                        
                    </TableCell>                        
                  </TableRow> 
                )
            }            
            {
              (vExpandSection ==="HTML" || vExpandSection ==="ALL") && ( 
                  <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                    <TableCell className={styles.TableCellParameter}>
                              <Text className={styles.RowHeader}>
                              Article Html Author Info
                              </Text>                     
                              </TableCell>         
                    <TableCell className={styles.TableCellValue} colspan="5">
                          <TextAreaField
                                    className={styles.TextAreaField}
                                    name="vArticleHtmlAuthorInfoSection"
                                    placeholder="Add Article Html Author Info"
                                    defaultValue={vArticleHtmlAuthorInfoSection}
                                    rows={5}
                                    resize="vertical"
                                    autoResize
                                    onChange={(e) => Field_handleChange(e, vArticleHtmlAuthorInfoSection, "vArticleHtmlAuthorInfoSection")}
                              />                        
                    </TableCell>                        
                  </TableRow> 
                )
            }            
            {
              (vExpandSection ==="HTML" || vExpandSection ==="ALL") && ( 
                  <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                    <TableCell className={styles.TableCellParameter}>
                              <Text className={styles.RowHeader}>
                              Article Html Footer
                              </Text>                     
                              </TableCell>         
                    <TableCell className={styles.TableCellValue} colspan="5">
                          <TextAreaField
                                    className={styles.TextAreaField}
                                    name="vArticleHtmlFooterSection"
                                    placeholder="Add Article Html Footer"
                                    defaultValue={vArticleHtmlFooterSection}
                                    rows={5}
                                    resize="vertical"
                                    autoResize
                                    onChange={(e) => Field_handleChange(e, vArticleHtmlFooterSection, "vArticleHtmlFooterSection")}
                              />                        
                    </TableCell>                        
                  </TableRow> 
                )
            }                                 
            {
              (vExpandSection ==="HTML" || vExpandSection ==="ALL") && ( 
                <TableRow  className={styles.TableRowBackgroundTitle}  key={vSBSMParameter.id}>         
                  <TableCell className={styles.TableCellParameter}>
                            <Text className={styles.RowHeader}>
                            Article Html Google Publishing
                            </Text>                     
                            </TableCell>         
                  <TableCell className={styles.TableCellValue} colspan="5">
                        <TextAreaField
                                  className={styles.TextAreaField}
                                  name="vArticleHtmlGooglePublishingSection"
                                  placeholder="Add Article Html Google Publishing"
                                  defaultValue={vArticleHtmlGooglePublishingSection}
                                  rows={5}
                                  resize="vertical"
                                  autoResize
                                  onChange={(e) => Field_handleChange(e, vArticleHtmlGooglePublishingSection, "vArticleHtmlGooglePublishingSection")}
                            />                        
                  </TableCell>                        
                </TableRow>   
              )
            }
      {
        ErrorMessage.length > 0 &&             
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              colSpan="3">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
      }               
      </TableBody>
    
  )
}

export default SBSMParameterRow;