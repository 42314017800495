
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import {
    createBlogPosts,
    updateBlogPosts,
    deleteBlogPosts
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './BlogEntry.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function BlogEntry(
  props
) {       

  BlogEntry.propTypes = {
    BlogPost: PropTypes.object,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlogPost, setvBlogPost] = useState(props.BlogPost)  
    const [vPrompt, setvPrompt] = useState() 
    const [vPromptName, setvPromptName] = useState() 

    const [vSubRedditListString, setvSubRedditListString] = useState() 

    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vBlogPostActive, setvBlogPostActive] = useState(false)   
    const [vBlogPostHTMLString, setvBlogPostHTMLString] = useState()    
    
    
      useEffect(() => {

        //console.log("BlogEntry.useEffect[].props.BlogPost:  ",props.BlogPost) 

      },[])  

      useEffect(() => {
      //console.log("BlogEntry.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

      // //console.log("BlogEntry.useEffect[vBlogPost].vBlogPost: " ,vBlogPost.BlogHTMLBody);

        if (vBlogPost !== undefined) {

          if (vBlogPost.PictureURL === "") {
            //console.log("BlogEntry.useEffect[vBlogPost].vBlogPost.PictureURL: " ,vBlogPost.PictureURL);

          }
          

        }

      }, [vBlogPost]);   

      const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
      };

      return (
        <div className={vBlogPost.Category === "Content Creation & Strategy" ? styles.BackgroundSocialMedia: 
                        vBlogPost.Category === "Social Media & Marketing" ? styles.BackgroundLegalEthics: 
                        vBlogPost.Category === "Online Business & Entrepreneurship" ? styles.BackgroundMoneyBusiness:                         
                        vBlogPost.Category === "Personal Development & Branding" ? styles.BackgroundPersonalEmpowerment: 
                        styles.Background}
        onClick={(e) => openInNewTab(vBlogPost.PostURL)}>
        
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          wrap="nowrap"
          gap="1rem"
          className={styles.FlexLeftColumn}>   
        <Text>{vBlogPost.SubCategory}</Text>
        {  
                (vBlogPost.PictureURL !== null && vBlogPost.PictureURL !== "") &&  (                     
          <Image
              alt="RhyteIt logo"
              src={vBlogPost.PictureURL}
              objectFit="initial"
              objectPosition="50% 50%"
              backgroundColor="initial"
              className={styles.image}
              opacity="100%"
            />  
              )}  
              {
                (vBlogPost.PictureURL === null || vBlogPost.PictureURL === "") &&  (                        
                  <Image
                      alt="RhyteIt logo"
                      src="https://news.rhyteit.com/RhyteItLogo.png"
                      objectFit="initial"
                      objectPosition="50% 50%"
                      backgroundColor="initial"
                      opacity="100%"
                      className={styles.ImageLogo}
                    />  
                    )}                
         
             <Text className={styles.TextTitle} >
              {vBlogPost.Title}
             </Text>              
             <Text className={styles.Date} >
              {vBlogPost.Intro}
             </Text>
          <div
          className={styles.DateLine} >
            <Text
          className={styles.Date} 
          onClick={() => alert(vBlogPost.id)}>
             {new Date(vBlogPost.CreationDateTime).toLocaleDateString('en-US',{year: 'numeric',month: 'long',day: 'numeric'})}
             </Text>
          </div>
          </Flex>         
      </div>
      
    )
  }
export default BlogEntry;

