
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  TableBody,
  Button,
  Link,
  Text,
  TextField,
  TextAreaField,
  Flex
  } from '@aws-amplify/ui-react';

  import  { getSBSMNews}  from '../../graphql/queries'
  import  { deleteSBSMNews, updateSBSMNews, createSBSMNews}  from '../../graphql/mutations'
  import { generateClient } from 'aws-amplify/api';
  
  
  import styles from './SBSMNewsAdminRow.module.css';  
  import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
  import {RhyteItLogging} from '../../BusinessLogic/UserManagement';

const SBSMNewsAdminRow = (props) => {       

  SBSMNewsAdminRow.propTypes = {
      SBSMArticle: PropTypes.object
      };
  
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);   
    const [ErrorMessage, setErrorMessage] = useState("")
    let ErrorMessageStr = ""
    let debugConsoleOutPut = true
    let vHoursAgo = 0    
    const [vSBSMNewsArticle, setvSBSMNewsArticle] = useState(props.SBSMArticle)  
    const [vExpandRow, setvExpandRow] = useState(false) 
    const [vTextString, setvTextString] = useState()  
    const [vExpandRowIcon, setvExpandRowIcon] = useState("+")  
    const [vHeadRowKey, setvHeadRowKey] = useState()  
    const [vTextRowKey, setvTextRowKey] = useState()  



    useEffect(() => {


      },[])  

    useEffect(() => {

          //console.log("SBSMNewsAdminRow.useEffect[vSBSMNewsArticle].vSBSMNewsArticle:  ",vSBSMNewsArticle) 
          setErrorMessage("")

          if (vSBSMNewsArticle !== undefined) {
            setvHeadRowKey(Date.now())
            setvTextString(vSBSMNewsArticle.TextString)
          }          

      },[vSBSMNewsArticle])     
      
      

      useEffect(() => {

        setErrorMessage("")

        if (vTextString !== undefined) {
        
          //console.log("SBSMNewsAdminRow.useEffect[vTextString].vTextString:  ",vTextString) 

          setvTextRowKey(Date.now())
        }          

    },[vTextString])     
    
    
    function SBSMNewsAdminRow_Delete_Row(event,data) {
      try {

           // //console.log("SBSMNewsAdminRow.SBSMNewsAdminRow_Delete_Row.Users[0].UserId    :  ",Users[0].UserId    ) 

            if (Users === "edwardhawke@rhyteit.com") {
          
                var today = new Date();

                if (vSBSMNewsArticle.SBSMStatus !== "New") {

                  const DeleteSBSMNewsArticle = {
                    id: vSBSMNewsArticle.id,
                    UserId: vSBSMNewsArticle.UserId,
                    _version: vSBSMNewsArticle._version
                  }
                  


                  const ReturnMessage = client.graphql({
                    query: deleteSBSMNews,
                    variables: {
                      input: DeleteSBSMNewsArticle
                    }
                  });  

                }
              
                setvSBSMNewsArticle()
                ErrorMessageStr = "Item deleted at: " + today.toLocaleString("en-US")
                setErrorMessage(ErrorMessageStr)       
                  
          } else {

            ErrorMessageStr = "You do not have permission to delete this item"
            setErrorMessage(ErrorMessageStr)  

          }
      
          } catch (err) { 

          ErrorMessageStr = "SBSMNewsAdminRow.SBSMNewsAdminRow_Delete_Row.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               
      
    }   


   async function SaveSBSMNewsAdminRow(pEvent, pStatus) {
      try {           
          var today = new Date();
          setErrorMessage("")
          
          var vUpdateDateTime = new Date().toISOString()


          vSBSMNewsArticle.UpdateDateTime = vUpdateDateTime       
          vSBSMNewsArticle.UpdateName = Users   
          vSBSMNewsArticle.SBSMStatus = pStatus

          let ArticleItem = {
            "id": vSBSMNewsArticle.id,
            "UserId": vSBSMNewsArticle.UserId,
            "QueryTerm": vSBSMNewsArticle.QueryTerm,
            "InfoType": vSBSMNewsArticle.InfoType,
            "Title": vSBSMNewsArticle.Title,
            "ImageURL": vSBSMNewsArticle.ImageURL,
            "ActualURL": vSBSMNewsArticle.ActualURL,
            "URL": vSBSMNewsArticle.URL,
            "Source": vSBSMNewsArticle.Source,
            "PubDate": vSBSMNewsArticle.PubDate,
            "SBSMStatus": vSBSMNewsArticle.SBSMStatus,
            "Penalty": vSBSMNewsArticle.Penalty,
            "UpVotes": vSBSMNewsArticle.UpVotes,
            "DownVotes": vSBSMNewsArticle.DownVotes,
            "AgeInHours": vSBSMNewsArticle.AgeInHours,
            "Score": vSBSMNewsArticle.Score,
            "IncludeInNewsLetter": vSBSMNewsArticle.IncludeInNewsLetter,
            "ArticleTextCollected": vSBSMNewsArticle.ArticleTextCollected,
            "ArticleAnalyzed": vSBSMNewsArticle.ArticleAnalyzed,
            "ArticleTrendAnalyzed": vSBSMNewsArticle.ArticleTrendAnalyzed,
            "TwitterPostCreated": vSBSMNewsArticle.TwitterPostCreated,
            "ArticlePublished": vSBSMNewsArticle.ArticlePublished,
            "Category": vSBSMNewsArticle.Category,
            "PeopleMentionedInTheArticle": vSBSMNewsArticle.PeopleMentionedInTheArticle,
            "ArticleSummary": vSBSMNewsArticle.ArticleSummary,
            "TextString": vSBSMNewsArticle.TextString,
            "ArticleURL": vSBSMNewsArticle.ArticleURL,
            "ArticleHtml": vSBSMNewsArticle.ArticleHtml,
            "ArticleS3Key": vSBSMNewsArticle.ArticleS3Key,
            "TwitterPost": vSBSMNewsArticle.TwitterPost,
            "TrendImpact": vSBSMNewsArticle.TrendImpact,
            "TrendImpactExplanation": vSBSMNewsArticle.TrendImpactExplanation,
            "TrendDemographics": vSBSMNewsArticle.TrendDemographics,
            "TrendDuration": vSBSMNewsArticle.TrendDuration,
            "TrendDurationExplanation": vSBSMNewsArticle.TrendDurationExplanation,
            "TrendSentiment": vSBSMNewsArticle.TrendSentiment,
            "TrendSentimentExplanation": vSBSMNewsArticle.TrendSentimentExplanation,
            "TrendDrivers": vSBSMNewsArticle.TrendDrivers,
            "TrendRisk": vSBSMNewsArticle.TrendRisk,
            "TrendRiskExplanation": vSBSMNewsArticle.TrendRiskExplanation,
            "Blocked": vSBSMNewsArticle.Blocked,
            "CreationName": vSBSMNewsArticle.CreationName,
            "UpdateName":vSBSMNewsArticle.UpdateName,
            "CreationDateTime": vSBSMNewsArticle.CreationDateTime,
            "UpdateDateTime": vSBSMNewsArticle.UpdateDateTime
          }

            //console.log("SBSMNewsAdminRow.SaveSBSMNewsAdminRow.ArticleItem:  ",ArticleItem) 
            
            const ReturnMessage = await client.graphql({
              query: updateSBSMNews,
              variables: {
                input: ArticleItem
              }              
            });  

            //console.log("SBSMNewsAdminRow.SaveSBSMNewsAdminRow.ReturnMessage.data.updateSBSMNews:  ",ReturnMessage.data.updateSBSMNews) 

            ErrorMessageStr = "Article saved at: " + today.toLocaleString("en-US")


            setvSBSMNewsArticle(ReturnMessage.data.updateSBSMNews) 
            setErrorMessage(ErrorMessageStr)
     

        } catch (err) { 
          ErrorMessageStr = ("SBSMNewsAdminRow.SaveSBSMNewsAdminRow.Error: ", err, err.stack)
          console.error(ErrorMessageStr)
          setErrorMessage(ErrorMessageStr)
      }        
    }    

    async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        //console.log("SBSMNewsAdminRow.ExpandRow.pExpandRowTmp: " ,pExpandRowTmp);
        setvExpandRow(!pExpandRowTmp)

        setvExpandRowIcon(!pExpandRowTmp === true ? "-" : "+")


          } catch (err) { 

          ErrorMessageStr = "SBSMNewsAdminRow.ExpandRow.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }   

      function Field_handleChange(event, data, pButtonTitle) {
        try {
  
          //console.log("SBSMNewsAdminRow.Field_handleChange.pButtonTitle: " ,pButtonTitle);
  
          let vTmp = event.target.value
        
  
          if (pButtonTitle === "vTextString") {
            setvTextString(vTmp)
          } 
          
  
        } catch (err) {
          RhyteItLogging(
            Users,
            "SBSMNewsAdminRow",
            "Field_handleChange",
            err.toString(),
            '{"ERROR": "' + err.toString() + '"}',
            0
          );
        }
      }      

      async function GetSBSMNewsArticle() {
        try {
          setErrorMessage("");
          //console.log("BlogCategoryPostHeader.getSBSMNews.vBlogPost: ", vBlogPost);
          let variables = {
            id: vSBSMNewsArticle["id"],
            UserId: vSBSMNewsArticle["UserId"],
            };
    
          const getSBSMNewsData = await client.graphql({
            query: getSBSMNews,
            variables: variables,
          });

          if (getSBSMNewsData.data.getSBSMNews !== undefined) {
    
            //console.log("SBSMNewsAdminRow.getSBSMNews.getSBSMNews.data.getSBSMNews.items: ", getSBSMNewsData.data.getSBSMNews);
            const SBSMNewsAdminRow1 =
            getSBSMNewsData.data.getSBSMNews;
            setvSBSMNewsArticle(SBSMNewsAdminRow1)
          } 
    
    
    
        } catch (err) {
  
          console.error("SBSMNewsAdminRow.getSBSMNews.Error.message:  ", err); 
          console.error("SBSMNewsAdminRow.getSBSMNews.Error.message:  ", err["errors"][0]["message"].toString()); 
          
          RhyteItLogging(
            "NA",
            "SBSMNewsAdminRow",
            "getSBSMNews",
            err["errors"][0]["message"].toString(),
            '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
            0
          );  
        }
      }         
            

  return (
    <div>
      {vSBSMNewsArticle !== undefined && (
      <TableBody className={styles.TableBody}   key={vHeadRowKey}>         
            <TableRow  className={styles.ActionRow}  >         
              <TableCell className={styles.TableCellActions}> 
              <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      onClick={(e) => ExpandRow(vExpandRow)}
                    >
                   {vExpandRowIcon}
                </Button> 
                </TableCell>  
                <TableCell  className={styles.TableCellText} >   
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Save blog post"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => SaveSBSMNewsAdminRow(e, "UPDATED")}
                      
                    >
                    Save
                </Button>    
                </TableCell>  
                <TableCell  className={styles.TableCellText} > 
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Analyze for trend information"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => SaveSBSMNewsAdminRow(e, "ANALYZE")}
                      
                    >
                    Analyze
                </Button> 
                </TableCell>  
                <TableCell  className={styles.TableCellText} > 
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Analyze for trend information"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => SaveSBSMNewsAdminRow(e, "PUBLISH")}
                      
                    >
                    Publish
                </Button> 
                </TableCell>  
                <TableCell  className={styles.TableCellText} >      
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Analyze for trend information"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => SBSMNewsAdminRow_Delete_Row(e, vSBSMNewsArticle)}
                      
                    >
                    Delete
                </Button>   
                </TableCell>  
                <TableCell  className={styles.TableCellText} >   
                <Button
                      loadingText=""
                      ariaLabel=""
                      title="Refresh"
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => GetSBSMNewsArticle(e)}
                      
                    >
                    Refresh
                </Button>       
              </TableCell>                                 
              </TableRow>      
              <TableRow  className={vTextString === null ? styles.TableRowBackgroundTitle : vSBSMNewsArticle.ImageURL === "https://news.rhyteit.com/RhyteItLogo.png" ? styles.TableRowImageMissing : vSBSMNewsArticle.ImageURL === null ? styles.TableRowImageMissing : styles.TextStringPresent }>                 
              <TableCell  className={styles.TableCellText} >                  
                  <Link className={styles.Link} href={vSBSMNewsArticle.URL} isExternal={true} colspan="3">
                  {vSBSMNewsArticle.Title}
                  </Link>   
              </TableCell>                 
              <TableCell  className={styles.TableCellStatus} colspan="1">                  
                  <Text className={styles.Link}>
                  {vSBSMNewsArticle.SBSMStatus}
                  </Text>   
              </TableCell>       
              <TableCell  className={styles.TableCellPubDate} colspan="2">                  
                  <Link className={styles.Link} href={vSBSMNewsArticle.URL} isExternal={true} colSpan="1"> 
                    {new Date(vSBSMNewsArticle.PubDate).toLocaleString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", hourCycle: "h23"  }).replace(",", "")}
                  </Link>   
              </TableCell>          
              <TableCell  className={styles.TableCellPubDate} colspan="3">                  
                    <Text className={styles.Link}>           
                    {new Date(vSBSMNewsArticle.UpdateDateTime).toLocaleString("en-CA", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", hourCycle: "h23"  }).replace(",", "")}
                  
                    </Text>   
              </TableCell>                               
            </TableRow> 
        </TableBody>
      )}
        
      { vSBSMNewsArticle !== undefined && vExpandRow === true &&  ( 
          
      <TableBody className={styles.TableBody} key={vTextRowKey}>              
            <TableRow  className={styles.SubRowDiv} >         
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        ID
                        </Text>                
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowHeader}>
                        {vSBSMNewsArticle.id}
                        </Text>                  
              </TableCell>                      
              </TableRow>    
              <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Text String
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vTextString}
                        </Text>             
              </TableCell>                        
            </TableRow>       
              <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Category
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.Category}
                        </Text>                 
              </TableCell>                        
            </TableRow>       
              <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Mentions
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.PeopleMentionedInTheArticle}
                        </Text>                 
              </TableCell>                        
            </TableRow>          
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Trend Impact
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendImpact}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Explanation
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendImpactExplanation}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Trend Demographics
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendDemographics}
                        </Text>                 
              </TableCell>        
            </TableRow>    
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Trend Duration
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendDuration}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Explanation
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendDurationExplanation}
                        </Text>                 
              </TableCell>                        
            </TableRow>    
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Trend Sentiment
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendSentiment}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Explanation
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendSentimentExplanation}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Trend Drivers
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendDrivers}
                        </Text>                 
              </TableCell>                        
            </TableRow>       
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Trend Risk
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendRisk}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Explanation
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TrendRiskExplanation}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Twitter Post
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.TwitterPost}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Article Title
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ArticleTitle}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Article Intro
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ArticleIntro}
                        </Text>                 
              </TableCell>                        
            </TableRow>    
              <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Article Key words
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ArticleKeywords}
                        </Text>                 
              </TableCell>                        
            </TableRow>            
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Article URL
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ArticleURL}
                        </Text>                 
              </TableCell>                        
            </TableRow>         
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Article S3Key
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ArticleS3Key}
                        </Text>                 
              </TableCell>                        
            </TableRow>              
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Article Html Body
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ArticleHtmlBody}
                        </Text>                 
              </TableCell>                        
            </TableRow>              
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Image S3 Key
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ArticleImageS3Key}
                        </Text>                 
              </TableCell>                        
            </TableRow>             
            <TableRow  className={styles.SubRowDiv} key={vSBSMNewsArticle.id}>                                  
              <TableCell className={styles.TableCellDetailParameter} colspan="1">
                        <Text className={styles.RowHeader}>
                        Image URL
                        </Text>               
              </TableCell>     
              <TableCell className={styles.TableCellDetailValue} colspan="6">
                        <Text className={styles.RowDetail}>
                        {vSBSMNewsArticle.ImageURL}
                        </Text>                 
              </TableCell>                        
            </TableRow>        
          </TableBody>
        )
      }
      {
        vSBSMNewsArticle !== undefined && ErrorMessage.length > 0 &&   
        <TableBody className={styles.TableBody}>            
          <TableRow  className={styles.ErrorMessageRow}>              
            <TableCell 
              colSpan="3">      
              {ErrorMessage}
            </TableCell>               
          </TableRow>  
          </TableBody>
      } 
    </div>
  )
}

export default SBSMNewsAdminRow;