
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {getBlog  }  from '../../../graphql/queries'
  import {    
    createBlog,
    updateBlog,
    deleteBlog
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './RhyteItTextField.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function RhyteItTextField(
  props
) {       

  RhyteItTextField.propTypes = {
    Blog: PropTypes.object,
    RhyteItField: PropTypes.string,
    FieldValue: PropTypes.string,
    RhyteItFieldLabel: PropTypes.string,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlog, setvBlog] = useState(props.Blog) 
    const [vRhyteItField, setvRhyteItField] = useState(props.RhyteItField) 
    const [vRhyteItFieldLabel, setRhyteItFieldLabel] = useState(props.RhyteItFieldLabel) 
    const [vFieldValue, setvFieldValue] = useState(props.FieldValue) 
    

    const [vUpdateKey, setvUpdateKey] = useState() 

    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    
  
        


      useEffect(() => {

        console.log("RhyteItTextField.useEffect[].props.RhyteItField:  ",props.RhyteItField) 
        console.log("RhyteItTextField.useEffect[].props.FieldValue:  ",props.FieldValue) 

      },[])  

      useEffect(() => {
      //console.log("RhyteItTextField.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("RhyteItTextField.useEffect[vBlog].vBlog: " ,vBlog);

        if (vBlog !== undefined) {

          
        
        }

      }, [vBlog]);   

      useEffect(() => {

        //console.log("RhyteItTextAreaFieldJSON.useEffect[vBlog].vBlog: " ,vBlog);
 
         if (vFieldValue !== undefined) {
 
          setvUpdateKey(Date.now)
         
         }
 
       }, [vFieldValue]);        
            
      
   
      async function UpdateRecord(pEvent, pStatus) {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")

            
            console.log("RhyteItTextField.UpdateRecord.pStatus: ",pStatus)
            console.log("RhyteItTextField.UpdateRecord.vRhyteItField: ",vRhyteItField)
            
            var vUpdateDateTime = new Date().toISOString()
            let vFieldNameTmp = vRhyteItField["FieldName"]

            let BlogUpdateItem = {
              "id": vBlog["id"],
              [vRhyteItField]: vFieldValue,
              "BlogStatus": pStatus,
              "UpdateName": Users,
              "UpdateDateTime": vUpdateDateTime
            }

            
            console.log("RhyteItTextField.UpdateRecord.BlogUpdateItem: ",BlogUpdateItem)
            
              let updateBlogResponse = await client.graphql({                    
                      query: updateBlog,
                      variables:{input: BlogUpdateItem}            
                }
              )
            
              console.log("RhyteItTextField.UpdateRecord.BlogUpdateItem: ",BlogUpdateItem)
    
            console.log("RhyteItTextField.SaveBlogEditionToSubscriber.updateBlogResponse: ",updateBlogResponse)
    

            setvBlog(updateBlogResponse.data.updateBlog)        
            
          } catch (err) { 
            RhyteItLogging(Users, "RhyteItTextField", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("RhyteItTextField.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    
      

    function Field_handleChange(event, data, pFieldName) {
      try {


        let vTmp = event.target.value

        setvFieldValue(vTmp)

      } catch (err) {
        RhyteItLogging(
          Users,
          "RhyteItTextField",
          "vPrompt_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }            
    

      return (          
        
                         
              <TableRow  className={styles.TableRowActive}  key={vUpdateKey}>  
                <TableRow  className={styles.TableRowActive}  key={vUpdateKey}>      
                <TableCell className={styles.TableCellLeftAlign} colspan="5"> 
                    <TextField
                        className={styles.TextField}
                        name={vRhyteItField}
                        placeholder={vRhyteItFieldLabel}
                        defaultValue={vFieldValue}
                        onChange={(e) => Field_handleChange(e, vFieldValue, RhyteItTextField)}
                      />                  
                    </TableCell>  
                </TableRow>
                <TableRow  className={styles.TableRowActive}  key={vUpdateKey}> 
                <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => UpdateRecord(e, "UPDATED")}
                      
                    >
                    Save
                </Button> 
              </TableCell>  
            <TableCell  className={styles.TableCellLeftAlign}>  
              <Text  className={styles.TextUpdate}>
              {new Date(vBlog.UpdateDateTime).toISOString().slice(0, 10) + " " + new Date(vBlog.UpdateDateTime).toISOString().slice(11, 16)}
              </Text>                           
            </TableCell>                                                                        
            </TableRow>                                                    
              </TableRow>
                  
                    
    )
  }
export default RhyteItTextField;

