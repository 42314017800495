
import React, { useState , useEffect, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  Button
  } from '@aws-amplify/ui-react'; 
import SBSMParameterRow from './SBSMParameterRow';  
import styles from './SBSMNewsManager.module.css';
import {GetGroupRoleAccess, RhyteItLogging, ReturnUserToLandingPage} from '../../BusinessLogic/UserManagement';
import { UserContext, CognitoGroupContext} from '../../BusinessLogic/Hooks/UserContext';


const SBSMNewsManager = (props) => {       

  SBSMNewsManager.propTypes = {
      SBSMManagerParameters: PropTypes.array,
      RhyteItUser: PropTypes.array
      };

    let debugConsoleOutPut = true

    const {Users, setUsers} = useContext(UserContext);

    const [updatedKey, setupdatedKey] = useState(Date.now())  
    const [SBSMManagerParameters, setSBSMManagerParameters] = useState(props.SBSMManagerParameters)
    var ErrorMessageStr = ""   
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  

    useEffect(() => {

        console.log("SBSMNewsManager.useEffect[].props.SBSMParameters:  ",props.SBSMManagerParameters) 


      },[])  

      useEffect(() => {

        //console.log("SBSMNewsManager.useEffect[].SBSMManagerParameters:  ",SBSMManagerParameters) 
  
      },[SBSMManagerParameters])    
      
  

  return ( 
    <div className={styles.Background}>


      <Table className={styles.RhyteItTable}   highlightOnHover={true}> 
              {
                SBSMManagerParameters !== undefined &&
                SBSMManagerParameters.map((SBSMParameter) => (         
                <SBSMParameterRow  key={SBSMParameter.id} SBSMParameter={SBSMParameter}/>)) 
              }                    
        </Table>
      </div> 
    
  )
}

export default SBSMNewsManager;


