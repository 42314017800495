
import React, { useState , useEffect, useRef, useContext} from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Button,
  SelectField,
  Flex,
  Autocomplete, Loader,
  Text
  } from '@aws-amplify/ui-react'; 
import NewsArticle from './NewsArticle';
import HeadlineArticle from './HeadlineArticle';

import { generateClient } from 'aws-amplify/api';
import {
  useInView,
  useScroll,
  useTransform,
  motion,
  useAnimation,
} from "framer-motion";
import styles from './NewsEntries.module.css';
import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
import {
  listSBSMNews,
  listSBSMSubReddits
} from "../../../graphql/queries";
import {
  createNewsArticles,
  deleteNewsArticles
} from "../../../graphql/mutations";


const NewsEntries = (props) => {       

  NewsEntries.propTypes = {
      News: PropTypes.object,
      SBSMNewsFilter: PropTypes.object,
      SearchTerm: PropTypes.string,
      PageTitle: PropTypes.string,
      PageSubCategoryTitles: PropTypes.array,
      NewsArticles: PropTypes.object,
      CellPhone:  PropTypes.bool
      };

    let debugConsoleOutPut = true
  

    const [vNews, setvNews] = useState(props.News)   
    const [vSearchTerm, setvSearchTerm] = useState(props.SearchTerm); 
    const [vCellPhone, setvCellPhone] = useState(props.CellPhone); 
    
    var ErrorMessageStr = ""
    const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)  
    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);
    const client = generateClient();
    
    const [vNewsArticles, setvNewsArticles] = useState(props.NewsArticles)   

    
    const [ShowTable, setShowTable] = useState(false); 
    const [nextToken, setnextToken] = useState(undefined) 
    const [IsLoading, setIsLoading] = useState(false)
    const [vArticlesReturned, setvArticlesReturned] = useState()     
    const [vSBSMNewsFilter, setvSBSMNewsFilter] = useState(props.SBSMNewsFilter);     
    const [vViewArticles, setvViewArticles] = useState(false)

    const [vPageTitle, setvPageTitle] = useState(props.PageTitle);  
    const [vPageSubCategoryTitles, setvPageSubCategoryTitles] = useState(props.PageSubCategoryTitles);       
    

    
    useEffect(() => {
      //console.log("NewsEntries.useEffect[].props.News: " ,props.News);
     //console.log("NewsEntries.useEffect[].props.SBSMNewsFilter: " ,props.SBSMNewsFilter);
      //console.log("NewsEntries.useEffect[].props.PageSubCategoryTitles: " ,props.PageSubCategoryTitles);
 

    },[])  

    useEffect(() => {
       if (isInView) {
         mainControls.start("visible");
       }
     }, [isInView]);

    useEffect(() => {
      //console.log("NewsEntries.useEffect[vNews].vSearchTerm: " ,vSearchTerm);
      if (vNews !== undefined && vSearchTerm === undefined) {

        setvNewsArticles()
        //GetNewsArticles()

      }
    }, [vNews]);

    useEffect(() => {

      const fetch1 = async () => {
        

        try {

              let vNewsArticlesData = null

              if(nextToken !== null && nextToken !== undefined) {

                
                setIsLoading(true)

                  let variables = {
                    nextToken,
                    filter: vSBSMNewsFilter
                  };
                  
      
                  const vNewsArticlesData  = await client.graphql({
                    query: listSBSMNews,
                    variables: variables
                  });     

                
                //console.log("NewsEntries.fetch1.vNewsArticlesData.RETURN:  ",vNewsArticlesData.data.listSBSMNews.items.length)


                if (vNewsArticlesData.data.listSBSMNews.items.length > 0) {

                    let vvNewsArticlesDataTmp = vNewsArticles   

                    if (vvNewsArticlesDataTmp !== undefined) {
          
                        vNewsArticlesData.data.listSBSMNews.items.forEach(item => 
                          {   
                        
                            if ([...vvNewsArticlesDataTmp].filter(Content => item.id === Content.id)["length"] === 0) {  
                              vvNewsArticlesDataTmp.push(item) 
                            }
      
                          })

                        setvNewsArticles(vvNewsArticlesDataTmp)  

                    } else {

                      setvNewsArticles(vNewsArticlesData.data.listSBSMNews.items)  

                    }

                    if (vNewsArticlesData.data.listSBSMNews.nextToken !== null ) {
                      //console.log("NewsEntries.fetch1.vNewsArticlesData.data.listSBSMNews.nextToken.valid1:  ",vNewsArticlesData.data.listSBSMNews.nextToken.substring(0, 4))
                      
                      if (vNewsArticlesData.data.listSBSMNews.nextToken !== nextToken) {
                        setnextToken(vNewsArticlesData.data.listSBSMNews.nextToken)    
                      }

                    } else if (vNewsArticlesData.data.listSBSMNews.nextToken === null){          
                
                      //console.log("NewsEntries.fetch1.vToken.null:  ",vNewsArticlesData.data.listSBSMNews.nextToken)
                      setnextToken()
                      setIsLoading(false)  
                    }  
                      
                } else if (vNewsArticlesData.data.listSBSMNews.items.length === 0) {
                
                  if (vNewsArticlesData.data.listSBSMNews.nextToken !== null){                  
                    //console.log("NewsEntries.fetch1.vNewsArticlesData.data.listSBSMNews.nextToken.valid2:  ",vNewsArticlesData.data.listSBSMNews.nextToken.substring(0, 4))
                    
                    if (vNewsArticlesData.data.listSBSMNews.nextToken !== nextToken) {
                      setnextToken(vNewsArticlesData.data.listSBSMNews.nextToken)    
                    }
                        
    
                  } else if (vNewsArticlesData.data.listSBSMNews.nextToken === null){                  
                    setnextToken()
                    setIsLoading(false)         
    
                  }
              }
              
              } else {setIsLoading(false) }
  
        } catch (err) {
          
            console.error("NewsEntries.useEffect.[nextToken].fetch1.Error: ", err, err.stack)

        } 
      }
    
      fetch1()

    }, [nextToken])    
    
    useEffect(() => {


      //console.log("NewsEntries.useEffect[IsLoading].IsLoading: ",IsLoading);
      //console.log("NewsEntries.useEffect[IsLoading].vNewsArticles: ",vNewsArticles);
      //console.log("NewsEntries.useEffect[IsLoading].nextToken: ",nextToken); 

      if (vNewsArticles !== undefined && IsLoading === false && nextToken === undefined) {

        let vArticlesSorted = SortArticlesByDate(vNewsArticles)
        setvNewsArticles(vArticlesSorted)
        setvViewArticles(true)

      }

      
      if (IsLoading === false && nextToken === undefined) {
        setvArticlesReturned(vNewsArticles === undefined ? 0 : vNewsArticles.length)
      }

    }, [IsLoading]);  

    useEffect(() => {
      
        //console.log("NewsEntries.useEffect[vArticlesReturned].vArticlesReturned: ",vArticlesReturned); 

    }, [vArticlesReturned]);       

    useEffect(() => {
      
      //console.log("NewsEntries.useEffect[vSearchTerm].vSearchTerm: " ,vSearchTerm);

      if (vSearchTerm !== undefined) {

        setvNewsArticles()
        SearchNewsArticles()

       
      }

    }, [vSearchTerm]);  

    useEffect(() => {
      

    }, [vSBSMNewsFilter]);      

    
    
    

    function SortArticlesByDate(pArticles) {
          pArticles.sort(GFG_sortFunction);
          return pArticles
      //    //console.log(JSON.stringify(pEmails));
    }

    function GFG_sortFunction(a, b) {
        let dateA = new Date(a.PubDate).getTime();
        let dateB = new Date(b.PubDate).getTime();
        return dateA < dateB ? 1 : -1;
    };    



     async function GetNewsArticles() {
      try {
        setErrorMessage("");
        
        //console.log("NewsEntries.GetNewsArticles.vNews: ", vNews);
        let variables = {
            filter: {
              SBSMManagerId: {
                eq: vNews["id"],
              },
              ArticlePublished: {
                eq: true,
              },
            },
          };
  
        const listSBSMNewsData2 = await client.graphql({
          query: listSBSMNews,
          variables: variables,
        });
  
        //console.log("NewsEntries.GetNewsArticles.listSBSMNews.data.listSBSMNews.items.length : ", listSBSMNewsData2.data.listSBSMNews.items.length );
  
        if (listSBSMNewsData2.data.listSBSMNews.items.length > 0) {
          //console.log("listSBSMNewsData2.data.listSBSMNews.items.length: ",listSBSMNewsData2.data.listSBSMNews.items.length);
           
          setvNewsArticles(listSBSMNewsData2.data.listSBSMNews.items)    
         
          if (listSBSMNewsData2.data.listSBSMNews.nextToken !== null ) {
            //console.log("NewsEntries.GetArticles.listSBSMNewsData2.data.listSBSMNews.nextToken.valid:  ",listSBSMNewsData2.data.listSBSMNews.nextToken.substring(0, 4))
            setnextToken(listSBSMNewsData2.data.listSBSMNews.nextToken)  

          } 
        
        }  
        
        if (listSBSMNewsData2.data.listSBSMNews.nextToken !== null){                  
            //console.log("NewsEntries.GetArticles.listSBSMNewsData2.data.listSBSMNews.nextToken.valid:  ",listSBSMNewsData2.data.listSBSMNews.nextToken.substring(0, 4))
            setnextToken(listSBSMNewsData2.data.listSBSMNews.nextToken)        

          } 

         else if (listSBSMNewsData2.data.listSBSMNews.nextToken === null){          
      
            //console.log("NewsEntries.GetArticles.vToken.null:  ",listSBSMNewsData2.data.listSBSMNews.nextToken)
            setIsLoading(false)  
          }    
  
  
  
      } catch (err) {

        console.error("NewsEntries.GetNewsArticles.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          "NA",
          "NewsEntries",
          "GetNewsArticles",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        


      }
    }
  
    async function SearchNewsArticles() {
      try {
        setErrorMessage("");
        
        //console.log("NewsEntries.SearchNewsArticles.vNews: ", vNews);
        //console.log("NewsEntries.SearchNewsArticles.vSearchTerm: ", vSearchTerm);
        let variables = {
            filter: {
              News: {
                eq: vNews["id"],
              },
              TextString: {
                contains: vSearchTerm,
              },
            },
          };
  
        const listSBSMNewsData2 = await client.graphql({
          query: listSBSMNews,
          variables: variables,
        });
  
        //console.log("NewsEntries.SearchNewsArticles.listSBSMNews.data.listSBSMNews.items.length : ", listSBSMNewsData2.data.listSBSMNews.items.length );
  
        if (listSBSMNewsData2.data.listSBSMNews.items.length > 0) {
          const NewsEntries1 =
            listSBSMNewsData2.data.listSBSMNews.items;
            let vSortedNewsArticles = SortNewsArticlesByDate(NewsEntries1)
            setvNewsArticles(vSortedNewsArticles)
        } 
  
  
  
      } catch (err) {

        console.error("NewsEntries.SearchNewsArticles.Error.message:  ", err["errors"][0]["message"].toString()); 
        
        RhyteItLogging(
          "NA",
          "NewsEntries",
          "SearchNewsArticles",
          err["errors"][0]["message"].toString(),
          '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
          0
        );
        


      }
    }

    function SortNewsArticlesByDate(pNewsPostEditionEmail) {
      pNewsPostEditionEmail.sort(GFG_sortFunction);
      return pNewsPostEditionEmail;
      //console.log(""JSON.stringify(pEmails));
    }
  
    function GFG_sortFunction(a, b) {
      let NewsPostCreationDateTimeA = a.CreationDateTime;
      let NewsPostCreationDateTimeB = b.CreationDateTime;
      return NewsPostCreationDateTimeA < NewsPostCreationDateTimeB ? 1 : -1;
    }




  return (   
    <section ref={ref} className = {styles.Section}>
      {vCellPhone === false && (  
        <Flex
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="flex-start"
            wrap="nowrap"
            gap="1rem"
            className={styles.FlexLeftColumn}>               
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              className={styles.ArticleRow}
            >         
            {
              vArticlesReturned !== undefined &&  (   

                vArticlesReturned > 0 && vNewsArticles.slice(0,1).map((vArticle) => (                      
                    (<HeadlineArticle   key={vArticle.id}  Article={vArticle}/>)        
                    ))
                  )
                }
            {
              vArticlesReturned !== undefined &&  (   

                vArticlesReturned > 0 && vNewsArticles.slice(1,3).map((vArticle) => (                      
                    (<NewsArticle   key={vArticle.id}  Article={vArticle}/>)           
                  ))
                )
              }    
                  
            </Flex>  
            <Flex
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="flex-start"
              wrap="wrap"
              gap="1rem"
              className={styles.ArticleSubRow}
            >         
            {
              vArticlesReturned !== undefined &&  (   

                vArticlesReturned > 0 && vNewsArticles.slice(3,vNewsArticles.length-1).map((vArticle) => (                      
                    (
                      <NewsArticle   key={vArticle.id}  Article={vArticle}/>)        
                    ))

              )
            }                
            </Flex>                      
      {IsLoading === true && (
        <Flex
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
          > 
          <Text
            className={styles.Text}>Loading...</Text>
            <Loader />    
        </Flex>
        )  
      }            
       
        </Flex>   
      )}   
      {vCellPhone === true && (  
        <Flex
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="nowrap"
            gap="1rem"
            className={styles.CellPhoneFlexColumn}>               
            {
              vArticlesReturned !== undefined &&  (   
                vArticlesReturned > 0 && vNewsArticles.map((vArticle) => (                      
                    (<NewsArticle   key={vArticle.id}  Article={vArticle}/>)           
                  ))
                )
              }   
      </Flex>   
      )}              
                 
    </section>
  )
}

export default NewsEntries;


