
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import {
    createBlog,
    updateBlog,
    deleteBlog
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './BlogMetaDataTable.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  import RhyteItTextField from './RhyteItTextField';  
  import RhyteItTextAreaFieldJSON from './RhyteItTextAreaFieldJSON';  
  
  
  
function BlogMetaDataTable(
  props
) {       

  BlogMetaDataTable.propTypes = {
    Blog: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlog, setvBlog] = useState(props.Blog) 
    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vBlogActive, setvBlogActive] = useState(false)  
    let [vFieldNameSelected, setvFieldNameSelected] = useState()  
    let [vLabelSelected, setvLabelSelected] = useState()  
    let [vFieldTypeSelected, setvFieldTypeSelected] = useState() 
    


    let vFieldButtons =  [
      {"FieldName" : "Blog", "LabelName" : "Blog", "FieldType" : "TextField"},
      {"FieldName" : "Description", "LabelName" : "Description", "FieldType" : "TextAreaField"},
      {"FieldName" : "BlogEmail", "LabelName" : "Email", "FieldType" : "TextField"},
      {"FieldName" : "RedditClientId", "LabelName" : "Reddit Client Id", "FieldType" : "TextField"},
      {"FieldName" : "RedditClientSecret", "LabelName" : "Reddit Client Secret", "FieldType" : "TextField"},
      {"FieldName" : "RedditUserName", "LabelName" : "Reddit UserName", "FieldType" : "TextField"},
      {"FieldName" : "RedditPassword", "LabelName" : "Reddit Password", "FieldType" : "TextField"},
      {"FieldName" : "RedditUserAgent", "LabelName" : "Reddit UserAgent", "FieldType" : "TextField"},
      {"FieldName" : "OpenAIapiKey", "LabelName" : "OpenAI api Key", "FieldType" : "TextField"},
      {"FieldName" : "OpenAIOrganization", "LabelName" : "OpenAI Organization", "FieldType" : "TextField"},
      {"FieldName" : "OpenAIProject", "LabelName" : "OpenAI Project", "FieldType" : "TextField"},
      {"FieldName" : "OpenAIModel", "LabelName" : "OpenAI Model", "FieldType" : "TextField"},
      {"FieldName" : "S3Bucket", "LabelName" : "S3 Bucket", "FieldType" : "TextField"},
      {"FieldName" : "BlogURL", "LabelName" : "Blog URL", "FieldType" : "TextField"},
      {"FieldName" : "Categories", "LabelName" : "Categories", "FieldType" : "TextAreaFieldJSON"},
    ]
        
      useEffect(() => {

        //console.log("BlogMetaDataTable.useEffect[].props.Blog:  ",props.Blog) 

      },[])  

      useEffect(() => {
      //console.log("BlogMetaDataTable.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("BlogMetaDataTable.useEffect[vBlog].vBlog: " ,vBlog);

        if (vBlog !== undefined) {
          setvBlogActive(vBlog["Active"])

        }

      }, [vBlog]);   
      
      useEffect(() => {
        //console.log("BlogMetaDataTable.useEffect[vExpandRow].vExpandRow: " ,vExpandRow);
        }, [vExpandRow]);     

      async function ExpandRow(pExpandRow) {
      try {
        let pExpandRowTmp = pExpandRow 
        console.log("BlogMetaDataTable.ExpandRow.FieldName: " ,pExpandRow["FieldName"]);
        console.log("BlogMetaDataTable.ExpandRow.LabelName: " ,pExpandRow["LabelName"]);
        console.log("BlogMetaDataTable.ExpandRow.FieldType: " ,pExpandRow["FieldType"]);
        console.log("BlogMetaDataTable.ExpandRow.vBlog);: " ,vBlog);
        setvFieldNameSelected(pExpandRow["FieldName"])
        setvLabelSelected(pExpandRow["LabelName"])
        setvFieldTypeSelected(pExpandRow["FieldType"])


          } catch (err) { 

          ErrorMessageStr = "BlogMetaDataTable.PlayEpisode.Error: " + err + " " + err.stack

          console.error(ErrorMessageStr); 
          
          setErrorMessage(ErrorMessageStr)         
        }               

      }     
     

      async function vBlogActiveChanged(e, data) {
        try {


            //console.log("Application.vBlogActiveChanged.data: ", data)
            //console.log("Application.vBlogActiveChanged.e: ", e)
            //console.log("Application.vBlogActiveChanged.vBlogActive: ", vBlogActive)

            setvBlogActive(vBlogActive===false ? true : false)
            
            //console.log("Application.vBlogActiveChanged.vBlogActive2: ", vBlogActive)

    } catch (err) { //console.log('error fetching Users')
                    console.error(err, err.stack); 
                }
    }          



      return (
        <TableBody>         
          <TableRow  className={styles.TableRowActive}  key={vBlog.CreationDateTime}>     
          <TableCell className={styles.TableCellLeftAlign} colspan="6"> 
          <Flex
               direction="row"
               justifyContent="flex-start"
               alignItems="center"
               alignContent="center"
               wrap="wrap"
               gap="1rem"
               className={styles.FlexRow}>   
          {
            vFieldButtons !== undefined && vFieldButtons.map((vFieldButton) => (                      
              (<Button   key={vFieldButton.FieldName}  
                          className={styles.ButtonSection}
                          onClick={(e) => ExpandRow(vFieldButton)}>{vFieldButton.LabelName}</Button>)        
              ))
          }            
                          
          </Flex>                     
                </TableCell>                  
              </TableRow>                           
                

          {
            vFieldTypeSelected === "TextField" &&  (                     
              <RhyteItTextField  key={vFieldNameSelected}  Users={Users} Blog={vBlog} RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vBlog[vFieldNameSelected]}/>
            )          
          }
          {
            vFieldTypeSelected === "TextAreaFieldJSON" &&  (                     
              <RhyteItTextAreaFieldJSON  key={vFieldNameSelected}  Users={Users} Blog={vBlog} RhyteItField={vFieldNameSelected} RhyteItFieldLabel={vLabelSelected} FieldValue={vBlog[vFieldNameSelected]}/>
            )          
          }
              
        {
          ErrorMessage.length > 0 &&             
            <TableRow  className={styles.ErrorMessageRow}>              
              <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>               
            </TableRow>  
        }               
        </TableBody>
      
    )
  }
export default BlogMetaDataTable;

