
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  { getBlogPosts }  from '../../../graphql/queries'
  import BlogCategoryPost from './BlogCategoryPost';
  import {
    createBlogPosts,
    updateBlogPosts,
    deleteBlogPosts
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './BlogCategoryPostHeader.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function BlogCategoryPostHeader(
  props
) {       

  BlogCategoryPostHeader.propTypes = {
    BlogPost: PropTypes.object,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlogPost, setvBlogPost] = useState(props.BlogPost) 
    
    const [vBlogPostRecord, setvBlogPostRecord] = useState() 
    const [vPrompt, setvPrompt] = useState() 
    const [vPromptName, setvPromptName] = useState() 

    const [vSubRedditListString, setvSubRedditListString] = useState() 

    
    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    const [vExpandRow, setvExpandRow] = useState(false)    
    let [vBlogPostActive, setvBlogPostActive] = useState(false)   
    const [vBlogPostHTMLString, setvBlogPostHTMLString] = useState()   
    const [vPostClicked, setvPostClicked] = useState(false)    
    
    
      useEffect(() => {

        //console.log("BlogCategoryPostHeader.useEffect[].props.BlogPost:  ",props.BlogPost) 

      },[])  

      useEffect(() => {
      //console.log("BlogCategoryPostHeader.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {


      }, [vBlogPost]);   

      useEffect(() => {

          //console.log("BlogCategoryPostHeader.useEffect[vPostClicked].vPostClicked: " ,vPostClicked);
  
          if (vPostClicked === true) {
  
            GetBlogPost()
  
          }
  
        }, [vPostClicked]);   
  
      



      async function GetBlogPost() {
        try {
          setErrorMessage("");
          
          //console.log("BlogCategoryPostHeader.GetBlogPosts.vBlogPost: ", vBlogPost);
          let variables = {
            id: vBlogPost["BlogPostId"],
            };
    
          const getBlogPostsPostsData2 = await client.graphql({
            query: getBlogPosts,
            variables: variables,
          });
    
          //console.log("BlogEntries.GetBlogPosts.getBlogPosts.data.getBlogPosts.items: ", getBlogPostsPostsData2.data.getBlogPosts);

          if (getBlogPostsPostsData2.data.getBlogPosts !== undefined) {
            const BlogEntries1 =
            getBlogPostsPostsData2.data.getBlogPosts;
              setvBlogPostRecord(BlogEntries1)
          } 
    
    
    
        } catch (err) {
  
          console.error("BlogEntries.GetBlogPosts.Error.message:  ", err); 
          console.error("BlogEntries.GetBlogPosts.Error.message:  ", err["errors"][0]["message"].toString()); 
          
          RhyteItLogging(
            "NA",
            "BlogEntries",
            "GetBlogPosts",
            err["errors"][0]["message"].toString(),
            '{"ERROR": "' + err["errors"][0]["message"].toString() + '"}',
            0
          );  
        }
      }      

      async function PostClickHandler(event, vBlogPost) {
        try {
            //console.log("BlogCategoryPosts.PostClickHandler.vBlogPost: ", vBlogPost );
            setvPostClicked(vPostClicked === true ? false : true);
                 
      } catch (err) {
        RhyteItLogging(
          "NA",
          "Blog",
          "BlogCategoryPosts",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
      }

      return (
        <Flex
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        alignContent="flex-start"
        wrap="nowrap"
        gap="1rem"
        className={styles.FlexLeftColumn}
        onClick={(e) => PostClickHandler(e, vBlogPost)}>     
           {
             vPostClicked === false && (
              <div>
                <Text className={styles.PostTitle}>{vBlogPost["Title"]}</Text>
                <Text className={styles.PostDate}>{new Date(vBlogPost["CreationDateTime"]).toLocaleDateString('en-US',{year: 'numeric',month: 'long',day: 'numeric'})}</Text>
              </div>
            )}         
            {
             vPostClicked === true && vBlogPostRecord !== undefined && (                                    
              <BlogCategoryPost key={vBlogPostRecord["id"]} BlogPost={vBlogPostRecord}/>   
            )}        
      </Flex>  
      
    )
  }
export default BlogCategoryPostHeader;

