
import React, { useState , useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Button,
  Image,
  TableBody,
  TableRow,
  TableCell,
  CheckboxField,
  TextAreaField,
  TextField,
  Text
  } from '@aws-amplify/ui-react';

  import  {  }  from '../../../graphql/queries'
  import {
    createBlogPrompts,
    updateBlogPrompts,
    deleteBlogPrompts
  } from "../../../graphql/mutations";
  import { generateClient } from 'aws-amplify/api';

  import { Link } from "react-router-dom";
  import {
    useInView,
    useScroll,
    useTransform,
    motion,
    useAnimation,
  } from "framer-motion";
  import styles from './AWSBedrockModelIdImage.module.css';  
  import {RhyteItLogging} from '../../../BusinessLogic/UserManagement';
  
function AWSBedrockModelIdImage(
  props
) {       

  AWSBedrockModelIdImage.propTypes = {
    BlogPrompt: PropTypes.object,
    Users: PropTypes.string,
    };

    const client = generateClient();
  
    const [ErrorMessage, setErrorMessage] = useState("")   
    let ErrorMessageStr = ""

    const [vBlogPrompt, setvBlogPrompt] = useState(props.BlogPrompt) 
    
    const [vAWSBedrockModelIdImage, setvAWSBedrockModelIdImage] = useState() 

    

    const [Users, setUsers] = useState(props.Users)   

    const mainControls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref);

    
  
        


      useEffect(() => {

        //console.log("AWSBedrockModelIdImage.useEffect[].props.BlogPrompt:  ",props.BlogPrompt) 

      },[])  

      useEffect(() => {
      //console.log("AWSBedrockModelIdImage.useEffect[isInView].props.scrollYProgress: " ,props.scrollYProgress);

        if (isInView) {
          mainControls.start("visible");
        }
      }, [isInView]);      

      useEffect(() => {

       //console.log("AWSBedrockModelIdImage.useEffect[vBlogPrompt].vBlogPrompt: " ,vBlogPrompt);

        if (vBlogPrompt !== undefined) {

          setvAWSBedrockModelIdImage(vBlogPrompt.AWSBedrockModelIdImage)

        }

      }, [vBlogPrompt]);   
      
   
      async function UpdateRecord(pEvent, pStatus) {
        
  
        try {           
            var today = new Date();
            setErrorMessage("")
            
            var vUpdateDateTime = new Date().toISOString()

            let BlogPromptUpdateItem = {
              "id": vBlogPrompt["id"],
              "AWSBedrockModelIdImage": vAWSBedrockModelIdImage,
              "BlogPromptStatus": pStatus,
              "UpdateName": Users,
              "UpdateDateTime": vUpdateDateTime
            }
    
              let updateBlogPromptsResponse = await client.graphql({                    
                      query: updateBlogPrompts,
                      variables:{input: BlogPromptUpdateItem}            
                }
              )
            
              console.log("AWSBedrockModelIdImage.UpdateRecord.BlogPromptUpdateItem: ",BlogPromptUpdateItem)
    
            console.log("AWSBedrockModelIdImage.SaveBlogPromptEditionToSubscriber.updateBlogPromptsResponse: ",updateBlogPromptsResponse)
    

            setvBlogPrompt(updateBlogPromptsResponse.data.updateBlogPrompts)        
  
          } catch (err) { 
            RhyteItLogging(Users, "AWSBedrockModelIdImage", "Unsubscribe",  err.toString(),  "{\"ERROR\": \"" + err.toString() + "\"}", 0)
            console.error("AWSBedrockModelIdImage.Unsubscribe.Error:  ".err, err.stack); 
        }        
      }    
      

    function Field_handleChange(event, data, pFieldName) {
      try {


        let vTmp = event.target.value

        
        if (pFieldName === "vAWSBedrockModelIdImage") {
          setvAWSBedrockModelIdImage(vTmp)
        } 

      } catch (err) {
        RhyteItLogging(
          Users,
          "AWSBedrockModelIdImage",
          "vPrompt_handleChange",
          err.toString(),
          '{"ERROR": "' + err.toString() + '"}',
          0
        );
      }
    }            
    

      return (          
        
                         
              <TableRow  className={styles.TableRowActive}  key={vBlogPrompt.UpdateDateTime}>     
                <TableCell className={styles.TableCellLeftAlign} colspan="5"> 
                  
                <TextField
                    className={styles.TextField}
                    name="vAWSBedrockModelIdImage"
                    placeholder="AWS Bedrock Model Id Image"
                    defaultValue={vAWSBedrockModelIdImage}
                    onChange={(e) => Field_handleChange(e, vAWSBedrockModelIdImage, "vAWSBedrockModelIdImage")}
                  />                           
                </TableCell>  
                <TableCell className={styles.TableCellLeftAlign}> 
                <Button
                      loadingText=""
                      ariaLabel=""
                      className={styles.Button}
                      //onClick={(e) => UpdateRecord(e)}
                      onClick={(e) => UpdateRecord(e, "UPDATED")}
                      
                    >
                    Save
                </Button> 
            </TableCell>   
            {
          ErrorMessage.length > 0 &&  ( 
            <TableCell 
                colSpan="3">      
                {ErrorMessage}
              </TableCell>     
            )
          }                                                              
              </TableRow>
                  
                    
    )
  }
export default AWSBedrockModelIdImage;

