import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
 import styles from './NewsMenuIcon.module.css';  
import {
  Flex,
  Text,
  useAuthenticator,
Image
  } from '@aws-amplify/ui-react';
  import axios from 'axios';
  import {uuidv4} from "../../../BusinessLogic/UtilitiesManagement"; 
  
import  {createAudienceGeoInfo}  from '../../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';
import { UserContext, CognitoGroupContext} from '../../../BusinessLogic/Hooks/UserContext';
import {GetGroupRoleAccess, RhyteItLogging} from '../../../BusinessLogic/UserManagement';

  function NewsMenuIcon({
    NavBarMenuClicked,
    onClick
  }) {

  NewsMenuIcon.propTypes = {
      NavBarMenuClicked: PropTypes.bool,
      onClick: PropTypes.func
    };

    var vFirstTouch = true
    const client = generateClient();
    const {Users, setUsers} = useContext(UserContext);    
    const {CognitoGroup, setCognitoGroup} = useContext(CognitoGroupContext);  
  
useEffect(() => {


          
},[])   



  return (
                      <Flex
                              direction="column"
                              justifyContent="flex-start"
                              alignItems="stretch"
                              alignContent="flex-start"
                              wrap="nowrap"
                              className={styles.MenuItems}
                              onClick={onClick}
                            >        
                            <Image
                              alt=""
                              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLineGreenDark.svg"
                              objectFit="initial"
                              objectPosition="50% 50%"
                              className={styles.MenuItem}
                            />         
                            <Image
                              alt=""
                              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLineOrangeDark.svg"
                              objectFit="initial"
                              objectPosition="50% 50%"
                              className={styles.MenuItem}
                            />            
                            <Image
                              alt=""
                              src="https://rhyteiticons.s3.us-west-2.amazonaws.com/MenuGraphics/MenuLinePurpleDark.svg"
                              objectFit="initial"
                              objectPosition="50% 50%"
                              className={styles.MenuItem}
                            />                                  
                      </Flex> 
  )
}

export default NewsMenuIcon;