import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';

import {ErrorBoundary} from 'react-error-boundary'
import  { createEmailAccounts, updateEmailAccounts, deleteEmailAccounts}  from '../../graphql/mutations'
import { generateClient } from 'aws-amplify/api';


import { UserContext} from '../../BusinessLogic/Hooks/UserContext';
import { useNavigate, useLocation } from "react-router-dom";

import {
  Button ,
  Authenticator,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Card,
  Image,
  View,
  Flex,
  Text

} from '@aws-amplify/ui-react';
import styles from './RhyteItAccountDetail.module.css';
import styled from 'styled-components'


function ErrorFallback({error, resetErrorBoundary}) {
  ErrorFallback.propTypes = {
    error: PropTypes.string,
    resetErrorBoundary: PropTypes.string
  };
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

const LabelText = styled.div`
                        color: white;
                        font-size: 1.5em !important;
                        `;


const RhyteItAccountDetail = (props) => {      



      RhyteItAccountDetail.propTypes = {
              vRhyteItAccount: PropTypes.object,
              UserEmail: PropTypes.string
            };

        const client = generateClient();
        let debugConsoleOutPut = true
        let navigate = useNavigate(); 

        const location = useLocation();
        //const { vRhyteItAccount } = location.state;
        const { vRhyteItAccount } = location.state;


        var ErrorMessageStr = ""   
        const [ErrorMessage, setErrorMessage] = useState(ErrorMessageStr)   
        const [RedoImageChecked, setRedoImageChecked] = useState(vRhyteItAccount.RedoImage)  
        const [RecordUpdated, setRecordUpdated] = useState(false)  
        
 

        let {Users} = useContext(UserContext);   
        const [RhyteItAccount, setRhyteItAccount] = useState(props.vRhyteItAccount) 
        const [UserEmail, setUserEmail] = useState(props.UserEmail)  



 
        useEffect(() => {

       
      

        },[])   

        function EmailAccount_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.EmailAccount = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.PostTitle_handleChange.Error:  ".err, err.stack); 
          }        
        }      
        
        function FirstName_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.FirstName = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.FirstName_handleChange.Error:  ".err, err.stack); 
          }        
        }       
        
        
        function id_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.id = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.id_handleChange.Error:  ".err, err.stack); 
          }        
        }         
          

        function LastName_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.LastName = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.LastName_handleChange.Error:  ".err, err.stack); 
          }        
        }         
           
        function BirthMonth_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.BirthMonth = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.BirthMonth_handleChange.Error:  ".err, err.stack); 
          }        
        }       
        
        function BirthDay_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.BirthDay = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.BirthDay_handleChange.Error:  ".err, err.stack); 
          }        
        }             

        function BirthYear_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.BirthYear = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.BirthYear_handleChange.Error:  ".err, err.stack); 
          }        
        }    
        
        function ChromiumProfile_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.ChromiumProfile = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.ChromiumProfile_handleChange.Error:  ".err, err.stack); 
          }        
        }            

        function Location_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.Location = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.Location_handleChange.Error:  ".err, err.stack); 
          }        
        }      
        
        function SBSMNewsBio_handleChange(event, data) {
          try {              
              
              if (debugConsoleOutPut === true) {
            //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event.target.checked: ",event.target.checked)
             //   //console.log("RhyteItAccountDetail.Subscribed_handleChange.event: ",event)
              }
    
    
              RhyteItAccount.SBSMNewsBio = event.target.value
             // //console.log("RhyteItAccountDetail.Subscribed_handleChange.RhyteItAccount[elementPos].Subscribed: ",RhyteItAccount[elementPos].Subscribed)
    
              setRhyteItAccount(RhyteItAccount)   
    
     
    
            } catch (err) { 
              console.error("RhyteItAccountDetail.SBSMNewsBio_handleChange.Error:  ".err, err.stack); 
          }        
        }              

        async function SaveRhyteItAccount(event, data) {
          try {           
            
              setErrorMessage("")
              
              if (debugConsoleOutPut === true) {
                console.log("RhyteItAccountDetail.SaveRhyteItAccount.UserEmail: ",UserEmail)
               // //console.log("RhyteItAccountDetail.SaveRhyteItAccount.RhyteItAccount: ",RhyteItAccount)
                //console.log("RhyteItAccountDetail.SaveRhyteItAccount.event: ",event)
              }
    
           
    
              if (debugConsoleOutPut === true) {
                //console.log("RhyteItAccountDetail.SaveRhyteItAccount.new Date().toISOString(): ",new Date().toISOString())
              }
    
              var vUpdateDateTime = new Date().toISOString()
    
              if (debugConsoleOutPut === true) {
              // console.log("RhyteItAccountDetail.SaveRhyteItAccount.RhyteItAccount: ",RhyteItAccount)
             //   console.log("RhyteItAccountDetail.SaveRhyteItAccount.Users: ",Users)
                //console.log("RhyteItAccountDetail.SaveRhyteItAccount.RhyteItAccount.RespondedDate Compare: ",RhyteItAccount[elementPos].RespondedDate === "1970-01-01T00:00:00.000Z" )
             }
    
              RhyteItAccount.UpdateDateTime = vUpdateDateTime       
              RhyteItAccount.UpdateName = UserEmail     
              RhyteItAccount.RedoImage = RedoImageChecked   

              if (RhyteItAccount["ChromiumProfile"] === false || RhyteItAccount["ChromiumProfile"] === null) {
                RhyteItAccount["ChromiumProfile"] = ""
              } 
                  
              let vRhyteItAccountItem = {
                "id": RhyteItAccount.id,
                "UserId": RhyteItAccount.UserId,
                "Password": RhyteItAccount.Password,
                "FirstName": RhyteItAccount.FirstName,
                "LastName": RhyteItAccount.LastName,
                "Name": RhyteItAccount.Name,
                "NameNonSpaced": RhyteItAccount.NameNonSpaced,  
                "BirthMonth": RhyteItAccount.BirthMonth,
                "BirthDay": RhyteItAccount.BirthDay,
                "BirthYear": RhyteItAccount.BirthYear,
                "StartDate": RhyteItAccount.StartDate,
                "EndDate": RhyteItAccount.EndDate,
                "EmailAccountProvider": RhyteItAccount.EmailAccountProvider,
                "EmailAccount": RhyteItAccount.EmailAccount,
                "ChromiumProfile": RhyteItAccount.ChromiumProfile,
                "TwitterAccount": RhyteItAccount.TwitterAccount,
                "TwitterPassword": RhyteItAccount.TwitterPassword,
                "TwitterConsumerKey": RhyteItAccount.TwitterConsumerKey,
                "TwitterConsumerSecret": RhyteItAccount.TwitterConsumerSecret,
                "RedditAccount": RhyteItAccount.RedditAccount,
                "OnlyFansAccount": RhyteItAccount.OnlyFansAccount,
                "MediumAccount": RhyteItAccount.MediumAccount,
                "InstagramAccount": RhyteItAccount.InstagramAccount,
                "VerificationProvider": RhyteItAccount.VerificationProvider,
                "VerificationCode": RhyteItAccount.VerificationCode,
                "OpenAIOrgKey": RhyteItAccount.OpenAIOrgKey,
                "OpenAIapiKey": RhyteItAccount.OpenAIapiKey,
                "AccountStatus": RhyteItAccount.AccountStatus,
                "Prompt":RhyteItAccount.Prompt,
                "Location": RhyteItAccount.Location,
                "SBSMNewsBio": RhyteItAccount.SBSMNewsBio,                
                "Model":RhyteItAccount.Model,
                "ProfileImageURL": RhyteItAccount.ProfileImageURL,
                "MageSeed": RhyteItAccount.MageSeed,
                "Industry": RhyteItAccount.Industry,
                "ProcessGroup": RhyteItAccount.ProcessGroup,
                "RedoImage": RhyteItAccount.RedoImage,
                "CreationName": RhyteItAccount.CreationName,
                "UpdateName": UserEmail,
                "CreationDateTime": RhyteItAccount.CreationDateTime,
                "UpdateDateTime": RhyteItAccount.UpdateDateTime
            }

    
              if (debugConsoleOutPut === true) {
              
                console.log("RhyteItAccountDetail.SaveRhyteItAccount.vRhyteItAccountItem: ",vRhyteItAccountItem)
    
              }

              if (RhyteItAccount.AccountStatus === "NEW") {

                vRhyteItAccountItem["AccountStatus"] = "ACTIVE"

                const createEmailAccountsResponse = await client.graphql({
                  query: createEmailAccounts,
                  variables: {
                    input: vRhyteItAccountItem
                  }
                });  

                
                ErrorMessageStr = "Created"
                setRhyteItAccount(createEmailAccountsResponse.data.createEmailAccounts)
              }

            else if (RhyteItAccount.AccountStatus !== "NEW") {
    
              const updateEmailAccountsResponse = await client.graphql({
                query: updateEmailAccounts,
                variables: {
                  input: vRhyteItAccountItem
                }
              });  

              if (debugConsoleOutPut === true) {
              
                console.log("RhyteItAccountDetail.SaveRhyteItAccount.updateEmailAccountsResponse.data.updateEmailAccounts: ",updateEmailAccountsResponse.data.updateEmailAccounts)
    
              }
              
              setRhyteItAccount(updateEmailAccountsResponse.data.updateEmailAccounts)
              ErrorMessageStr = "Updated"
            }

              setRecordUpdated(true)
              setErrorMessage(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
         
    
            } catch (err) { 
              ErrorMessageStr = ("RhyteItAccountDetail.SaveRhyteItAccount.Error: ", err, err.stack)
              console.error(err)
              console.error(ErrorMessageStr)
              setErrorMessage(ErrorMessageStr)
          }        
        }     
        
      async  function DeleteRhyteItAccount(event,data) {
          try {
    

    
              const DeleteRhyteItAccounts = {
                id: RhyteItAccount.id,
                UserId: RhyteItAccount.UserId,
                _version: RhyteItAccount._version
              }
              
              const ReturnMessage = await client.graphql({
                query: deleteEmailAccounts,
                variables: {
                  input: DeleteRhyteItAccounts
                }
              });  

    
              setRhyteItAccount([])
    
              setErrorMessage("Deleted")         
                    
              } catch (err) { 
    
              ErrorMessageStr = "RhyteItAccountDetail.DeleteRhyteItAccount.Error: " + err + " " + err.stack
    
              console.error(ErrorMessageStr); 
              
              setErrorMessage(ErrorMessageStr)         
            }               
          
        }           

        function GoToAccounts()  {

          navigate('/RhyteItAccounts', 
          {
            state: {
                RedditUser:Users
            }
          })

        }      

        function GoToAccountSocialMedia()  {

          navigate('/RhyteItAccountSocialMedia', 
          {
            state: {
                vRhyteItAccount: vRhyteItAccount
            }
          })

        }   
     
        function GoToAccountTwitter()  {

          navigate('/RhyteItAccountTwitter', 
          {
            state: {
                vRhyteItAccount: vRhyteItAccount
            }
          })

        }                  

        function GoToAccountSD()  {

          navigate('/RhyteItAccountSD', 
          {
            state: {
                vRhyteItAccount: vRhyteItAccount
            }
          })

        }                  
           

    return ( 
          <div className={styles.SystemAdminBackground}>
            <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                >   
                    <div  className={styles.GridContainer}>              
                        <div className={styles.Container}>
                          <div className={styles.AccountDetailContainer}>
                            <View
                              className={styles.View}
                            >
                              <Card  className={styles.Card}>
                                <Flex
                                    direction="column"
                                    alignItems="flex-start"
                                    gap="1rem"
                                  >                               

                                    <Flex
                                      direction="column"
                                      alignItems="flex-start"
                                      gap="10px"
                                    >
                                      <Flex>
                                        <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated }>
                                          {RhyteItAccount.AccountStatus}
                                        </Text>
                                        <Text size="small" className={styles.Text}>
                                          {new Date( RhyteItAccount.CreationDateTime).toLocaleString("en-US")}
                                        </Text>
                                        <Text size="small" className={RecordUpdated === false ? styles.Text :styles.TextUpdated  }>
                                          {new Date( RhyteItAccount.UpdateDateTime).toLocaleString("en-US")}
                                        </Text>
                                      </Flex>
                                      <Table>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell className={styles.RowLabelColumn}>         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Id
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.id"                           
                                                defaultValue={vRhyteItAccount.id}
                                                onChange={ (e) => id_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell className={styles.RowLabelColumn}>         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Email
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.EmailAccount"                           
                                                defaultValue={vRhyteItAccount.EmailAccount}
                                                onChange={ (e) => EmailAccount_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell className={styles.RowLabelColumn}>         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Password
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.Password"                           
                                                defaultValue={vRhyteItAccount.Password}
                                                onChange={ (e) => EmailAccount_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>                                        
                                          <TableRow>
                                            <TableCell  className={styles.RowLabelColumn}>         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  First Name
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.FirstName"                           
                                                defaultValue={vRhyteItAccount.FirstName}
                                                onChange={ (e) => FirstName_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow >
                                          <TableRow className={styles.RowLabelColumn}>
                                            <TableCell   className={styles.RowLabelColumn}>         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Last Name
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.LastName"                           
                                                defaultValue={vRhyteItAccount.LastName}
                                                onChange={ (e) => LastName_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>
                                          <TableRow >
                                            <TableCell  className={styles.RowLabelColumn}>         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Birth Month
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.BirthMonth"                           
                                                defaultValue={vRhyteItAccount.BirthMonth}
                                                onChange={ (e) => BirthMonth_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell  className={styles.RowLabelColumn}>         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Birth Day
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.BirthDay"                           
                                                defaultValue={vRhyteItAccount.BirthDay}
                                                onChange={ (e) => BirthDay_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell  className={styles.RowLabelColumn} >         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Birth Year
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.BirthYear"                           
                                                defaultValue={vRhyteItAccount.BirthYear}
                                                onChange={ (e) => BirthYear_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell  className={styles.RowLabelColumn} >         
                                              <LabelText   
                                                className={styles.TextFieldLabel}
                                                >
                                                  Chromium Profile
                                              </LabelText>
                                            </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.ChromiumProfile"                           
                                                defaultValue={vRhyteItAccount.ChromiumProfile}
                                                onChange={ (e) => ChromiumProfile_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell  className={styles.RowLabelColumn} >         
                                                <LabelText   
                                                  className={styles.TextFieldLabel}
                                                  >
                                                    Location
                                                </LabelText>
                                              </TableCell>
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.Location"                           
                                                defaultValue={vRhyteItAccount.Location}
                                                onChange={ (e) => Location_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell  className={styles.RowLabelColumn} >         
                                                <LabelText   
                                                  className={styles.TextFieldLabel}
                                                  >
                                                    SBSM Bio
                                                </LabelText>
                                              </TableCell>                                            
                                            <TableCell>
                                              <TextField className={styles.TextField} 
                                                name="vRhyteItAccount.SBSMNewsBio"                           
                                                defaultValue={vRhyteItAccount.SBSMNewsBio}
                                                onChange={ (e) => SBSMNewsBio_handleChange(e, vRhyteItAccount) } />
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>      
                                    </Flex> 
                                  <div className={styles.headerline} />                                    
                                  <Flex
                                    direction="row"
                                    alignItems="flex-start"
                                    gap="1rem"
                                  >  
                                    <Button className={styles.Button}                                       
                                            onClick={ (e) => SaveRhyteItAccount(e, vRhyteItAccount) }
                                            variation="primary">Save</Button>
                                    <Button className={styles.Button}                                       
                                            onClick={ (e) => DeleteRhyteItAccount(e, vRhyteItAccount) }
                                            variation="primary">Delete</Button>
                                    <Button className={styles.Button}                                       
                                            onClick={ (e) => GoToAccounts(e, vRhyteItAccount) }
                                            variation="primary">Back</Button>
                                    <Button className={styles.Button}                                       
                                            onClick={ (e) => GoToAccountSocialMedia(e, vRhyteItAccount) }
                                            variation="primary">Social Media</Button>   
                                    <Button className={styles.Button}                                       
                                            onClick={ (e) => GoToAccountTwitter(e, vRhyteItAccount) }
                                            variation="primary">Twitter</Button>     
                                    <Button className={styles.Button}                                       
                                            onClick={ (e) => GoToAccountSD(e, vRhyteItAccount) }
                                            variation="primary">Stable Diffusion</Button>                                                  
                                    
                                  </Flex>  
                                                                    
                                  </Flex>                            
                              </Card>
                            </View>           
                          </div>
                          <div className={styles.MessageBox}>{ErrorMessage}</div>                            
                        </div>     
                    </div> 
            </ErrorBoundary>
          </div> 
    )

  }
 

export default RhyteItAccountDetail;